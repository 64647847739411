import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { stateToHTML } from 'draft-js-export-html'
import { convertFromRaw, RawDraftContentState } from 'draft-js'
import { Icon } from 'components/atoms'
import { AuthorCard, FloatingButton } from 'components/molecules'
import { useComment, usePageRoute, useToggle } from 'hooks'
import { addLikesToPosts, deleteLikesToPosts, addScrapToPosts, deleteScrapToPosts } from 'core/apis/api.post'
import { Post as PostType } from 'types/Post'
import { useHistory, useParams } from 'react-router-dom'
import {
  PostStyles,
  PostTitle,
  TagsGroup,
  Tag,
  IconBtnWrapper,
  AuthorInfoWrapper,
  PostContentWrapper,
  AuthorCardWrapper,
  PostMoveWrapper,
  PrevMove,
  NextMove,
  LikeWrapper,
  LikeIconWrapper,
  ScrapedWrapper,
  ScrapedIconWrapper,
  AuthorNickname,
  LikeCount,
  ScrapCount,
  CreatedDate,
  PrevMoveText,
  NextMoveText,
  PrevPostTitle,
  NextPostTitle,
  PostInfoWrapper,
} from './Post.styles'

export interface PostPropTypes {
  postData: PostType
  isLiked: boolean
  isScraped: boolean
}

/** `Post` 컴포넌트의 문서입니다.  */

function Post({ postData, isLiked, isScraped }: PostPropTypes) {
  const [tigerToggle, setTigerToggle] = useToggle(isLiked)
  const [bookmarkToggle, setBookmarkToggle] = useToggle(isScraped)
  const [likeCount, setLikeCount] = useState<number>(postData?.likeCount)
  const [scrapCount, setScrapCount] = useState<number>(postData?.scrapCount)
  const createdDate = new Date(postData?.createdDate)
  const { handlePageMove } = usePageRoute()
  const { comments } = useComment()
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const queryClient = useQueryClient()

  const { mutateAsync: onAddLikeEvent } = useMutation(() => addLikesToPosts(postData.id), {
    onSuccess: () => {
      setLikeCount((prev) => prev + 1)
      setTigerToggle()
      queryClient.invalidateQueries('post_content')
      queryClient.invalidateQueries('post_isLike')
    },
  })

  const { mutateAsync: onDeleteLikeEvent } = useMutation(() => deleteLikesToPosts(postData.id), {
    onSuccess: () => {
      setLikeCount((prev) => prev - 1)
      setTigerToggle()
      queryClient.invalidateQueries('post_content')
      queryClient.invalidateQueries('post_isLike')
    },
  })

  const { mutateAsync: onAddScrapEvent } = useMutation(() => addScrapToPosts(postData?.id), {
    onSuccess: () => {
      setScrapCount((prev) => prev + 1)
      setBookmarkToggle()
      queryClient.invalidateQueries('post_content')
      queryClient.invalidateQueries('post_isScrap')
    },
  })

  const { mutateAsync: onDeleteScrapEvent } = useMutation(() => deleteScrapToPosts(postData.id), {
    onSuccess: () => {
      setScrapCount((prev) => prev - 1)
      setBookmarkToggle()
      queryClient.invalidateQueries('post_content')
      queryClient.invalidateQueries('post_isScrap')
    },
  })

  const handleLikeToggle = () => {
    if (tigerToggle) {
      onDeleteLikeEvent()
    } else {
      onAddLikeEvent()
    }
  }

  const handleScrapToggle = () => {
    if (bookmarkToggle) {
      onDeleteScrapEvent()
    } else {
      onAddScrapEvent()
    }
  }

  const sharePage = () => {
    navigator.share?.({
      title: document.title,
      url: window.location.href,
    })
  }

  const moveToCommentPage = () => {
    history.push(`/post/${id}/comments`)
  }

  // 포스트 본문 파싱=
  // 본문 형태의 종류는 draft-js raw 데이터를 문자열로 직렬화한 형태와, html이 그대로 저장된 형태가 있다
  const contentHtml = React.useMemo(() => {
    try {
      const contentState = convertFromRaw(JSON.parse(postData?.content) as RawDraftContentState)

      return stateToHTML(contentState, {
        entityStyleFn: (entity) => {
          const entityType = entity.getType().toLowerCase()
          let result

          if (entityType === 'draft-js-video-plugin-video') {
            let { src } = entity.getData() as { src: string }

            if (src?.includes('www.youtube.com')) {
              const [, query] = src.split('?')

              if (!query) return

              const v = new URLSearchParams(query).get('v')
              src = `https://www.youtube.com/embed/${v}`
            }

            result = {
              element: 'iframe',
              attributes: {
                src,
              },
              style: {
                margin: '0 auto',
                display: 'block',
                width: '100%',
              },
            }
          }

          if (entityType === 'image') {
            const data = entity.getData() as { src: string; width: string }
            result = {
              element: 'img',
              attributes: {
                src: data.src,
              },
              style: {
                width: `${data.width ?? 100}%`,
                margin: '0 auto',
              },
            }
          }

          // eslint-disable-next-line consistent-return
          return result
        },
      })
    } catch (e) {
      console.log(e)
      return postData?.content
    }
  }, [postData?.content])

  return (
    <PostStyles>
      <PostInfoWrapper>
        <TagsGroup>
          {postData?.tags.map((tag) => {
            return <Tag key={tag.id}>{tag.name}</Tag>
          })}
        </TagsGroup>
        <PostTitle>{postData?.title}</PostTitle>
        <AuthorInfoWrapper>
          <AuthorNickname>{`Edit. ${postData?.author.nickname}`}</AuthorNickname>
          <CreatedDate>
            {`${createdDate.getFullYear()}.${createdDate.getMonth() + 1}.${createdDate.getDate()}`}
          </CreatedDate>
        </AuthorInfoWrapper>
      </PostInfoWrapper>
      <FloatingButton
        likeCount={likeCount}
        scrapCount={scrapCount}
        commentCount={comments.length}
        onLike={handleLikeToggle}
        onScrap={handleScrapToggle}
        onShare={sharePage}
        onComment={moveToCommentPage}
      />
      <PostContentWrapper
        dangerouslySetInnerHTML={{
          __html: contentHtml,
        }}
      />
      <IconBtnWrapper>
        <LikeWrapper>
          <LikeIconWrapper tigerToggle={tigerToggle}>
            <Icon
              icon='heart_filled'
              size='middle'
              color={tigerToggle ? 'secondary_color_regular' : 'gray10'}
              onClick={handleLikeToggle}
              cursor
            />
          </LikeIconWrapper>
          <LikeCount>{`${likeCount}`}</LikeCount>
        </LikeWrapper>
        <ScrapedWrapper>
          <ScrapedIconWrapper bookmarkToggle={bookmarkToggle}>
            <Icon
              icon='bookmark_filled'
              size='middle'
              color={bookmarkToggle ? 'primary_color_regular' : 'gray10'}
              onClick={handleScrapToggle}
              cursor
            />
          </ScrapedIconWrapper>
          <ScrapCount>{`${scrapCount}`}</ScrapCount>
        </ScrapedWrapper>
      </IconBtnWrapper>
      <AuthorCardWrapper>
        <AuthorCard
          id={postData?.author.id}
          src={postData?.author.imageUrl || undefined}
          email={postData?.author.email}
          nickname={postData?.author.nickname}
          introduction={postData?.author.introduction}
        />
      </AuthorCardWrapper>
      <PostMoveWrapper>
        {postData?.prevPost ? (
          <PrevMove onClick={() => handlePageMove(`/post/${postData.prevPost.id}`)}>
            <PrevMoveText>이전글</PrevMoveText>
            <PrevPostTitle>{postData.prevPost.title}</PrevPostTitle>
          </PrevMove>
        ) : (
          <div />
        )}
        {postData?.nextPost ? (
          <NextMove onClick={() => handlePageMove(`/post/${postData.nextPost.id}`)}>
            <NextMoveText>다음글</NextMoveText>
            <NextPostTitle>{postData.nextPost.title}</NextPostTitle>
          </NextMove>
        ) : (
          <div />
        )}
      </PostMoveWrapper>
    </PostStyles>
  )
}

export default Post
