import { timeAgo } from 'core/utils/time-ago'
import { Comment as CommentType } from 'types/Comment'
import { thumb_unfilled as Thumb } from 'assets/icons/svg'
import { useAuthentication, useComment } from 'hooks'
import {
  CommentWrapper,
  CommentNickname,
  CommentContent,
  ThumbsUpButton,
  CommentButtonsLeft,
  CommentButtonsRight,
  BestCommentBadge,
  CommentLeft,
  CommentRight,
} from './Comment.styles'

interface CommentProps {
  comment: CommentType
  onReportComment: () => void
  onReply: () => void
  onUpdate: () => void
}

const Comment: React.FC<CommentProps> = ({ comment, onReportComment, onReply, onUpdate }) => {
  const { id, createdBy, content, createdDate, likeCount, liked, isBest } = comment
  const { user } = useAuthentication()
  const { deleteComment, toggleLike } = useComment()

  if (comment.deleted) {
    return <CommentWrapper deleted>삭제된 댓글입니다.</CommentWrapper>
  }

  return (
    <CommentWrapper>
      <CommentLeft>
        <CommentNickname>{createdBy.nickname}</CommentNickname>
        <CommentContent>
          {isBest && <BestCommentBadge>Best</BestCommentBadge>}
          {content}
        </CommentContent>
        <CommentButtonsLeft>
          <span>{timeAgo(createdDate)}</span>
          <button type='button' onClick={onReply}>
            답글달기
          </button>
          <button type='button' onClick={onReportComment}>
            신고
          </button>
        </CommentButtonsLeft>
      </CommentLeft>
      <CommentRight>
        <ThumbsUpButton liked={liked} onClick={() => toggleLike(id)}>
          <Thumb />
          {likeCount}
        </ThumbsUpButton>
        {createdBy.id === user?.id && (
          <CommentButtonsRight>
            <button type='button' onClick={onUpdate}>
              수정
            </button>{' '}
            |{' '}
            <button type='button' onClick={() => deleteComment(id)}>
              삭제
            </button>
          </CommentButtonsRight>
        )}
      </CommentRight>
    </CommentWrapper>
  )
}

export default Comment
