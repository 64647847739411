import styled from 'styled-components'

export const SelectStyles = styled.select`
  width: 100%;
  height: 48px;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray70};
  font-size: 15px;

  :focus {
    outline: none;
  }
`
