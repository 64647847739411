import BeNew from 'assets/images/BeNew.png'
import { ImgStyles, BorderRadiusTypes, BorderTypes } from './Img.styles'

export interface ImgPropTypes {
  /** 이미지 주소 */
  src: string

  /** 이미지 설명 */
  alt: string

  /** 이미지 테두리 */
  border: BorderTypes

  /** 이미지 테두리 반지름 */
  borderRadius: BorderRadiusTypes
}

/** `Img` 컴포넌트의 문서입니다.  */

const Img = ({ src = BeNew, alt = '준비 중...', border = 'default', borderRadius }: ImgPropTypes) => {
  return <ImgStyles src={src} alt={alt} border={border} borderRadius={borderRadius} />
}

export default Img
