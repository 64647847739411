import { Header, HeaderInner } from './PageHeader.styles'

const PageHeader: React.FC = ({ children }) => {
  return (
    <Header>
      <HeaderInner>{children}</HeaderInner>
    </Header>
  )
}

export default PageHeader
