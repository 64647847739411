import { useAuthentication } from 'hooks'
import {
  Wrapper,
  InputBox,
  InputArea,
  Nickname,
  InputBoxTextNum,
  InputButton,
  CommentCount,
} from './CommentInput.styles'

export interface CommentInputProps {
  commentCount?: number
  inputValue: string
  onChangeInputValue: React.ChangeEventHandler<HTMLTextAreaElement>
  onClickAddButton: React.MouseEventHandler<HTMLButtonElement>
}

const CommentInput: React.FC<CommentInputProps> = ({
  commentCount,
  inputValue,
  onChangeInputValue,
  onClickAddButton,
}) => {
  const { user } = useAuthentication()

  const maxLength = 300
  const textareaRow = 3
  const placeholder = user
    ? '건강한 성 문화, 당신의 댓글로 함께 만들어가요.'
    : '로그인하고 비뉴와 함께 생각을 나눠요! :)'

  return (
    <Wrapper>
      {!!commentCount && <CommentCount>{`댓글 ${commentCount}`}</CommentCount>}
      <InputBox>
        <InputArea>
          {!!user && <Nickname>{user?.nickname}</Nickname>}
          <textarea
            rows={textareaRow}
            maxLength={maxLength}
            placeholder={placeholder}
            disabled={!user}
            value={inputValue}
            onChange={onChangeInputValue}
          />
          {!!user && (
            <InputBoxTextNum>
              {inputValue.length}/{maxLength}
            </InputBoxTextNum>
          )}
        </InputArea>
        <InputButton disabled={!user || !inputValue.length} onClick={onClickAddButton}>
          등록
        </InputButton>
      </InputBox>
    </Wrapper>
  )
}

export default CommentInput
