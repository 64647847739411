import { useParams } from 'react-router-dom'
import { useQueries } from 'react-query'
import { PostTemplate } from 'components/templates'
import { getPostById, getLikesToPosts, getScrapsToPosts } from 'core/apis/api.post'
import { useAuthentication, useComment } from 'hooks'

function PostPage() {
  const { id } = useParams<{ id: string }>()
  const { user } = useAuthentication()
  const enabled = !!user
  const [postData, likeData, scrapeData] = useQueries([
    { queryKey: ['post_content', id], queryFn: () => getPostById(id) },
    { queryKey: ['post_isLike', id], queryFn: () => getLikesToPosts(id), enabled },
    { queryKey: ['post_isScrap', id], queryFn: () => getScrapsToPosts(id), enabled },
  ])
  const { isCommentLoading, comments } = useComment()

  const isLoading = postData.isLoading || likeData.isLoading || scrapeData.isLoading || isCommentLoading

  if (!isLoading && !postData.data) {
    alert('에러가 발생했습니다. 다시 시도해주세요')
    return null
  }

  return (
    <PostTemplate
      loading={isLoading}
      postData={postData.data!}
      isLiked={likeData.data?.likeYn ?? false}
      isScraped={scrapeData.data?.scrapYn ?? false}
      comments={comments}
    />
  )
}
export default PostPage
