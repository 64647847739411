import { useCallback, useEffect, useState } from 'react'

export function useBirthYear() {
  const [yearOfBirthList, setYearOfBirthList] = useState<string[]>([])

  const handleGetYearOfBirthList = useCallback(() => {
    const date = new Date()
    const year = date.getFullYear()
    const list = []

    for (let y = year - 80; y <= year - 14; y += 1) {
      list.push(y.toString())
    }

    return list.reverse()
  }, [])

  useEffect(() => {
    const yearList = handleGetYearOfBirthList()
    setYearOfBirthList(yearList)
  }, [handleGetYearOfBirthList])

  return yearOfBirthList
}
