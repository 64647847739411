import styled from 'styled-components'
import { lineClamp } from 'assets/styles/lineClamp'

export const CardWrapper = styled.li`
  width: 100%;
  position: relative;
  cursor: pointer;
`

export const CardImgWrapper = styled.div`
  aspect-ratio: 1.775;
  margin-bottom: 8px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;

  ${({ theme }) => `
    @media ${theme.media.mobile} {
      aspect-ratio: 1.733;
      border-radius: 8px;
    }
  `}
`

export const CardImg = styled.div<{ thumbnail: string; smallImage: boolean }>`
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), 25%, rgba(0, 0, 0, 0)),
    center / ${({ smallImage }) => (smallImage ? '120px 120px' : 'cover')}
      url('${({ thumbnail }) => thumbnail}') #ebeaea;
  background-position: center;
  background-color: #dbe1ff;
  background-repeat: no-repeat;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;

  ${CardWrapper}:hover & {
    -webkit-transform: scale(1.06);
    -moz-transform: scale(1.06);
    -o-transform: scale(1.06);
    transform: scale(1.06);
  }
`

export const CardTitle = styled.div`
  margin-bottom: 12px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      margin-bottom: 2px;
    }
  `}
`

export const Title = styled.h5`
  ${({ theme }) => theme.typographies.largeBody}
  ${{ ...lineClamp(2) }}
  word-break: keep-all;

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.subtitleMobile}
    }
  `}
`

export const Description = styled.p`
  border-top: 0.8px solid ${({ theme }) => theme.colors.gray10};
  margin-top: -4px;
  padding-top: 8px;
  margin-bottom: 14px;
  ${({ theme }) => theme.typographies.smallBody}
  color: ${({ theme }) => theme.colors.gray50};
  ${{ ...lineClamp(2) }}

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.overline1}
      margin-top: 4px;
      padding-top: 4px;
      margin-bottom: 2px;
    }
  `}
`

export const TagText = styled.span`
  ${({ theme }) => theme.typographies.smallBody}
  color: ${({ theme }) => theme.colors.gray70};

  ${({ theme: { media, typographies, colors } }) => `
    @media ${media.mobile} {
      ${typographies.overline1}
      color: ${colors.black};
    }
  `}
`

export const CardIconGroup = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 17px;
  right: 17px;
  column-gap: 8px;

  ${({ theme }) => `
    @media ${theme.media.mobile} {
      bottom: 12px;
      right: 12px;  

      & svg {
        width: 20px;
        height: 20px;
      }
    }
  `}
`
