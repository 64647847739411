import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  getAuthorInfo,
  getPostByAuthor,
  checkSubscription,
  subscribeAuthor,
  unsubscribeAuthor,
} from 'core/apis/api.author'
import { AuthorTemplate } from 'components/templates'
import { PostCategoryId } from 'types/PostCategory'
import { useAuthentication } from 'hooks'

const AuthorPage: React.FC = () => {
  const queryClient = useQueryClient()
  const { user } = useAuthentication()
  const { id } = useParams<{ id: string }>()
  const [categoryId, setCategoryId] = useState<PostCategoryId | undefined>(undefined)
  const { isLoading: authorLoading, data: author } = useQuery(['author', id], () => getAuthorInfo(id))
  const { isLoading: postLoading, data: posts } = useQuery(['author-post', id, categoryId], () =>
    getPostByAuthor({ id, categoryId }),
  )
  const { data: isSubscribing, refetch } = useQuery(['author-sub-check', id], () => checkSubscription(id), {
    enabled: !!user,
    staleTime: Infinity,
  })
  const { mutateAsync: subscribe } = useMutation(() => subscribeAuthor(id), {
    onMutate() {
      queryClient.setQueryData(['author-sub-check', id], true)
    },
  })
  const { mutateAsync: unsubscribe } = useMutation(() => unsubscribeAuthor(id), {
    onMutate() {
      queryClient.setQueryData(['author-sub-check', id], false)
    },
  })

  const onClickSubScription = () => {
    const promise = isSubscribing ? unsubscribe() : subscribe()

    promise.finally(() => {
      refetch()
    })
  }

  return (
    <AuthorTemplate
      author={author!}
      posts={posts!}
      selectedCategoryId={categoryId}
      loading={authorLoading || postLoading}
      onClickChips={(key, selected) => setCategoryId(selected ? (key as PostCategoryId) : undefined)}
      isSubscribing={isSubscribing ?? false}
      onClickSubscription={onClickSubScription}
    />
  )
}

export default AuthorPage
