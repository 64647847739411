import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
`
export const CommentCount = styled.div`
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.typographies.subtitle3}
  margin-bottom: 16px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      display: none;
    }
  `}
`

export const InputBox = styled.div`
  border: 1px solid #333;
  border-radius: 16px;
  padding: 16px 24px;
  display: flex;
  column-gap: 12px;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};

  textarea {
    width: 100%;
    border: 0;
    resize: none;
    background-color: transparent;
  }
`
export const InputArea = styled.div`
  flex: 1;
`

export const Nickname = styled.p`
  ${({ theme }) => theme.typographies.subtitle4}
  margin-bottom: 8px;
`

export const InputBoxTextNum = styled.p`
  ${({ theme }) => theme.typographies.overline1}
  color: ${({ theme }) => theme.colors.gray80};
  padding-top: 4px;
`

export const InputButton = styled.button`
  width: 90px;
  height: 50px;
  ${({ theme }) => theme.typographies.smallButton}
  line-height: 50px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primary_color_regular};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray30};
  }
`
