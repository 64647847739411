import styled from 'styled-components'

export const MissionWrapper = styled.ul`
  width: 716px;
  margin: 150px auto 0;
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      width: 100%;
      margin: 0;
      padding: 32px 0;
    }
  `}
`

export const MissionItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
`

export const MissionImg = styled.div`
  width: 716px;
  margin: 24px 0;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      width: 100%;
      margin: 18px 0;
    }
  `}
`

export const MissionContents = styled.div`
  width: 100%;
  max-width: 532px;
  margin: auto;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      padding: 0 24px;
    }
  `}
`

export const Title = styled.h3`
  ${({ theme }) => theme.typographies.subtitle}
  color: ${({ theme }) => theme.colors.primary_color_regular};
  margin-bottom: 12px;

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.largeBody}
    }
  `}
`

export const Description = styled.p`
  ${({ theme }) => theme.typographies.largeRegularBody}

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.body}
    }
  `}
`
