import { useRecoilState } from 'recoil'
import { userState } from 'recoil/atoms'
import { storage } from 'core/utils/storage'

const TOKEN_KEY = 'benew-access-token'

interface Token {
  accessToken: string
  tokenType: string
  expires: number
}

export const tokenManager = {
  parseTokenString: (str: string | null) => {
    if (!str) return null

    try {
      const now = Date.now()
      const token = JSON.parse(str) as Token

      return typeof token?.accessToken === 'string' &&
        typeof token?.tokenType === 'string' &&
        token?.expires > now
        ? token
        : null
    } catch {
      return null
    }
  },
  getToken: () => {
    const localToken = tokenManager.parseTokenString(storage.local.get(TOKEN_KEY))

    if (localToken) return localToken

    const sessionToken = tokenManager.parseTokenString(storage.session.get(TOKEN_KEY))

    if (sessionToken) return sessionToken

    return null
  },
  setToken: ({ accessToken, tokenType, session = false }: Omit<Token, 'expires'> & { session?: boolean }) => {
    const ONE_DAY = 24 * 3600 * 1000

    const val = { accessToken, tokenType, expires: Date.now() + (session ? ONE_DAY : 2 * ONE_DAY) }

    tokenManager.clearToken()

    if (session) {
      storage.session.set(TOKEN_KEY, JSON.stringify(val))
    } else {
      storage.local.set(TOKEN_KEY, JSON.stringify(val))
    }
  },
  clearToken: () => {
    storage.local.remove(TOKEN_KEY)
    storage.session.remove(TOKEN_KEY)
  },
  isExpired: () => {
    const token = tokenManager.getToken()

    if (!token) return true

    return token.expires < Date.now()
  },
}

/**
 * 로그인 유지 정책
 *
 * 로그인 유지 옵션 on(session = false)인 경우,
 * - 유효기간 : 2일
 * - 로컬스토리지에 저장
 *
 * 로그인 유지 옵션 off(session = true)인 경우,
 * - 유효기간 : 1일
 * - 쿠키에 저장
 * - 세션쿠키로 설정하여, 브라우저가 종료되면 쿠키가 제거되도록 한다
 */
export const useAuthentication = () => {
  const [user, setUser] = useRecoilState(userState)
  const { accessToken = null, tokenType = null } = tokenManager.getToken() ?? {}

  const setToken = (payload: { accessToken: string; tokenType: string; session?: boolean }) => {
    tokenManager.setToken({ ...payload })
  }

  const clearAuth = () => {
    tokenManager.clearToken()
    setUser(null)
  }

  return {
    setToken,
    setUser,
    clearAuth,
    user,
    accessToken,
    tokenType,
  } as const
}
