const MINUTE = 60 * 1000
const HOUR = 60 * MINUTE
const DAY = 24 * HOUR
const MONTH = 30 * DAY
const YEAR = 365 * DAY

export const timeAgo = (time: string | number | Date) => {
  let date

  try {
    if (time instanceof Date) {
      date = time
    } else {
      date = new Date(time)
    }
  } catch {
    return ''
  }

  const diff = Date.now() - date.getTime()

  if (diff < MINUTE) {
    return '몇 초 전'
  }
  if (diff < HOUR) {
    return `${Math.floor(diff / MINUTE)}분 전`
  }
  if (diff < DAY) {
    return `${Math.floor(diff / HOUR)}시간 전`
  }
  if (diff < MONTH) {
    return `${Math.floor(diff / DAY)}일 전`
  }
  if (diff < YEAR) {
    return `${Math.floor(diff / MONTH)}달 전`
  }
  return `${Math.floor(diff / YEAR)}년 전`
}
