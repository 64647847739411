import { Member } from 'types/Member'
import { Team } from 'types/Team'
import { API } from './api-factory'

export const getAboutTeam = async () => {
  const { data } = await API().get<Team>(`api/v1/abouts/team`)
  return data
}

export const getAboutUs = async () => {
  const { data } = await API().get<Member[]>(`api/v1/abouts/us`)
  return data
}
