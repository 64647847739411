import { Text, Input, Button } from 'components/atoms'
import { useMutation } from 'react-query'
import { useRegistrationForm } from 'hooks'
import { userPasswordEdit } from 'core/apis/api.auth'
import { passwordFormSchema } from 'core/utils/validation'
import { userState, unregisterState } from 'recoil/atoms'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { AccountFormStyles, FormItem, PasswordEditFormWrapper, DeleteAccount } from './AccountForm.styles'

export interface IFormInputs {
  password: string
  passwordConfirm: string
}

export interface Password {
  password: string
}

function AccountForm() {
  const user = useRecoilValue(userState)

  const setUnregisterFormActive = useSetRecoilState(unregisterState)

  const {
    register,
    watch,
    formState: { errors, isValid, isSubmitSuccessful },
    onSubmit,
  } = useRegistrationForm<IFormInputs>(passwordFormSchema, 'onChange')

  const { mutateAsync: onChangePassword } = useMutation(
    ({ password }: Password) => userPasswordEdit(password),
    {
      onSuccess: () => {
        alert('비밀번호 변경 성공! 🎉')
      },

      onError: () => {
        alert('비밀번호 변경 실패! 😱')
      },
    },
  )

  const handleChangePassword = () => {
    onChangePassword({ password: watch('password') })
  }

  const handleUnregisterForm = () => {
    setUnregisterFormActive((prev) => !prev)
  }

  if (!user) {
    return null
  }

  return (
    <AccountFormStyles>
      <FormItem>
        <Text as='label' typography='subtitle4' color='gray80' className='label'>
          이메일
        </Text>
        <Input type='email' value={user.email} placeholder={user.email} disabled />
      </FormItem>
      <FormItem>
        <Text as='label' typography='subtitle4' color='gray80' className='label'>
          비밀번호
        </Text>
        <PasswordEditFormWrapper
          onSubmit={(...args) => {
            onSubmit(handleChangePassword)(...args)
          }}
        >
          <Input type='password' placeholder='비밀번호 입력하기' register={{ ...register('password') }} />
          <Input
            type='password'
            placeholder='새 비밀번호 확인하기'
            register={{ ...register('passwordConfirm') }}
          />
          {errors.password && (
            <Text as='p' typography='overline2' color='error_color_regular'>
              {`${errors.password.message}`}
            </Text>
          )}

          {errors.passwordConfirm && (
            <Text as='p' typography='overline2' color='error_color_regular'>
              {`${errors.passwordConfirm.message}`}
            </Text>
          )}

          {isValid && (
            <Text as='p' typography='overline2' color='success_color_regular'>
              비밀번호가 일치합니다.
            </Text>
          )}
          <Button variant='fill' size='middle' disabled={!isValid} typography='smallButton'>
            비밀번호 변경
          </Button>

          {isSubmitSuccessful && (
            <Text as='p' typography='overline2' color='success_color_regular'>
              비밀번호가 변경되었습니다.
            </Text>
          )}
        </PasswordEditFormWrapper>
      </FormItem>
      <DeleteAccount onClick={handleUnregisterForm}>계정 삭제하기</DeleteAccount>
    </AccountFormStyles>
  )
}

export default AccountForm
