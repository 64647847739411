import { CommentList, Post } from 'components/organisms'
import { BaseTemplate } from 'components/templates'
import { Post as PostType } from 'types/Post'
import { BaseTemplateProps } from 'components/templates/BaseTemplate/BaseTemplate'
import { CommentSection, MobilePadding } from 'components/templates/PostTemplate/PostTemplate.styles'
import { Comment } from 'types/Comment'

interface PostTemplatePropTypes extends BaseTemplateProps {
  postData: PostType
  isLiked: boolean
  isScraped: boolean
  comments: Comment[]
}

function PostTemplate({ loading, postData, isLiked, isScraped, comments }: PostTemplatePropTypes) {
  return (
    <>
      <BaseTemplate loading={loading}>
        <Post postData={postData} isLiked={isLiked} isScraped={isScraped} />
        <CommentSection>
          <CommentList comments={comments} />
        </CommentSection>
      </BaseTemplate>
      <MobilePadding />
    </>
  )
}

export default PostTemplate
