import styled from 'styled-components'

export const NotScraped = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 363px;
  background-color: ${({ theme }) => theme.colors.gray05};
  ${({ theme }) => theme.typographies.subtitle}

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.subtitle4}
    }
  `}
`

export const Section = styled.section`
  width: 100%;
  padding: 0 24px;
  margin-bottom: 140px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      margin-bottom: 72px;
    }
  `}
`

export const SectionInner = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
`
