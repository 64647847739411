import { Text, Chips } from 'components/atoms'
import { Tag } from 'types/ContentResponse'
import { BannerStyles, BannerContents, ChipsGroup } from './Banner.styles'

export interface BannerPropTypes {
  /** 베너 이미지 */
  bannerImg: string
  /** 베너 제목 */
  title: string
  /** 베너 내용 */
  subTitle: string
  /** 베너 태그 */
  tags: Tag[]
  checkedTagId?: number
  onClickTag: (id: number) => void
}

/**
 * 배너 컴포넌트
 * 각 카테고리 페이지 위에 위치한 컴포넌트
 * 카테고리 제목, 일러스트, 태그 등의 내용이 포함된다
 */
function Banner({ bannerImg, title, subTitle, tags, checkedTagId, onClickTag }: BannerPropTypes) {
  return (
    <BannerStyles bannerImg={bannerImg}>
      <BannerContents>
        <Text as='h1' typography='h3' style={{ margin: '0 0 4px' }}>
          {title}
        </Text>
        <Text as='p' typography='subtitle3'>
          {subTitle}
        </Text>
        <ChipsGroup>
          {tags?.map(({ id, name }) => {
            const checked = checkedTagId === id
            return (
              <Chips key={id} onClick={() => onClickTag(id)} active={checked}>
                {name}
              </Chips>
            )
          })}
        </ChipsGroup>
      </BannerContents>
    </BannerStyles>
  )
}

export default Banner
