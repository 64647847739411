import styled from 'styled-components'

export const AuthorCardStyles = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.gray50};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray50};
  display: flex;
  align-items: stretch;
  column-gap: 32px;
  padding: 48px 0;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      padding: 32px 0;
      column-gap: 16px;
    }
  `}
`

export const ImgWrapper = styled.div`
  img {
    width: 100px;
    height: 100px;
  }
`

export const Profile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  column-gap: 16px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      flex-direction: column;
      align-items: stretch;
    }
  `}
`

export const NickName = styled.span`
  ${({ theme }) => theme.typographies.subtitle2}
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.subtitle4}
    }
  `}
`

export const Introduction = styled.p`
  ${({ theme }) => theme.typographies.body}
  color: ${({ theme }) => theme.colors.gray80};

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.smallBody}
    }
  `}
`

export const TextContents = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  column-gap: 8px;
`

export const LinkWrapper = styled.div`
  display: inline;
  cursor: pointer;
`

export const Buttons = styled.div`
  display: flex;
  column-gap: 12px;
  flex-shrink: 0;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      margin-top: 16px;
      column-gap: 6px;
    }
  `}
`

export const Button = styled.button<{ active?: boolean }>`
  padding: 8px 16px;
  border: 1px solid
    ${({ active, theme: { colors } }) => (active ? colors.primary_color_regular : colors.black)};
  cursor: pointer;
  border-radius: 48px;
  display: inline-flex;
  align-items: center;
  ${({ theme: { typographies } }) => typographies.subtitle4}
  column-gap: 8px;
  color: ${({ active, theme: { colors } }) => (active ? colors.white : colors.black)};
  background-color: ${({ active, theme: { colors } }) =>
    active ? colors.primary_color_regular : colors.white};

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.overline2}
      padding: 4px 8px;
      column-gap: 4px;
    }
  `}

  svg {
    width: 24px;
    height: 24px;

    ${({ theme: { media } }) => `
      @media ${media.mobile} {
        width: 18px;
        height: 18px;
      }
    `}

    path {
      fill: ${({ active, theme: { colors } }) => (active ? colors.white : colors.black)};
    }
  }

  &:hover {
    border-color: ${({ theme: { colors } }) => colors.primary_color_regular};
    background-color: ${({ theme: { colors } }) => colors.primary_color_regular};
    color: ${({ theme: { colors } }) => colors.white};

    path {
      fill: ${({ theme: { colors } }) => colors.white};
    }
  }
`
