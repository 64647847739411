import { useInfiniteQuery, useQuery } from 'react-query'
import { CategoryTemplate } from 'components/templates'
import { getPostByCategory } from 'core/apis/api.post'
import { getTagByCategory } from 'core/apis/api.tag'
import interviewBannerImg from 'assets/images/interview_banner.png'
import { InterviewCard } from 'types/ContentResponse'
import { usePagination } from 'hooks/usePagination'

function InterviewPage() {
  const { size, sort, tagId } = usePagination()

  const { data, isLoading, fetchNextPage } = useInfiniteQuery(
    ['post', 3, size, sort, tagId],
    ({ pageParam = 1 }) =>
      getPostByCategory<InterviewCard>({ categoryId: 3, page: pageParam as number, size, sort, tagId }),
    {
      getNextPageParam(lastPage, allPages) {
        return lastPage.last ? undefined : allPages.length + 1
      },
    },
  )

  const { data: tags, isLoading: isTagLoading } = useQuery(['tag', 'interview'], () => getTagByCategory(2))

  const cards = data?.pages?.reduce((acc, currentPage) => {
    return [...acc, ...currentPage.content]
  }, [] as InterviewCard[])

  const showMoreButton = data?.pages?.slice(-1)?.[0]?.last === false

  return (
    <CategoryTemplate
      loading={isLoading || isTagLoading}
      bannerImg={interviewBannerImg}
      title='인터뷰'
      subTitle={`더 이상 부끄럽지 않은 인터뷰!\n차마 아무에게도 하지 못했던 진솔한 이야기`}
      cards={cards}
      onClickMoreButton={fetchNextPage}
      showMoreButton={showMoreButton}
      tags={tags ?? []}
    />
  )
}

export default InterviewPage
