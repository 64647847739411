/* eslint-disable */
import { useRecoilValue } from 'recoil'
import { Img, Text, Button } from 'components/atoms'
import { usePageRoute, useToggle } from 'hooks'
import {
  InterviewCardStyles,
  ImgWrapper,
  InterviewCardContent,
  IconGroup,
  EllipsisContent,
} from './InterviewCard.styles'

export interface InterviewCardPropTypes {
  /** 인터뷰 데이터 */
  slide: any // 임시 타입

  /** 카드 제목 */
  title: string

  /** 카드 설명 */
  description: string

  /** 카드 생성 날짜 */
  createdDate: string
}

/**
 * 인터뷰카드
 *
 * 일단 개발 보류
 * 다시 사용할 일이 있으면 첫번째 줄 주석을 해제하고 eslint를 적용하여 사용할 것
 */
function InterviewCard({
  slide,
  title = '제목 입니다.',
  description = '설명 입니다.',
  createdDate = '날짜 입니다.',
}: InterviewCardPropTypes) {
  const [tigerToggle, setTigerToggle] = useToggle(false)
  const [bookmarkToggle, setBookmarkToggle] = useToggle(false)
  const createdDateFormat = new Date(createdDate)
  const { handlePageMove } = usePageRoute()

  const handleToggleLike = () => {
    setTigerToggle()
  }

  const handleToggleScraped = () => {
    setBookmarkToggle()
  }

  return (
    <InterviewCardStyles onClick={() => handlePageMove(`/post/${slide.id}`)}>
      <IconGroup>
        <Button
          variant='text'
          iconOnly
          withIcon={tigerToggle ? 'tiger_filled' : 'tiger_unfilled'}
          size='large'
          onClick={handleToggleLike}
        >
          tiger_icon
        </Button>
        <Button
          variant='text'
          iconOnly
          withIcon={bookmarkToggle ? 'bookmark_filled' : 'bookmark_unfilled'}
          size='large'
          onClick={handleToggleScraped}
        >
          bookmark
        </Button>
      </IconGroup>
      <ImgWrapper>
        <Img src={slide.thumbnail} alt='임시 이미지...' border='none' borderRadius='leftBig' />
      </ImgWrapper>
      <InterviewCardContent>
        <Text as='p' typography='smallBody'>
          {`${createdDateFormat.getFullYear()}-${
            createdDateFormat.getMonth() + 1
          }-${createdDateFormat.getDate()}`}
        </Text>
        <Text as='h5' typography='subtitle'>
          {title}
        </Text>
        <EllipsisContent>
          <Text as='p' typography='subtitle4'>
            {description}
          </Text>
        </EllipsisContent>
        <Text as='p' typography='subtitle3'>
          Edit. 잠자는허예
        </Text>
      </InterviewCardContent>
    </InterviewCardStyles>
  )
}

export default InterviewCard
