import styled from 'styled-components'

export const SelectFiledStyles = styled.div``

export const Label = styled.label`
  ${({ theme: { typographies } }) => typographies.smallBody}
  color: ${({ theme: { colors } }) => colors.gray80};
  display: block;
  margin-bottom: 4px;
`
