import { ImageSlider } from 'components/molecules'
import { CardBanner, CardSlider } from 'components/organisms'
import { SexpediaCard, PosepediaCard, ReviewCard, PostCard } from 'types/ContentResponse'
import { BaseTemplate } from 'components/templates'
import { Banner } from 'types/Banner'
import { CardBannerContainer } from './MainTemplate.styles'
import { BaseTemplateProps } from '../BaseTemplate/BaseTemplate'

interface MainTemplatePropTypes extends BaseTemplateProps {
  recentCards: PostCard[]
  popularCards: PostCard[]
  sexpediaCards: SexpediaCard[]
  posepediaCards: PosepediaCard[]
  reviewCards: ReviewCard[]
  editorPickCards: PostCard[]
  banners: Banner[]
}

function MainTemplate({
  loading,
  popularCards,
  recentCards,
  sexpediaCards,
  posepediaCards,
  // interviewCards,
  reviewCards,
  editorPickCards,
  banners,
}: MainTemplatePropTypes) {
  return (
    <BaseTemplate loading={loading}>
      <ImageSlider slides={banners} />
      <CardBannerContainer>
        <CardBanner title='인기 콘텐츠' cards={popularCards} />
        <CardBanner title='신규 콘텐츠' cards={recentCards} />
        {editorPickCards.length > 0 && <CardSlider title='에디터 추천' slides={editorPickCards} />}
        <CardBanner title='성과사전' to='sexpedia' cards={sexpediaCards} />
        <CardBanner title='체위사전' to='posepedia' cards={posepediaCards} />
        <CardBanner title='성층탐구' to='review' cards={reviewCards} />
      </CardBannerContainer>
    </BaseTemplate>
  )
}

export default MainTemplate
