import { useEffect } from 'react'
import { useAuthentication } from 'hooks'
import { useHistory, useLocation } from 'react-router-dom'
import { checkSignup, getKakaoAccessToken, getUserInfo, loginWithKakao } from '../core/apis/api.auth'

function KakaoLoginRedirectPage() {
  const { setToken, setUser } = useAuthentication()
  const history = useHistory()
  const searchParams = new URLSearchParams(useLocation().search)
  const [redirectUri] = window.location.href.split('?')
  const code = searchParams.get('code')

  useEffect(() => {
    if (!code || !redirectUri) return

    const login = async () => {
      try {
        const { kakaoAccessToken: accessToken } = await getKakaoAccessToken(code, redirectUri)

        if (!accessToken) throw Error('invalid access token')

        const alreadySignup = await checkSignup({ provider: 'KAKAO', accessToken })

        if (alreadySignup) {
          await loginWithKakao(accessToken).then(setToken).then(getUserInfo).then(setUser)
          history.go(-1)
        } else {
          history.replace('/signup', { provider: 'KAKAO' })
        }
      } catch {
        alert('로그인 중 에러 발생')
        history.go(-1)
      }
    }

    login()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div />
}

export default KakaoLoginRedirectPage
