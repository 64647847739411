import React from 'react'
import { ChipsStyles } from './Chips.styles'

export interface ChipsPropTypes {
  /** 칩스 내용 */
  children: string

  /** 클릭 이벤트 */
  onClick?: () => void

  active?: boolean
}

/** `Chips` 컴포넌트의 문서입니다.  */

const Chips = ({ children = '태그', onClick, active = false }: ChipsPropTypes) => {
  return (
    <ChipsStyles onClick={onClick} className={active ? 'active' : ''}>
      {children}
    </ChipsStyles>
  )
}

export default Chips
