import styled from 'styled-components'

export const PhotoEditorStyles = styled.section`
  max-width: 532px;
  margin: 71px auto 210px;
  .label {
    width: 40%;
  }
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  column-gap: 14px;
  cursor: pointer;
`

export const ProfileImgWrapper = styled.div`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.colors.gray80};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray10};
  padding: 24px 0;
  margin-top: 10px;
  margin-bottom: 26px;

  img {
    aspect-ratio: 1 / 1;
  }
`

export const ProfileImg = styled.div<{ dragging: boolean }>`
  position: relative;
  width: 348px;
  cursor: pointer;
  background-color: ${({ theme, dragging }) => dragging && theme.colors.black};
  opacity: ${({ dragging }) => dragging && 0.8};
`

export const AddImageBtn = styled.div`
  position: absolute;
  right: 15px;
  bottom: 15px;
  cursor: pointer;
`

export const SelectedImgList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  margin-top: 30px;
  margin-bottom: 108px;
`

export const SelectedImgItem = styled.li<{ active?: boolean }>`
  width: 164px;
  height: 164px;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: 3px solid ${({ theme, active }) => (active ? theme.colors.primary_color_regular : 'transparent')};

  .close {
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .close:before,
  .close:after {
    position: absolute;
    left: 50%;
    top: calc(50% - 150px);
    content: ' ';
    height: 300px;
    width: 2px;
    background-color: ${({ theme }) => theme.colors.gray30};
  }
  .close:before {
    transform: rotate(135deg);
    transform-origin: center;
  }
  .close:after {
    transform: rotate(-135deg);
  }
`

export const EditBtnWrapper = styled.div`
  display: flex;
  justify-content: center;
`
