import styled from 'styled-components'

export const NavbarStyles = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 32px;
  height: 44px;
  margin-left: 44px;
`

export const NavbarItem = styled.span`
  height: 36px;
  line-height: 36px;
  display: inline-block;

  &:hover {
    color: ${({ theme }) => theme.colors.primary_color_regular};
    border-bottom: 2px solid ${({ theme }) => theme.colors.primary_color_regular};
  }
`
