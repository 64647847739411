import styled from 'styled-components'
import { lineClamp } from 'assets/styles/lineClamp'

export const AuthorSection = styled.section`
  padding: 50px 24px;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 44px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      padding: 16px 24px;
      margin-bottom: 14px;
    }
  `}
`

export const AuthorSectionInner = styled.div`
  width: 100%;
  max-width: 794px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      max-width: 287px;
    }
  `}
`

export const ProfileImage = styled.div`
  width: 30%;
  aspect-ratio: 1 / 1;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 44px;
  flex: 1;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      margin-right: 16px;
    }
  `}
`

export const Profile = styled.div`
  flex: 1.9;
`

export const NickName = styled.div`
  ${({ theme }) => theme.typographies.subtitle};
  color: ${({ theme }) => theme.colors.primary_color_regular};
  margin-bottom: 14px;

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.smallButton_1}
      margin-bottom: 8px;
    }
  `}
`

export const Introduction = styled.div`
  ${({ theme }) => theme.typographies.subtitle3};
  color: ${({ theme }) => theme.colors.black};
  ${{ ...lineClamp(2) }}
  margin-bottom: 16px;

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.overline1}
      margin-bottom: 8px;
    }
  `}
`

export const Buttons = styled.div`
  display: flex;
  column-gap: 12px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      column-gap: 6px;
    }
  `}
`

export const PostSection = styled.section`
  padding: 0 24px;
`

export const PostSectionInner = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
`
export const Button = styled.button<{ active?: boolean }>`
  padding: 8px 16px;
  border: 1px solid
    ${({ active, theme: { colors } }) => (active ? colors.primary_color_regular : colors.black)};
  cursor: pointer;
  border-radius: 48px;
  display: inline-flex;
  align-items: center;
  ${({ theme: { typographies } }) => typographies.subtitle4}
  column-gap: 8px;
  color: ${({ active, theme: { colors } }) => (active ? colors.white : colors.black)};
  background-color: ${({ active, theme: { colors } }) =>
    active ? colors.primary_color_regular : colors.white};

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.overline2}
      padding: 4px 8px;
      column-gap: 4px;
    }
  `}

  svg {
    width: 24px;
    height: 24px;

    ${({ theme: { media } }) => `
      @media ${media.mobile} {
        width: 18px;
        height: 18px;
      }
    `}

    path {
      fill: ${({ active, theme: { colors } }) => (active ? colors.white : colors.black)};
    }
  }

  &:hover {
    border-color: ${({ theme: { colors } }) => colors.primary_color_regular};
    color: ${({ theme: { colors } }) => colors.white};
    background-color: ${({ theme: { colors } }) => colors.primary_color_regular};

    path {
      fill: ${({ theme: { colors } }) => colors.white};
    }
  }
`
