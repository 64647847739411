import styled from 'styled-components'

interface StyledProps {
  show: boolean
}

export const DesktopFloatingButton = styled.div<StyledProps>`
  position: sticky;
  top: 0;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      display: none;
    }
  `}

  .rtf {
    position: relative;
  }

  .rtf.open {
    position: absolute;
    top: 0;
  }

  .rtf.closed {
    position: absolute;
    top: 0;
  }

  .rtf.open .rtf--mb > * {
    transform-origin: center center;
    transform: rotate(180deg);
    transition: transform 0.2s ease-in-out;
  }

  .rtf--ab {
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.gray20};
    box-shadow: none;
    margin-top: 17px;
  }
`
export const MobileFloatingButton = styled.div`
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: ${({ theme: { colors } }) => colors.primary_color_regular};
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  z-index: 999;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      display: flex;
    }
  `}
`

export const MobileFloatingInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  button {
    ${({ theme }) => theme.typographies.overline2}
    color: ${({ theme: { colors } }) => colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  path {
    fill: ${({ theme: { colors } }) => colors.white};
  }
`
