import styled from 'styled-components'

export const SearchBarWrapper = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.gray50};
  padding: 14px 0;

  svg {
    width: 50px;
    height: 50px;
  }

  &:focus-within {
    border-color: ${({ theme: { colors } }) => colors.primary_color_regular};

    svg path {
      fill: ${({ theme: { colors } }) => colors.primary_color_regular};
    }
  }

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      svg {
        width: 18px;
        height: 18px;
        margin-top: 6px;
      }
    }
  `}
`

export const SearchInput = styled.input`
  ${({ theme: { typographies } }) => typographies.h4}
  flex: 1;
  padding: 0 24px;

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.body}
      padding: 0 12px;
    }
  `}
`
