import React, { useState, useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { Fab, Action } from 'react-tiny-fab'
import 'react-tiny-fab/dist/styles.css'
import { TwitterShareButton, FacebookShareButton } from 'react-share'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Icon } from 'components/atoms'
import {
  bookmark_unfilled as Bookmark,
  heart_unfilled as Heart,
  share_unfilled as Share,
  comment_unfilled as Comment,
} from 'assets/icons/svg'
import { DesktopFloatingButton, MobileFloatingButton, MobileFloatingInner } from './FloatingButton.styles'

interface FloatingButtonProps {
  likeCount: number
  scrapCount: number
  commentCount: number
  onLike: () => void
  onScrap: () => void
  onShare: () => void
  onComment: () => void
}

const FloatingButton: React.FC<FloatingButtonProps> = ({
  likeCount,
  scrapCount,
  commentCount,
  onLike,
  onScrap,
  onShare,
  onComment,
}) => {
  const theme = useContext(ThemeContext)
  const [visible, setVisible] = useState(true)
  const [urlCopy, setUrlCopy] = useState(false)
  const currentUrl = window.location.href

  return (
    <>
      <DesktopFloatingButton show={visible}>
        <Fab
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          className={`rtf ${visible ? 'open' : 'closed'}`}
          mainButtonStyles={{
            backgroundColor: theme.colors.secondary_color_regular,
            boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.15)`,
          }}
          style={{
            top: 0,
            left: '-240px',
          }}
          event='click'
          icon={<Icon icon='expand_unfilled' size='large' color='white' />}
          onClick={() => {
            setVisible((prev) => !prev)
            setUrlCopy(false)
          }}
        >
          <Action text='트위터로 공유하기'>
            <TwitterShareButton url={currentUrl}>
              <Icon icon='twitter_filled' size='middle' color='gray70' />
            </TwitterShareButton>
          </Action>
          <Action text='페이스북으로 공유하기'>
            <FacebookShareButton url={currentUrl}>
              <Icon icon='facebook_filled' size='middle' color='gray70' />
            </FacebookShareButton>
          </Action>
          <Action text={urlCopy ? '복사 완료!' : '링크 복사하기'}>
            <CopyToClipboard text={currentUrl} onCopy={() => setUrlCopy(true)}>
              <Icon icon='link_unfilled' size='middle' color='gray70' />
            </CopyToClipboard>
          </Action>
        </Fab>
      </DesktopFloatingButton>
      <MobileFloatingButton>
        <MobileFloatingInner>
          <button type='button' onClick={onLike}>
            <Heart />
            <span>{likeCount}</span>
          </button>
          <button type='button' onClick={onScrap}>
            <Bookmark />
            <span>{scrapCount}</span>
          </button>
          <button type='button' onClick={onComment}>
            <Comment />
            <span>{commentCount}</span>
          </button>
          <button type='button' onClick={onShare}>
            <Share />
          </button>
        </MobileFloatingInner>
      </MobileFloatingButton>
    </>
  )
}

export default FloatingButton
