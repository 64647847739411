import styled from 'styled-components'

export const TextFieldStyles = styled.div``

export const FieldWrapper = styled.div`
  display: flex;
  column-gap: 20px;
`

export const Label = styled.label`
  ${({ theme: { typographies } }) => typographies.smallBody}
  color: ${({ theme: { colors } }) => colors.gray80};
  display: block;
  margin-bottom: 4px;
`

export const InputWrapper = styled.div`
  position: relative;
  flex: 1;
`

export const ButtonWrapper = styled.div`
  flex-basis: 90px;

  button {
    width: 100%;
    height: 100%;
  }
`

export const Message = styled.div`
  position: absolute;
  top: 55px;
  left: 8px;
`

export const Timer = styled.span`
  color: ${({ theme: { colors } }) => colors.primary_color_regular};
  position: absolute;
  bottom: 10px;
  right: 0;
  ${({ theme: { typographies } }) => typographies.body};
`
