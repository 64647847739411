import styled from 'styled-components'

export const Banner = styled.a`
  width: 100%;
  height: 177px;
  background-color: ${({ theme }) => theme.colors.primary_color_regular};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 24px;

  ${({ theme }) => `
    @media ${theme.media.mobile} {
      height: 104px;
    }
  `}
`

export const BannerInner = styled.div`
  position: relative;
  width: 100%;
  max-width: 1080px;
  height: 100%;
`

export const BannerContents = styled.div`
  height: 100%;
  display: flex;
  column-gap: 20px;
`

export const BannerIcon = styled.div`
  ${({ theme }) => theme.typographies.largeBody}

  ${({ theme }) => `
    @media ${theme.media.mobile} {
      ${theme.typographies.overline2}
    }
  `}
`

export const BannerTitle = styled.h3`
  ${({ theme }) => theme.typographies.subtitle}
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => `
    @media ${theme.media.mobile} {
      ${theme.typographies.largeBody}
    }
  `}
`

export const BannerDescription = styled.p`
  ${({ theme }) => theme.typographies.smallBody}
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => `
    @media ${theme.media.mobile} {
      ${theme.typographies.overline2}
    }
  `}
`

export const BannerImage = styled.div`
  position: absolute;
  width: 55%;
  max-width: 595px;
  bottom: 0;
  right: 0;

  ${({ theme }) => `
    @media ${theme.media.mobile} {
      display: none;
    }
  `}
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 5px;
  padding-bottom: 20px;

  ${({ theme }) => `
    @media ${theme.media.mobile} {
      row-gap: 0;
      padding-bottom: 4px;
    }
  `}
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;

  ${({ theme }) => `
    @media ${theme.media.mobile} {
      display: none;
    }
  `}
`
