import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthTemplate } from 'components/templates'
import { EmailAuthForm, PasswordForm, PrivacyForm, TermsUseForm } from 'components/organisms'

export type Step = 0 | 1 | 2 | 3

const formComponents = [TermsUseForm, EmailAuthForm, PasswordForm, PrivacyForm]

function SignUpPage() {
  const history = useHistory()
  const [step, setStep] = useState<Step>(0)
  const FormComponent = formComponents[step]

  const handleProceed = () => {
    if (step === 3) return
    setStep((s) => (s + 1) as Step)
  }

  const handleBack = () => {
    if (step === 0) history.go(-1)
    else setStep((s) => (s - 1) as Step)
  }

  return (
    <AuthTemplate onClickBack={handleBack}>
      <FormComponent onProceed={handleProceed} />
    </AuthTemplate>
  )
}

export default SignUpPage
