import React from 'react'
import { ThemeProvider } from 'styled-components'
import { RecoilRoot } from 'recoil'
import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import Routes from 'routes'
import GlobalStyles from 'assets/styles/globalStyles'
import { theme } from 'assets/styles/theme'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { staleTime: Infinity },
  },
})

const App: React.FC = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <RecoilRoot>
        <Router>
          <Routes />
        </Router>
      </RecoilRoot>
    </QueryClientProvider>
  </ThemeProvider>
)

export default App
