import React, { ReactNode } from 'react'
import { MenuStyles } from './Menu.styles'

export interface MenuPropTypes {
  weight?: 'bold'
  children: string | ReactNode
  underline?: boolean
  onClick?: () => void
}

function Menu({ weight, children, underline, onClick }: MenuPropTypes) {
  return (
    <MenuStyles weight={weight} underline={underline} onClick={onClick}>
      {children}
    </MenuStyles>
  )
}

export default Menu
