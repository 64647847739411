import styled from 'styled-components'

export const ProfileFormStyles = styled.form`
  max-width: 532px;
  margin: 71px auto 210px;

  .label {
    width: 40%;
  }
`

export const ProfileImgWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray10};
  padding-bottom: 16px;
`

export const ProfileImg = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  img {
    aspect-ratio: 1 / 1;
  }
`

export const AddBtnWrapper = styled.div`
  position: absolute;
  right: 15px;
  bottom: 15px;
  cursor: pointer;
`

export const ProfileFormItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray10};

  :nth-child(5) {
    height: 110px;
    padding: 16px 0;
  }

  textarea {
    resize: none;
  }
`

export const EditBtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 96px;

  button {
    min-width: 223px;
    min-height: 50px;
  }
`
