import { Fragment } from 'react'
import { Img } from 'components/atoms'
import missionImage from 'assets/images/mission.png'
import missionImage2 from 'assets/images/mission2.png'
import {
  MissionWrapper,
  MissionItem,
  MissionImg,
  MissionContents,
  Title,
  Description,
} from './MissionSection.styles'

interface MissionSectionProps {
  missions: { header: string; texts: string[] }[]
}

const MissionSection: React.FC<MissionSectionProps> = ({ missions }) => {
  return (
    <MissionWrapper>
      <MissionItem>
        {missions.map((mission, idx) => {
          return (
            <Fragment key={mission.header}>
              <MissionContents>
                <Title>{mission.header}</Title>
                {mission.texts.map((text: string) => (
                  <Description key={text}>{text}</Description>
                ))}
              </MissionContents>
              {idx < 2 && (
                <MissionImg>
                  <Img
                    src={idx === 0 ? missionImage : missionImage2}
                    alt='미션 구분 이미지'
                    border='none'
                    borderRadius='none'
                  />
                </MissionImg>
              )}
            </Fragment>
          )
        })}
      </MissionItem>
    </MissionWrapper>
  )
}

export default MissionSection
