import { useRecoilState } from 'recoil'
import { signUpInfoState } from 'recoil/atoms'
import { TextField } from 'components/molecules'
import { useRegistrationForm } from 'hooks'
import { passwordFormSchema } from 'core/utils/validation'
import AuthForm from '../../AuthForm'

export interface PasswordFormPropTypes {
  /** 다음 단계로 이동 동작  */
  onProceed: () => void
}

export interface IFormInputs {
  password: string
  passwordConfirm: string
}

/** `PasswordForm` 컴포넌트의 문서입니다.  */

function PasswordForm({ onProceed }: PasswordFormPropTypes) {
  const [signUpInfo, setSignUpInfo] = useRecoilState(signUpInfoState)
  const {
    register,
    watch,
    formState: { errors, isValid },
  } = useRegistrationForm<IFormInputs>(passwordFormSchema, 'onChange')

  const handleNextStep = () => {
    setSignUpInfo({ ...signUpInfo, password: watch('password') })
    onProceed()
  }

  return (
    <AuthForm
      title='이메일로 회원가입하기'
      subTitle='비밀번호 설정하기'
      submitButtonDisabled={!isValid}
      submitButtonText='다음'
      onSubmitButtonClick={handleNextStep}
    >
      <TextField
        type='password'
        label='비밀번호'
        placeholder='비밀번호 입력하기'
        register={{ ...register('password') }}
        error={errors.password?.message}
      />
      <TextField
        type='password'
        label='비밀번호 확인'
        placeholder='비밀번호 확인하기'
        register={{ ...register('passwordConfirm') }}
        error={errors.passwordConfirm?.message}
        success={isValid ? '비밀번호가 일치합니다.' : undefined}
      />
      <AuthForm.Ul>
        <AuthForm.Li>로그인에 사용하실 비밀번호를 입력해주세요.</AuthForm.Li>
        <AuthForm.Li>
          비밀번호는 영문, 숫자, 특수문자 포함 8자리 이상으로 설정해주세요. (사용가능 특수문자 !?@#$%&* )
        </AuthForm.Li>
      </AuthForm.Ul>
    </AuthForm>
  )
}

export default PasswordForm
