import { FindPasswordForm } from 'components/organisms'
import { AuthTemplate } from 'components/templates'
import { useHistory } from 'react-router-dom'

function FindPasswordPage() {
  const history = useHistory()

  return (
    <AuthTemplate onClickBack={() => history.go(-1)}>
      <FindPasswordForm />
    </AuthTemplate>
  )
}

export default FindPasswordPage
