import { useQuery } from 'react-query'
import { useAuthentication, usePageRoute } from 'hooks'
import { getUserScrapList } from 'core/apis/api.auth'
import { VerticalCard, RouteTitle } from 'components/molecules'
import { addScrapToPosts, deleteScrapToPosts } from 'core/apis/api.post'
import { PostCard } from 'types/ContentResponse'
import { PostCategory } from 'types/PostCategory'
import { CardBannerStyles, CardBannerContents } from './CardBanner.styles'

export interface CardBannerPropTypes<CardType> {
  /** 카드 베너 제목 */
  title: string

  /** 더보기 눌렀을 때 링크. 없으면 더보기 노출안됨 */
  to?: 'sexpedia' | 'posepedia' | 'review'

  /** 카드 데이터 */
  cards: CardType[]
}

/**
 * `CardBanner` 컴포넌트
 *
 * 메인페이지의 섹션
 */
function CardBanner<CardType extends PostCard>({ title, to, cards = [] }: CardBannerPropTypes<CardType>) {
  const { user } = useAuthentication()
  const { data: scrapedCards, refetch } = useQuery(['user_scrap'], () => getUserScrapList(), {
    enabled: !!user,
  })
  const { handlePageMove } = usePageRoute()

  const addScrap = (card: CardType) => {
    addScrapToPosts(card.id).finally(() => {
      refetch()
    })
  }
  const deleteScrap = (card: CardType) => {
    deleteScrapToPosts(card.id).finally(() => {
      refetch()
    })
  }

  return (
    <CardBannerStyles>
      <RouteTitle title={title} to={to ?? ''} link={Boolean(to)} />
      <CardBannerContents>
        {cards.map((card: CardType) => {
          const scraped = !!user && scrapedCards?.content?.some(({ id }: PostCard) => id === card.id)
          const hideTags = PostCategory.Posepedia.CODE === card.category.code
          return (
            <VerticalCard
              key={card.id}
              title={card.title}
              thumbnail={card.thumbnail}
              tags={hideTags ? undefined : card.tags}
              scraped={scraped}
              smallImage={card.category.name === '체위사전'}
              onClickCard={() => handlePageMove(`/post/${card.id}`)}
              onScrap={() => (scraped ? deleteScrap(card) : addScrap(card))}
            />
          )
        })}
      </CardBannerContents>
    </CardBannerStyles>
  )
}

export default CardBanner
