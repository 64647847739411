import React, { ElementType, Ref, forwardRef } from 'react'
import styled from 'styled-components'
import { Typographies, FontColors } from 'assets/styles/types'
import { OverridableProps } from 'types/OverridableProps'
import { DEFAULT_ELEMENT } from './Text.types'

const element = React.createElement

const El: React.FC<{
  tag: ElementType
  typography: Typographies
  color: FontColors
  align?: 'center' | 'right'
}> = ({ tag, children, typography, ...props }) => element(tag, props, children)

const Typography = styled(El)`
  ${({ typography, theme }) => theme.typographies[typography]};
  color: ${({ color, theme }) => color && theme.colors[color]};
  text-align: ${({ align }) => `${align}`};
`

Typography.defaultProps = {
  tag: 'div',
}

export interface TextBaseProps {
  /** 텍스트 스타일 */
  typography: Typographies

  /** 텍스트 색깔 */
  color?: FontColors

  align?: 'center' | 'right'

  /** 텍스트 내용 */
  children: string
}

type TextProps<T extends ElementType = typeof DEFAULT_ELEMENT> = OverridableProps<T, TextBaseProps>

/** `Text` 컴포넌트의 문서입니다.  */

const Text = <T extends ElementType = typeof DEFAULT_ELEMENT>(
  {
    typography = 'h3',
    // fontWeight = "regular",
    color = 'black',
    as,
    children,
    align,
    ...props
  }: TextProps<T>,
  ref: Ref<unknown>,
) => {
  const target = as ?? DEFAULT_ELEMENT

  return (
    <Typography tag={target} ref={ref} typography={typography} color={color} align={align} {...props}>
      {children}
    </Typography>
  )
}

export default forwardRef(Text) as typeof Text
