import React, { Children, isValidElement, cloneElement } from 'react'
import { TabsContainer, TabItem } from './Tabs.styles'

interface TabProps {
  onClick?: () => void
  active?: boolean
  underline?: boolean
  wide?: boolean
}

const Tab: React.FC<TabProps> = ({
  onClick = () => {},
  active,
  underline = true,
  wide = false,
  children,
}) => (
  <TabItem onClick={onClick} active={active} underline={underline} wide={wide}>
    {children}
  </TabItem>
)

interface TabsProps {
  activeKey: string // 현재 활성화된 탭의 key
  onChange?: (key: string) => void // 탭 클릭 시 호출되는 callback
  style?: React.CSSProperties
  underline?: boolean
  wide?: boolean
}

const Tabs: React.FC<TabsProps> & { Tab: React.FC<TabProps> } = ({
  children,
  activeKey,
  onChange,
  underline,
  wide,
  ...rest
}) => {
  const newChildren = Children.map(children, (child) => {
    if (!isValidElement(child)) return child

    return cloneElement(child, {
      active: child.key === activeKey,
      underline,
      wide,
      onClick: () => {
        onChange?.(child.key as string)
      },
    })
  })

  return <TabsContainer {...rest}>{newChildren}</TabsContainer>
}

Tabs.Tab = Tab

export default Tabs
