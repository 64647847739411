import styled from 'styled-components'
import { lineClamp } from 'assets/styles/lineClamp'

export const ProfileStyles = styled.section`
  height: 363px;
  background-color: ${({ theme }) => theme.colors.gray05};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 52px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      height: 136px;
      margin-bottom: 16px;
    }
  `}
`

export const ProfileWrapper = styled.div`
  height: 80%;
  display: flex;
  align-items: center;
  column-gap: 44px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      column-gap: 20px;
      height: 72%;
    }
  `}
`

export const ProfileImg = styled.div`
  width: 256px;
  height: 256px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      width: 99px;
      height: 99px;
    }
  `}
`

export const ProfileInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 348px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      width: 142px;
      row-gap: 4px;
    }
  `}
`

export const UserInfoWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray50};
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding-bottom: 10px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      row-gap: 4px;
      padding-bottom: 4px;
    }
  `}
`

export const UserNickname = styled.div`
  ${({ theme }) => theme.typographies.subtitle2};
  color: ${({ theme }) => theme.colors.primary_color_regular};
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.smallButton}
    }
  `}
`

export const UserBirth = styled.div`
  ${({ theme }) => theme.typographies.subtitle4};
  color: ${({ theme }) => theme.colors.gray50};

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.overline2}
    }
  `}
`

export const UserIntroduction = styled.p`
  ${({ theme }) => theme.typographies.body};
  color: ${({ theme }) => theme.colors.black};
  ${{ ...lineClamp(3) }}

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.overline2}
    }
  `}
`
