import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import AuthorImg from 'assets/images/author.png'
import { Img } from 'components/atoms'
import { useAuthentication } from 'hooks'
import { email_unfilled as Email, alarm_unfilled as Alarm } from 'assets/icons/svg'
import { checkSubscription, subscribeAuthor, unsubscribeAuthor } from 'core/apis/api.author'
import {
  AuthorCardStyles,
  Buttons,
  ImgWrapper,
  TextContents,
  Button,
  Profile,
  NickName,
  Introduction,
} from './AuthorCard.styles'

export interface AuthorCardPropTypes {
  id: number
  src?: string
  email: string
  nickname: string
  introduction?: string
}

/** `AuthorCard` 컴포넌트의 문서입니다.  */
function AuthorCard({ id, src = AuthorImg, email, nickname, introduction = '' }: AuthorCardPropTypes) {
  const history = useHistory()
  const { user } = useAuthentication()
  const { data: isSubscribing, refetch } = useQuery(['author-sub-check', id], () => checkSubscription(id), {
    enabled: !!user,
    staleTime: Infinity,
  })

  const onClickSubScription = () => {
    const promise = isSubscribing ? unsubscribeAuthor(id) : subscribeAuthor(id)

    promise.finally(() => {
      refetch()
    })
  }

  return (
    <AuthorCardStyles>
      <ImgWrapper>
        <Img src={src} alt='작가 이미지' border='none' borderRadius='circle' />
      </ImgWrapper>
      <Profile>
        <TextContents>
          <NickName onClick={() => history.push(`/author/${id}`)}>{nickname}</NickName>
          <Introduction>{introduction}</Introduction>
        </TextContents>
        <Buttons>
          <CopyToClipboard text={email} onCopy={() => alert('이메일이 복사되었습니다')}>
            <Button type='button'>
              <Email />
              이메일
            </Button>
          </CopyToClipboard>
          <Button type='button' onClick={onClickSubScription}>
            <Alarm />
            {isSubscribing ? '구독중' : '구독'}
          </Button>
        </Buttons>
      </Profile>
    </AuthorCardStyles>
  )
}

export default AuthorCard
