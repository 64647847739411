import React from 'react'
import { Text } from 'components/atoms'
import { LoginFooterStyles, FooterContents, Title, SubText } from './LoginFooter.styles'

function LoginFooter() {
  return (
    <LoginFooterStyles>
      <FooterContents>
        <Text as='p' typography='subtitle2'>
          🦄
        </Text>
        <Title>비뉴의 회원이 된다면 특별한 콘텐츠를 경험할 수 있어요!</Title>
        <SubText>지금 당장 비뉴와 함께 더욱 다양한 콘텐츠를 즐겨봐요! 👏🏻👏🏻</SubText>
      </FooterContents>
    </LoginFooterStyles>
  )
}

export default LoginFooter
