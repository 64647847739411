import { Dots } from 'components/atoms'
import 'react-multi-carousel/lib/styles.css'
import { Banner } from 'types/Banner'
import { ImageSliderStyles, ImageSliderWrapper, Slide, Sub, TextWrapper, Title } from './ImageSlider.styles'

export interface ImageSliderPropTypes {
  /** 이미지 리스트 */
  slides: Banner[]
}

/** `ImageSlider` 컴포넌트의 문서입니다.  */

function ImageSlider({ slides }: ImageSliderPropTypes) {
  const responsive = {
    desktop: {
      breakpoint: { max: 5000, min: 750 },
      items: 1, // 한번에 보여질 슬라이드 수
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 750, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  }

  return (
    <ImageSliderWrapper>
      <ImageSliderStyles
        responsive={responsive}
        draggable={false}
        infinite
        arrows={false}
        // autoPlay
        autoPlaySpeed={3000}
        customDot={<Dots />}
        showDots
        renderDotsOutside={false}
        dotListClass='dotList'
      >
        {slides.map(({ title, text, linkUrl, bgColor, id, thumbnailUrl }, idx: number) => {
          const titles = (title || '').split('\n')
          return (
            <Slide
              className='carousel-item'
              key={id}
              color={bgColor}
              href={linkUrl}
              target='_blank'
              rel='noreferrer'
              bgImg={thumbnailUrl}
            >
              <TextWrapper>
                <Title dangerouslySetInnerHTML={{ __html: titles.slice(0, 2).join('<br />') }} />
                <Sub dangerouslySetInnerHTML={{ __html: String(text || '') }} />
              </TextWrapper>
            </Slide>
          )
        })}
      </ImageSliderStyles>
    </ImageSliderWrapper>
  )
}

export default ImageSlider
