import styled from 'styled-components'
import { HeaderStyles } from 'components/organisms/Header/Header.styles'

export const BannerWrapper = styled.div`
  margin-bottom: 67px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      display: none;
    }
  `}
`

export const MobileHeader = styled.div`
  display: none;

  ${({ theme: { media, colors } }) => `
    @media ${media.mobile} {
      display: block;
      position: fixed;
      width: 100%;
      top: 0;
      background: ${colors.white};
      z-index: 100;
    }
  `}
`

/**
 * Desktop 일 때는 BaseTemplate의 Header 컴포넌트 노출
 * Mobile 일 때는 BaseTemplate의 Header 없애고, MobileHeader 내의 Header 컴포넌트 노출
 */
export const FixedHeaderWrapper = styled.div`
  ${HeaderStyles} {
    display: block;

    ${({ theme: { media } }) => `
      @media ${media.mobile} {
        display: none;
      }
    `}
  }

  ${MobileHeader} ${HeaderStyles} {
    display: none;

    ${({ theme: { media } }) => `
      @media ${media.mobile} {
        display: block;
      }
    `}
  }
`

export const TabsWrapper = styled.div`
  height: 50px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray10};
`

export const ListWrapper = styled.section`
  width: 100%;
  padding: 0 24px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      margin-top: 134px;
    }
  `}
`

export const ListWrapperInner = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
`
export const MobileChipsWrapper = styled.div`
  display: none;
  margin: 0 -24px 24px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      display: block;
    }
  `}

  > div {
    > button:first-child {
      margin-left: 24px;
    }

    > button:last-child {
      margin-right: 24px;
    }
  }
`
