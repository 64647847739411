import { atom } from 'recoil'
import { SignUpInfo } from 'types/auth.type'
import { Gender, User } from 'types/User'

type MyPageStep = '1' | '2' | '3'

export const userState = atom<User | null>({
  key: 'userState',
  default: null,
})

export const signUpInfoState = atom<SignUpInfo>({
  key: 'SignUpInfoState',
  default: {
    adultCertificated: false,
    agreements: [],
    atLeast14: true, // 고정값
    birthYear: 0,
    email: '',
    emailVerified: true,
    imageUrl: '', // 고정값
    introduction: '', // 고정값
    nickname: '',
    password: '',
    provider: 'LOCAL', // 고정값
    roles: ['ROLE_USER'], // 고정값
    sex: Gender.고민중,
  },
})

export const myPageStepState = atom<MyPageStep>({
  key: 'myPageStepState',
  default: '1',
})

export const profileState = atom<User | null>({
  key: 'profileImgState',
  default: null,
})

export const unregisterState = atom<boolean>({
  key: 'unregisterFormState',
  default: false,
})
