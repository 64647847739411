import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
  row-gap: 16px;
  margin-bottom: 48px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      padding-bottom: 56px;
      margin-bottom: 28px;
    }
  `}
`

export const Title = styled.h4`
  ${({ theme: { typographies } }) => typographies.subtitle2}
  color: ${({ theme: { colors } }) => colors.primary_color_regular};
  flex-shrink: 0;

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.smallButton}
    }
  `}
`

export const Filter = styled.div`
  width: 100%;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      position: absolute;
      left: 0;
      padding-left: 24px;
      margin-top: 35px;
    }
  `}
`
