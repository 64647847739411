import styled from 'styled-components'

export const BannerStyles = styled.section<{ bannerImg: string }>`
  padding: 60px 24px 0;
  height: 314px;
  background-image: url(${({ bannerImg }) => bannerImg});
  background-position: center;
`

export const BannerContents = styled.div`
  max-width: 1100px;
  color: ${({ theme }) => theme.colors.black};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ChipsGroup = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 16px;
  max-width: 400px;
  width: fit-content;
`
