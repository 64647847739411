import { useState } from 'react'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { getAboutTeam, getAboutUs } from 'core/apis/api.about'
import { PageHeader, Tabs } from 'components/molecules'
import { BrandStorySection, MissionSection, MemberCardSection } from 'components/organisms'
import { BaseTemplate } from 'components/templates'

export const AboutContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 200px;
`

export const TabsContainer = styled.div<{ border: boolean }>`
  width: 100%;
  margin-top: 104px;
  margin-bottom: 90px;
  height: 50px;

  ${({ border, theme: { media, colors } }) => `
    @media ${media.mobile} {
      margin: 0;
      border-bottom: ${border ? `1px solid ${colors.gray10}` : 0};
    }
  `}
`

export const MemberSectionContainer = styled.div`
  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      padding-top: 24px;
    }
  `}
`

function AboutPage() {
  const { data: members, isLoading } = useQuery(['team_us'], getAboutUs)
  const { data: teams, isLoading: teamLoading } = useQuery(['team_data'], getAboutTeam)
  const [tab, setTab] = useState<string>('brand')

  const { brandStory, missionValue } = teams ?? {}

  return (
    <BaseTemplate loading={isLoading || teamLoading}>
      <PageHeader>About us</PageHeader>
      <AboutContainer>
        <TabsContainer border={tab !== 'brand'}>
          <Tabs activeKey={tab} onChange={setTab}>
            <Tabs.Tab key='brand'>{brandStory?.title}</Tabs.Tab>
            <Tabs.Tab key='mission'>{missionValue?.title}</Tabs.Tab>
            <Tabs.Tab key='members'>Members</Tabs.Tab>
          </Tabs>
        </TabsContainer>
        {tab === 'brand' && (
          <BrandStorySection
            title={brandStory?.content?.header ?? ''}
            description={brandStory?.content?.texts?.[0] ?? ''}
            contents={brandStory?.content?.texts?.slice(1) ?? []}
          />
        )}
        {tab === 'mission' && <MissionSection missions={missionValue?.content || []} />}
        {tab === 'members' && (
          <MemberSectionContainer>
            <MemberCardSection members={members || []} />
          </MemberSectionContainer>
        )}
      </AboutContainer>
    </BaseTemplate>
  )
}

export default AboutPage
