import { useState } from 'react'

export const useToastPop = () => {
  const [isToastOn, setIsToastOn] = useState<boolean>(false)

  const handleToastPop = (time: number) => {
    setIsToastOn(true)

    setTimeout(() => {
      setIsToastOn(false)
    }, time)
  }

  return { isToastOn, handleToastPop }
}
