import React from 'react'
import { Link, Icon } from 'components/atoms'
import { RouteTitleStyles, RouteBtn, Title } from './RouteTitle.styles'

export interface RouteTitlePropTypes {
  /** 제목 */
  title: string

  /** 이동 경로 */
  to: string

  link?: boolean
}

/** `RouteTitle` 컴포넌트의 문서입니다.  */

function RouteTitle({ title, to, link }: RouteTitlePropTypes) {
  return (
    <RouteTitleStyles>
      <Title>{title}</Title>
      {link && (
        <RouteBtn>
          <Link to={to} typography='subtitle4'>
            더보기
          </Link>
          <Icon icon='moving_right_unfilled' color='black' size='small' />
        </RouteBtn>
      )}
    </RouteTitleStyles>
  )
}

export default RouteTitle
