export const PostCategory = {
  Sexpedia: {
    CODE: 'SEXPEDIA',
    KO: '성과사전',
    ID: 1,
  },
  Posepedia: {
    CODE: 'POSEPEDIA',
    KO: '체위사전',
    ID: 2,
  },
  Review: {
    CODE: 'SEXPLORATION',
    KO: '성층탐구',
    ID: 5,
  },
} as const

export type PostCategoryId = typeof PostCategory[keyof typeof PostCategory]['ID']
