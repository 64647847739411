import { Key } from 'react'
import { Comment } from 'types/Comment'
import { API, AuthAPI } from './api-factory'

export const getComments = async (postId: Key) => {
  const { data } = await API().get<Comment[]>(`/api/v1/comments/posts/${postId}`)
  return data
}

export const getBestComments = async (postId: Key) => {
  const { data } = await API().get<Comment[]>(`/api/v1/comments/top3/${postId}`)
  return data
}

export const createComment = async (postId: Key, content: string) => {
  const { data } = await AuthAPI().post<Comment>(`/api/v1/comments/posts/${postId}`, { content })
  return data
}

export const updateComment = async (commentId: Key, content: string) => {
  const { data } = await AuthAPI().put<Comment>(`/api/v1/comments/${commentId}`, { content })
  return data
}

export const createReply = async (commentId: Key, content: string) => {
  const { data } = await AuthAPI().post<Comment>(`/api/v1/comments/${commentId}`, { content })
  return data
}

export const deleteComment = (commentId: Key) => {
  return AuthAPI().delete(`/api/v1/comments/${commentId}`)
}

export const reportComment = (commentId: Key, reason: string) => {
  return AuthAPI().post(`/api/v1/comments/${commentId}/reports`, { reason })
}

export const likeComment = (commentId: Key) => {
  return AuthAPI().post(`/api/v1/comments/${commentId}/likes`)
}

export const unlikeComment = (commentId: Key) => {
  return AuthAPI().delete(`/api/v1/comments/${commentId}/likes`)
}
