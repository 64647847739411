import styled, { css } from 'styled-components'

export const DotsStyles = styled.div<{ active?: boolean }>`
  width: 8px;
  height: 8px;
  border-color: ${({ theme }) => theme.colors.gray05};
  border-width: 5px;
  border-style: solid;
  border-radius: 50%;
  display: inline-block;

  ${({ active }) =>
    active &&
    css`
      border-width: 5px 30px;
      border-radius: 16px;
      border-color: ${({ theme }) => theme.colors.warning_color_regular};
    `}

  &:hover {
    cursor: pointer;
  }

  ${({ theme }) => `
    @media ${theme.media.mobile} {
      width: 4px;
      height: 4px;
      border-width: 2px;
    }
  `}
`
