import styled from 'styled-components'

export const MemberList = styled.ul`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  max-width: 1132px;
  gap: 20px;
  padding: 0 24px;
`

export const ListItem = styled.li`
  position: relative;
  flex: 0 1 calc((100% - 40px) / 3);

  ${({ theme }) => `
    @media ${theme.media.mobile} {
      flex: 0 1 calc((100% - 20px) / 2);
    }
  `}
`
