import { PageHeader } from 'components/molecules'
import { CardList, TitleWithFilter } from 'components/organisms'
import { BaseTemplate } from 'components/templates'
import { PaginationResponse, PostCard } from 'types/ContentResponse'
import { Author } from 'types/Author'
import DefaultProfile from 'assets/images/default_profile.png'
import { PostCategory } from 'types/PostCategory'
import { alarm_unfilled as Alarm, email_unfilled as Email } from 'assets/icons/svg'
import {
  PostSection,
  PostSectionInner,
  AuthorSection,
  AuthorSectionInner,
  ProfileImage,
  Profile,
  NickName,
  Introduction,
  Buttons,
  Button,
} from './AuthorTemplate.styles'
import { BaseTemplateProps } from '../BaseTemplate/BaseTemplate'

interface AuthorTemplateProps extends BaseTemplateProps {
  author: Author
  posts: PaginationResponse<PostCard>
  selectedCategoryId: number | undefined
  onClickChips: (id: React.Key, selected: boolean) => void
  isSubscribing: boolean
  onClickSubscription: () => void
}

const AuthorTemplate: React.FC<AuthorTemplateProps> = ({
  loading,
  author,
  posts,
  selectedCategoryId,
  onClickChips,
  isSubscribing,
  onClickSubscription,
}) => {
  const options = Object.values(PostCategory).map(({ KO: label, ID: id }) => ({ id, label }))
  const { imageUrl, nickname = '', introduction = '', email = '' } = author ?? {}

  return (
    <BaseTemplate loading={loading}>
      <PageHeader>작가페이지</PageHeader>
      {/* 작가 프로필 */}
      <AuthorSection>
        <AuthorSectionInner>
          <ProfileImage>
            <img src={imageUrl || DefaultProfile} alt={nickname} />
          </ProfileImage>
          <Profile>
            <NickName>{nickname}</NickName>
            <Introduction>{introduction}</Introduction>
            <Buttons>
              <Button
                onClick={() => {
                  window.location.href = `mailto:${email}`
                }}
              >
                <Email />
                이메일
              </Button>
              <Button onClick={onClickSubscription}>
                <Alarm />
                {isSubscribing ? '구독중' : '구독'}
              </Button>
            </Buttons>
          </Profile>
        </AuthorSectionInner>
      </AuthorSection>

      {/* 포스트 */}
      <PostSection>
        <PostSectionInner>
          <TitleWithFilter
            options={options}
            selectedId={selectedCategoryId}
            title='작가의 글'
            onClick={onClickChips}
          />
          <CardList cards={posts?.content} showSortingButtons={false} showDescription />
        </PostSectionInner>
      </PostSection>
    </BaseTemplate>
  )
}

export default AuthorTemplate
