import React, { useState } from 'react'
import { Tabs } from 'components/molecules'
import { AccountForm, ProfileEditForm } from 'components/organisms'
import { AccountSettingStyles, TabsWrapper } from './AccountSetting.styles'

function AccountSetting() {
  const [selectedTab, setSelectedTab] = useState('profile')

  return (
    <AccountSettingStyles>
      <TabsWrapper>
        <Tabs activeKey={selectedTab} onChange={setSelectedTab} wide>
          <Tabs.Tab key='profile'>프로필 설정</Tabs.Tab>
          <Tabs.Tab key='account'>계정 설정</Tabs.Tab>
        </Tabs>
      </TabsWrapper>
      {selectedTab === 'profile' ? <ProfileEditForm /> : <AccountForm />}
    </AccountSettingStyles>
  )
}

export default AccountSetting
