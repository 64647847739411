import React from 'react'
import { Icon } from 'components/atoms'
import {
  CardWrapper,
  CardImgWrapper,
  CardImg,
  CardTitle,
  CardIconGroup,
  Title,
  TagText,
  Description,
} from './VerticalCard.styles'

interface Tag {
  id: number
  name: string
}

interface VerticalCardProps {
  title: string
  tags?: Tag[]
  description?: string
  thumbnail: string
  scraped?: boolean
  onClickCard?: () => void
  onScrap?: () => void
  smallImage?: boolean
}

const VerticalCard: React.FC<VerticalCardProps> = ({
  title,
  tags = [],
  description,
  thumbnail,
  scraped = false,
  onClickCard = () => {},
  onScrap = () => {},
  smallImage = false,
}) => {
  const handleScrap: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
    onScrap()
  }

  return (
    <CardWrapper onClick={onClickCard}>
      <CardImgWrapper>
        <CardImg thumbnail={thumbnail} smallImage={smallImage} />
        <CardIconGroup>
          <Icon
            icon={scraped ? 'bookmark_filled' : 'bookmark_transparent'}
            color={scraped ? 'primary_color_regular' : 'white'}
            size='middle'
            onClick={handleScrap}
          />
        </CardIconGroup>
      </CardImgWrapper>
      <CardTitle>
        <Title>{title}</Title>
      </CardTitle>
      {description && <Description>{description}</Description>}
      {tags?.map(({ id, name }, idx) => (
        <TagText key={id}>{`${idx > 0 ? '·' : ''}${name}`}</TagText>
      ))}
    </CardWrapper>
  )
}

export default VerticalCard
