import { Icon } from 'components/atoms'
import { SearchBarWrapper, SearchInput } from './SearchBar.styles'

type SearchBarProps = React.InputHTMLAttributes<HTMLInputElement>

const SearchBar: React.FC<SearchBarProps> = ({ placeholder = '검색', ...rest }) => {
  return (
    <SearchBarWrapper>
      <Icon icon='search_unfilled' size='middle' color='black' />
      <SearchInput type='text' placeholder={placeholder} {...rest} />
    </SearchBarWrapper>
  )
}

export default SearchBar
