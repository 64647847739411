import styled from 'styled-components'

export const MainFooterStyles = styled.footer``

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.black};
`

export const FooterContents = styled.div`
  width: 100%;
  max-width: 1080px;
  padding: 55px 0;
  margin: 0 24px;
`

export const IconsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  ${({ theme }) => `
    @media ${theme.media.mobile} {
      margin-bottom: 32px;
    }
  `}
`

export const LinksWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  ${({ theme }) => `
    @media ${theme.media.mobile} {
      flex-direction: column-reverse;
      align-items: flex-start;
      row-gap: 20px;
    }
  `}
`

export const FooterSNSIcons = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 20px;
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 10px;

  ${({ theme }) => `
    @media ${theme.media.mobile} {
      align-items: flex-start;
    }
  `}
`

export const CopyRight = styled.p`
  ${({ theme }) => theme.typographies.smallBody}
  color: ${({ theme }) => theme.colors.white};

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.smallBody2}
    }
  `}
`

export const InfoSub = styled.p`
  ${({ theme }) => theme.typographies.smallBody}
  color: ${({ theme }) => theme.colors.white};

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.overline1}
    }
  `}
`

export const RouteWrapper = styled.div`
  display: flex;
  column-gap: 20px;
`
