/* eslint-disable jsx-a11y/label-has-associated-control */
import styled from 'styled-components'
import { useState } from 'react'
import { Modal } from 'components/molecules'
import { ModalProps } from 'components/molecules/Modal/Modal'

const ReportForm = styled.form`
  label {
    display: block;
    margin-bottom: 24px;
  }

  input {
    margin-right: 8px;
  }
`

const Description = styled.p`
  border-top: 1px solid ${({ theme }) => theme.colors.gray30};
  padding-top: 16px;
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.typographies.overline1}
`

interface CommentReportModalProps extends Omit<ModalProps, 'onOk'> {
  onOk: (reason: string) => void
}

const CommentReportModal: React.FC<CommentReportModalProps> = ({ visible, onOk, onCancel }) => {
  const reportItems = [
    { text: '상업적 광고 및 스팸' },
    { text: '포르노 또는 음란물' },
    { text: '욕설 및 비하 표현' },
    { text: '개인정보 노출' },
    { text: '불쾌한 표현' },
  ]
  const [checkedValue, setCheckValue] = useState(reportItems[0].text)

  return (
    <Modal
      visible={visible}
      title='신고하기'
      okText='신고하기'
      onOk={() => onOk(checkedValue)}
      onCancel={onCancel}
    >
      <ReportForm>
        {reportItems.map(({ text }) => (
          <label key={text}>
            <input
              type='radio'
              name='report-reason'
              value={text}
              checked={text === checkedValue}
              onChange={({ target }) => setCheckValue(target.value)}
            />
            {text}
          </label>
        ))}
        <Description>
          불법적인 내용이거나, 서비스 이용 목적에 부합하지 않는 댓글을 신고해 주세요. 신고하신 댓글은
          운영정책에 따라 처리되면, 허위 신고 시 이용에 제한을 받을 수 있습니다.
        </Description>
      </ReportForm>
    </Modal>
  )
}
export default CommentReportModal
