import styled from 'styled-components'

export const CardBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 56px;
  max-width: 1148px;
  margin: 0 auto clamp(112px, 20%, 210px);
  padding: 56px 24px 0;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      row-gap: 32px;
      padding: 32px 24px 0;
    }
  `}
`
