import React, { useEffect } from 'react'
import { useToastPop } from 'hooks'
import { SignUpToastStyles, ToastInner } from './SignUpToast.styles'

interface SignUpToastProps {
  children: string
}

function SignUpToast({ children }: SignUpToastProps) {
  const { isToastOn: isToastForFadeOut, handleToastPop } = useToastPop()

  useEffect(() => {
    handleToastPop(3000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SignUpToastStyles>
      <ToastInner isToastForFadeOut={isToastForFadeOut}>{children}</ToastInner>
    </SignUpToastStyles>
  )
}

export default SignUpToast
