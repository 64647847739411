import styled from 'styled-components'
import Carousel from 'react-multi-carousel'

export const ImageSliderWrapper = styled.section`
  position: relative;
  width: 100%;
`

export const ImageSliderStyles = styled(Carousel)`
  .dotList {
    display: inline-flex;
    justify-content: flex-start;
    column-gap: 8px;
    position: absolute;
    bottom: 6%;
    max-width: 1148px;
    width: 100%;
    margin: 0 auto;
    padding: 0 24px;

    ${({ theme }) => `
      @media ${theme.media.mobile} {
        column-gap: 4px;
        bottom: 10%;
      }
    `}
  }

  .active {
    border-width: 5px 30px;
    border-radius: 16px;
    transition-duration: 0.5s;
    border-color: ${({ theme }) => theme.colors.warning_color_regular};

    ${({ theme }) => `
      @media ${theme.media.mobile} {
        height: 0;
        border-width: 2px 12px;
      }
    `}
  }
`

export const Slide = styled.a<{ color: string; bgImg: string }>`
  position: relative;
  display: flex;
  width: 100%;
  height: 447px;
  padding: 0 24px;
  align-items: center;
  background-color: ${({ color }) => `#${color}`};
  background-image: url('${({ bgImg }) => bgImg}');
  background-position: center right 35%;
  background-size: cover;

  ${({ theme }) => `
    @media ${theme.media.mobile} {
      height: 176px;
    }
  `}
`

export const TextWrapper = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
`

export const Title = styled.h3`
  ${({ theme }) => theme.typographies.h3}
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 28px;

  ${({ theme: { typographies, media } }) => `
    @media ${media.mobile} {
      ${typographies.subtitle3}
      margin-bottom: 4px;
    }
  `}
`

export const Sub = styled.p`
  ${({ theme }) => theme.typographies.subtitle}
  color: ${({ theme }) => theme.colors.white};

  ${({ theme: { typographies, media } }) => `
    @media ${media.mobile} {
      ${typographies.body}
    }
  `}
`
