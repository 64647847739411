import { Logo, Icon, Link } from 'components/atoms'
import { FooterBanner } from 'components/molecules'
import {
  MainFooterStyles,
  FooterWrapper,
  FooterContents,
  RouteWrapper,
  InfoWrapper,
  FooterSNSIcons,
  IconsWrapper,
  LinksWrapper,
  InfoSub,
  CopyRight,
} from './MainFooter.styles'

/** `Footer` 컴포넌트의 문서입니다.  */

function MainFooter() {
  return (
    <MainFooterStyles>
      <FooterBanner />
      <FooterWrapper>
        <FooterContents>
          <IconsWrapper>
            <Logo color='white' mobileSize={50} />
            <FooterSNSIcons>
              <Icon
                size='middle'
                icon='twitter_filled'
                color='white'
                cursor
                onClick={() => window.open('https://twitter.com/benew_io')}
              />
              <Icon
                size='middle'
                icon='instagram_unfilled'
                color='white'
                cursor
                onClick={() => window.open('https://www.instagram.com/benew_io/?hl=ko')}
              />
            </FooterSNSIcons>
          </IconsWrapper>
          <LinksWrapper>
            <CopyRight>©️ Do-it, All rights reserved</CopyRight>
            <InfoWrapper>
              <RouteWrapper>
                <Link
                  typography='smallBody'
                  color='white'
                  to='#'
                  onClick={() =>
                    window.open('https://benew-project.notion.site/e4e1035822c746cc8b662e50b7978758')
                  }
                >
                  고객센터
                </Link>
                <Link
                  typography='smallBody'
                  color='white'
                  to='#'
                  onClick={() =>
                    window.open('https://benew-project.notion.site/4c8727307fd94975b35d0c1147f97fac')
                  }
                >
                  이용약관
                </Link>
                <Link
                  typography='smallBody'
                  color='white'
                  to='#'
                  onClick={() =>
                    window.open('https://benew-project.notion.site/38c57f014aa84a208c603eecd6211cf0')
                  }
                >
                  개인정보 처리방침
                </Link>
              </RouteWrapper>
              <InfoSub>문의 : benew.contact@gmail.com</InfoSub>
            </InfoWrapper>
          </LinksWrapper>
        </FooterContents>
      </FooterWrapper>
    </MainFooterStyles>
  )
}

export default MainFooter
