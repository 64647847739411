import styled from 'styled-components'

export const InterviewCardStyles = styled.div`
  display: flex;
  position: relative;
  background-color: ${({ theme }) => theme.colors.gray10};
  width: 100%;
  height: 400px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  cursor: pointer;
`

export const ImgWrapper = styled.div`
  width: 50%;
  border-radius: 16px 0 0 16px;
`

export const InterviewCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* row-gap: 42px; */
  width: 50%;
  padding: 46px 127px 46px 46px;
`

export const EllipsisContent = styled.div`
  /* 여러 줄 자르기 추가 스타일 */
  white-space: normal;
  line-height: 1.2;
  text-align: left;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const IconGroup = styled.div`
  width: 58px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 24px;
  right: 24px;
`
