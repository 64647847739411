import styled from 'styled-components'

export const ChipsStyles = styled.button`
  min-width: 54px;
  height: 31px;
  color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  padding: 0px 10px;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primary_color_light};
    border: 1px solid ${({ theme }) => theme.colors.primary_color_light};
    cursor: pointer;
  }

  &:active,
  &.active {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primary_color_regular};
    border: 1px solid ${({ theme }) => theme.colors.primary_color_regular};
  }
`
