import React, { useState, useCallback } from 'react'

export const useInput = (initialValue = '') => {
  const [value, setValue] = useState(initialValue)

  const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    setValue((e?.target as HTMLInputElement)?.value || '')
  }, [])

  return [value, onChange, setValue] as const
}
