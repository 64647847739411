import { useQuery, useQueryClient } from 'react-query'
import { getUserScrapList } from 'core/apis/api.auth'
import { addScrapToPosts, deleteScrapToPosts } from 'core/apis/api.post'
import { VerticalCard, MoreButton } from 'components/molecules'
import { useAuthentication, usePageRoute } from 'hooks'
import { usePagination } from 'hooks/usePagination'
import { PostCard } from 'types/ContentResponse'
import { PostCategory } from 'types/PostCategory'
import { recent_unfilled as Recent, thumb_unfilled as Thumb } from 'assets/icons/svg'
import { CardListStyles, IconBtnGroup, CardListContents, MoreButtonWrapper, IconBtn } from './CardList.styles'

export interface CardListPropTypes<CardType> {
  /** 카드 데이터 */
  cards?: CardType[]

  showSortingButtons?: boolean

  showDescription?: boolean

  showMoreButton?: boolean

  onClickMoreButton?: () => void
}

const NO_OP = () => {}

/**
 * CardList 컴포넌트
 * 인피티니 스크롤이 적용된 카드 리스트
 */
function CardList<CardType extends PostCard>({
  cards,
  showSortingButtons = true,
  showDescription = false,
  showMoreButton = false,
  onClickMoreButton = NO_OP,
}: CardListPropTypes<CardType>) {
  const queryClient = useQueryClient()
  const { user } = useAuthentication()
  const { data: scrapedCards } = useQuery(['user_scrap'], () => getUserScrapList(), {
    enabled: !!user,
  })
  const { onChangeSorting, sort } = usePagination()
  const { handlePageMove } = usePageRoute()

  const addScrap = (card: CardType) => {
    addScrapToPosts(card.id).finally(() => {
      queryClient.invalidateQueries(['user_scrap'])
    })
  }

  const deleteScrap = (card: CardType) => {
    deleteScrapToPosts(card.id).finally(() => {
      queryClient.invalidateQueries(['user_scrap'])
    })
  }

  return (
    <CardListStyles>
      {/* 정렬 버튼 */}
      {showSortingButtons && (
        <IconBtnGroup>
          <IconBtn onClick={() => onChangeSorting('recent')} active={sort !== 'popularity'}>
            <Recent />
            최신순
          </IconBtn>
          <IconBtn onClick={() => onChangeSorting('popularity')} active={sort === 'popularity'}>
            <Thumb />
            조회순
          </IconBtn>
        </IconBtnGroup>
      )}

      {/* 카드 리스트 영역 */}
      <CardListContents>
        {cards?.map((card: CardType) => {
          const scraped = !!user && scrapedCards?.content?.some(({ id }: PostCard) => id === card.id)
          const hideTags = PostCategory.Posepedia.CODE === card.category.code
          return (
            <VerticalCard
              key={card.id}
              title={card.title}
              thumbnail={card.thumbnail}
              tags={hideTags ? undefined : card.tags}
              description={showDescription ? card.description : undefined}
              scraped={scraped}
              smallImage={card.category.name === '체위사전'}
              onClickCard={() => handlePageMove(`/post/${card.id}`)}
              onScrap={() => (scraped ? deleteScrap(card) : addScrap(card))}
            />
          )
        })}
      </CardListContents>

      {/* 더보기 버튼 영역 */}
      {showMoreButton && (
        <MoreButtonWrapper>
          <MoreButton onClickButton={onClickMoreButton} />
        </MoreButtonWrapper>
      )}
    </CardListStyles>
  )
}

export default CardList
