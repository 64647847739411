import { MemberCard } from 'components/molecules'
import { Member } from 'types/Member'
import { MemberList, ListItem } from './MemberCardSection.styles'

interface MemberCardSectionProps {
  members: Member[]
}

const MemberCardSection: React.FC<MemberCardSectionProps> = ({ members }) => {
  return (
    <MemberList>
      {members.map((member) => (
        <ListItem key={member.name + member.image}>
          <MemberCard member={member} />
        </ListItem>
      ))}
    </MemberList>
  )
}

export default MemberCardSection
