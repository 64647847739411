import styled from 'styled-components'
// import { PaginationPropTypes } from "./Pagination";

interface StyledPaginationPropTypes {
  currentPage: number
  pageNumber: number
}

export const PaginationStyles = styled.nav`
  display: flex;
  align-items: center;
`

export const PageNumberList = styled.ul`
  /* width: 188px; */
  display: flex;
  column-gap: 32px;
  align-items: center;
  margin: 0 40px;
  color: ${({ theme }) => theme.colors.gray50};
`

export const PageNumber = styled.li<StyledPaginationPropTypes>`
  display: inline-block;
  color: ${({ currentPage, pageNumber, theme }) =>
    currentPage === pageNumber ? theme.colors.black : theme.colors.gray50 || theme.colors.gray50};
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.black};
  }
  &:visited {
    color: ${({ theme }) => theme.colors.black};
  }
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`
