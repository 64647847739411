import React from 'react'
import { Input, Button, Text } from 'components/atoms'
import {
  TextFieldStyles,
  FieldWrapper,
  InputWrapper,
  Message,
  ButtonWrapper,
  Label,
  Timer,
} from './TextField.styles'

export interface TextFieldPropTypes extends React.InputHTMLAttributes<HTMLInputElement> {
  /** 텍스트 필드 속성 */
  type: string

  /** 텍스트 필드 labe에 들어갈 텍스트 */
  label?: string

  /** placeholder 문구 */
  placeholder: string

  /** 버튼 생성 및 텍스트 설정 */
  buttonText?: string

  /** 텍스트 필드 유효성 검사 */
  register?: Record<string, unknown>

  /** 버튼 활성화 설정 */
  disabled?: boolean

  /** 버튼 동작 */
  onClick?: () => void

  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void

  /** 에러 상태 메시지 */
  error?: string

  /** 성공 상태 메시지 */
  success?: string

  value?: string

  timer?: string

  // direction: "row" | "column";
}

/** `TextField` 컴포넌트의 문서입니다.  */

function TextField({
  type,
  label,
  placeholder,
  buttonText,
  register,
  disabled,
  onClick,
  onBlur,
  error,
  success,
  timer,
  ...rest
}: TextFieldPropTypes) {
  const isError = !!error
  const isSuccess = !error && !!success

  return (
    <TextFieldStyles>
      {label && <Label>{label}</Label>}
      <FieldWrapper>
        <InputWrapper>
          <Input
            type={type}
            placeholder={placeholder}
            border='underline'
            register={register}
            error={isError}
            success={isSuccess}
            onBlur={onBlur}
            {...rest}
            style={{ paddingRight: timer ? '48px' : 0 }}
          />
          {timer && <Timer>{timer}</Timer>}
          {isError && (
            <Message>
              <Text as='p' typography='overline2' color='error_color_regular'>
                {error!}
              </Text>
            </Message>
          )}
          {isSuccess && (
            <Message>
              <Text as='p' typography='overline2' color='success_color_regular'>
                {success!}
              </Text>
            </Message>
          )}
        </InputWrapper>
        {buttonText && (
          <ButtonWrapper>
            <Button
              type='button'
              variant='fill'
              disabled={disabled}
              size='middle'
              onClick={onClick}
              typography='smallButton'
            >
              {buttonText}
            </Button>
          </ButtonWrapper>
        )}
      </FieldWrapper>
    </TextFieldStyles>
  )
}

export default TextField
