import styled from 'styled-components'

export const LogoStyles = styled.div<{ size: number; mobileSize: number }>`
  width: ${({ size }) => size}px;
  aspect-ratio: 5 / 4;
  cursor: pointer;

  ${({ theme, mobileSize }) => `
    @media ${theme.media.mobile} {
      width: ${mobileSize}px;
    }
  `}
`

export const LogoImg = styled.img`
  width: 100%;
`
