import React from 'react'
import { LinkProps } from 'react-router-dom'
import { Typographies, FontColors } from 'assets/styles/types'
import { LinkStyles } from './Link.styles'

export interface LinkPropTypes extends LinkProps {
  /** 링크 텍스트 스타일 */
  typography?: Typographies

  /** 링크 텍스트 색상 */
  color?: FontColors

  /** 밑줄 유무 */
  underline?: boolean

  onClick?: () => void
}

/** `Link` 컴포넌트의 문서입니다.  */

function Link({ typography = 'body', color, underline, children, onClick, ...rest }: LinkPropTypes) {
  return (
    <LinkStyles {...rest} typography={typography} color={color} underline={underline} onClick={onClick}>
      {children}
    </LinkStyles>
  )
}

export default Link
