export const Gender = {
  여성: 'FEMALE',
  남성: 'MALE',
  인터섹스: 'SECRET',
  고민중: 'OTHER',
} as const

export type Role = 'ROLE_USER' | 'ROLE_ADMIN' | 'ROLE_EDITOR' | 'ROLE_WHITE'

export type Provider = 'LOCAL' | 'KAKAO' | 'FACEBOOK'

export interface User {
  id: number
  adultCertificated: boolean
  birthYear: number
  createdDate: string
  deleted: boolean
  email: string
  emailVerified: boolean
  imageUrl: string
  introduction: string
  nickname: string
  provider: Provider
  roles: Role[]
  sex: typeof Gender[keyof typeof Gender]
}
