import styled from 'styled-components'

export const DimmedBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000044;
  z-index: 1;
`

export const ModalWrapper = styled.aside`
  background-color: ${({ theme }) => theme.colors.white};
  position: absolute;
  width: 532px;
  top: 352px;
  left: calc(50% - 266px);
  padding: 54px;
`

export const ModalContent = styled.div`
  padding: 14px 0 32px;
`

export const ModalButtonWrapper = styled.div`
  display: flex;
  column-gap: 20px;
  justify-content: center;
`
