import { RadioChips } from 'components/molecules'
import { RadioChipsProps } from 'components/molecules/RadioChips/RadioChips'
import { Wrapper, Title, Filter } from './TitleWithFilter.styles'

interface TitleWithFilterProps extends RadioChipsProps {
  title: string
}

const TitleWithFilter: React.FC<TitleWithFilterProps> = ({ title, ...rest }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Filter>
        <RadioChips {...rest} />
      </Filter>
    </Wrapper>
  )
}

export default TitleWithFilter
