import { Icon, Img } from 'components/atoms'
import DefaultImg from 'assets/images/default_profile.png'
import { userState, myPageStepState } from 'recoil/atoms'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { Gender } from 'types/User'
import {
  ProfileInfoWrapper,
  ProfileImg,
  ProfileStyles,
  ProfileWrapper,
  UserInfoWrapper,
  UserNickname,
  UserBirth,
  UserIntroduction,
} from './Profile.styles'

function Profile() {
  const user = useRecoilValue(userState)
  const setStep = useSetRecoilState(myPageStepState)

  if (!user) return null

  const [genderText] = Object.entries(Gender).find(([, val]) => user.sex === val) || ['기타']

  return (
    <ProfileStyles>
      <ProfileWrapper>
        <ProfileImg>
          <Img src={user.imageUrl || DefaultImg} alt='프로필 이미지' border='none' borderRadius='circle' />
        </ProfileImg>
        <ProfileInfoWrapper>
          <UserInfoWrapper>
            <UserNickname onClick={() => setStep('2')}>
              {user.nickname}
              <Icon icon='pencil_unfilled' color='primary_color_regular' size='small' />
            </UserNickname>
            <UserBirth>{`${user?.birthYear}년생 / ${genderText}`}</UserBirth>
          </UserInfoWrapper>
          <UserIntroduction>{user?.introduction}</UserIntroduction>
        </ProfileInfoWrapper>
      </ProfileWrapper>
    </ProfileStyles>
  )
}

export default Profile
