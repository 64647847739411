import { useInfiniteQuery } from 'react-query'
import { CategoryTemplate } from 'components/templates'
import { getPostByCategory } from 'core/apis/api.post'
import { usePagination } from 'hooks/usePagination'
import { PosepediaCard } from 'types/ContentResponse'
import posepediaBannerImg from 'assets/images/posepedia_banner.png'

function PosepediaPage() {
  const { size, sort, tagId } = usePagination()

  const { data, isLoading, fetchNextPage } = useInfiniteQuery(
    ['post', 2, size, sort, tagId],
    ({ pageParam = 1 }) =>
      getPostByCategory<PosepediaCard>({ categoryId: 2, page: pageParam as number, size, sort, tagId }),
    {
      getNextPageParam(lastPage, allPages) {
        return lastPage.last ? undefined : allPages.length + 1
      },
    },
  )

  const cards = data?.pages?.reduce((acc, currentPage) => {
    return [...acc, ...currentPage.content]
  }, [] as PosepediaCard[])

  const showMoreButton = data?.pages?.slice(-1)?.[0]?.last === false

  return (
    <CategoryTemplate
      loading={isLoading}
      bannerImg={posepediaBannerImg}
      title='체위사전'
      subTitle='세상은 넓고 체위는 많다!'
      cards={cards}
      onClickMoreButton={fetchNextPage}
      showMoreButton={showMoreButton}
      tags={[]}
    />
  )
}

export default PosepediaPage
