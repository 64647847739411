import { useInfiniteQuery, useQuery } from 'react-query'
import { CategoryTemplate } from 'components/templates'
import { getPostByCategory } from 'core/apis/api.post'
import { getTagByCategory } from 'core/apis/api.tag'
import { usePagination } from 'hooks/usePagination'
import { ReviewCard } from 'types/ContentResponse'
import reviewBannerImg from 'assets/images/review_banner.png'

function ReviewPage() {
  const { size, sort, tagId } = usePagination()

  const { data, isLoading, fetchNextPage } = useInfiniteQuery(
    ['post', 5, size, sort, tagId],
    ({ pageParam = 1 }) =>
      getPostByCategory<ReviewCard>({ categoryId: 5, page: pageParam as number, size, sort, tagId }),
    {
      getNextPageParam(lastPage, allPages) {
        return lastPage.last ? undefined : allPages.length + 1
      },
    },
  )

  const { data: tags, isLoading: isTagLoading } = useQuery(['tag', 'review'], () => getTagByCategory(5))

  const cards = data?.pages?.reduce((acc, currentPage) => {
    return [...acc, ...currentPage.content]
  }, [] as ReviewCard[])

  const showMoreButton = data?.pages?.slice(-1)?.[0]?.last === false

  return (
    <CategoryTemplate
      loading={isLoading || isTagLoading}
      bannerImg={reviewBannerImg}
      title='성층탐구'
      subTitle='왔노라, 보았노라, 써봤노라'
      cards={cards}
      showMoreButton={showMoreButton}
      onClickMoreButton={fetchNextPage}
      tags={tags ?? []}
    />
  )
}

export default ReviewPage
