import { PaginationResponse, PostCard } from 'types/ContentResponse'
import { Author } from 'types/Author'
import { PostCategoryId } from 'types/PostCategory'
import { API, AuthAPI } from './api-factory'

export const getAuthorInfo = async (id: string | number) => {
  const { data } = await API().get<Author>(`api/v1/users/authors/${id}`)
  return data
}

export interface GetPostByAuthorProps {
  id: string | number
  page?: number
  size?: number
  categoryId?: PostCategoryId
}

export const getPostByAuthor = async ({ id, ...params }: GetPostByAuthorProps) => {
  const { data } = await API().get<PaginationResponse<PostCard>>(`api/v1/users/authors/${id}/posts`, {
    params,
  })
  return data
}

export const checkSubscription = async (id: string | number) => {
  const { data } = await AuthAPI().get<boolean>(`api/v1/users/subscribe/authors/${id}`)
  return data
}

export const subscribeAuthor = (id: string | number) => {
  return AuthAPI().post(`api/v1/users/subscribe/authors/${id}`)
}

export const unsubscribeAuthor = async (id: string | number) => {
  return AuthAPI().delete(`api/v1/users/subscribe/authors/${id}`)
}
