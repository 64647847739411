import styled from 'styled-components'

export const ToggleButtonStyles = styled.div``

export const InputWrapper = styled.label`
  position: relative;
`

export const Input = styled.input`
  position: absolute;
  left: -9999px;
  top: -9999px;
`

export const Slider = styled.div<{ toggled: boolean }>`
  display: flex;
  cursor: pointer;
  width: 40px;
  height: 12px;
  border-radius: 100px;
  background-color: ${({ theme, toggled }) =>
    toggled ? theme.colors.primary_color_regular : theme.colors.gray50};
  position: relative;
  transition: background-color 0.2s;
`

export const Ball = styled.span<{ toggled: boolean }>`
  font-size: 14px;
  position: absolute;
  padding: 3px;
  top: -5px;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  transition: 0.2s;
  color: ${({ theme }) => theme.colors.gray50};
  background-color: #fff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  transform: ${({ toggled }) => (toggled ? 'translateX(20px)' : 'translateX(0px)')};
`
