import { Wrapper, Title, SubTitle } from './FormHeader.styles'

export interface FormHeaderProps {
  title: string
  subTitle?: string
}

/**
 * 회원가입/로그인 등의 입력폼의 헤더 부분 컴포넌트
 * 타이틀, 서브타이틀로 구성
 */
const FormHeader: React.FC<FormHeaderProps> = ({ title, subTitle }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
    </Wrapper>
  )
}

export default FormHeader
