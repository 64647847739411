import React from 'react'
import { Comment as CommentType } from 'types/Comment'
import {
  Wrapper,
  CommentItem,
  Child,
  ReplyArrowWrapper,
} from 'components/organisms/CommentList/CommentList.styles'
import Comment from 'components/organisms/CommentList/Comment'
import CommentInput from 'components/organisms/CommentList/CommentInput'
import { useComment } from 'hooks'
import { reply_unfilled as ReplyArrowIcon } from 'assets/icons/svg'
import CommentReportModal from './CommentReportModal'

const ReplyArrow = () => (
  <ReplyArrowWrapper>
    <ReplyArrowIcon />
  </ReplyArrowWrapper>
)

interface CommentListProps {
  comments: CommentType[]
}

const CommentList: React.FC<CommentListProps> = ({ comments }) => {
  const {
    modalOpened,
    openModal,
    closeModal,
    reportComment,
    inputValue,
    onChangeInputValue,
    addComment,
    replyTargetComment,
    replyValue,
    onChangeReplyValue,
    openReplyInput,
    addReply,
    updateTargetComment,
    updateValue,
    onChangeUpdateValue,
    startUpdateComment,
    updateComment,
  } = useComment()

  const commentCount = comments
    .flatMap((comment) => [comment.deleted, ...comment.children.map((child) => child.deleted)])
    .filter((deleted) => !deleted).length

  return (
    <Wrapper>
      <CommentReportModal visible={modalOpened} onOk={reportComment} onCancel={closeModal} />
      <CommentInput
        commentCount={commentCount}
        inputValue={inputValue}
        onChangeInputValue={onChangeInputValue}
        onClickAddButton={addComment}
      />
      {comments.length > 0 && (
        <div>
          {comments.map((comment) => (
            <CommentItem key={comment.id}>
              {updateTargetComment === comment.id ? (
                <CommentInput
                  key={`${comment.id}-input`}
                  inputValue={updateValue}
                  onChangeInputValue={onChangeUpdateValue}
                  onClickAddButton={updateComment}
                />
              ) : (
                <Comment
                  comment={comment}
                  key={comment.id}
                  onReportComment={() => openModal(comment.id)}
                  onReply={() => openReplyInput(comment.id)}
                  onUpdate={() => startUpdateComment(comment.id, comment.content)}
                />
              )}
              {comment.children?.length > 0 &&
                comment.children.map((child) => (
                  <Child>
                    <ReplyArrow />
                    {updateTargetComment === child.id ? (
                      <CommentInput
                        key={`${child.id}-input`}
                        inputValue={updateValue}
                        onChangeInputValue={onChangeUpdateValue}
                        onClickAddButton={updateComment}
                      />
                    ) : (
                      <Comment
                        comment={child}
                        key={child.id}
                        onReportComment={() => openModal(child.id)}
                        onReply={() => openReplyInput(comment.id)}
                        onUpdate={() => startUpdateComment(child.id, child.content)}
                      />
                    )}
                  </Child>
                ))}
              {replyTargetComment === comment.id && (
                <Child>
                  <ReplyArrow />
                  <CommentInput
                    inputValue={replyValue}
                    onChangeInputValue={onChangeReplyValue}
                    onClickAddButton={addReply}
                  />
                </Child>
              )}
            </CommentItem>
          ))}
        </div>
      )}
    </Wrapper>
  )
}

export default CommentList
