import { useHistory, useParams } from 'react-router-dom'
import { AuthTemplate } from 'components/templates'
import { useComment } from 'hooks'
import { CommentList } from 'components/organisms'
import styled from 'styled-components'

const CommentListWrapper = styled.div`
  background-color: #f8f8f8;
  margin: -16px -24px;
  padding: 16px 24px;
  min-height: calc(100vh - 64px);
`

const Title = styled.h1`
  ${({ theme }) => theme.typographies.boldBody}
  margin: 0;
  position: absolute;
  top: 16px;
  left: calc(50% - 15px);
`

const MobileCommentPage = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const { comments } = useComment()

  const goBack = () => {
    history.replace(`/post/${id}`)
  }

  return (
    <AuthTemplate onClickBack={goBack}>
      <Title>댓글</Title>
      <CommentListWrapper>
        <CommentList comments={comments} />
      </CommentListWrapper>
    </AuthTemplate>
  )
}

export default MobileCommentPage
