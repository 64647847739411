import React from 'react'
import { Typographies, FontColors } from 'assets/styles/types'
import { CheckboxStyles, CustomCheckboxStyles, Label } from './Checkbox.styles'

export interface CheckboxPropsTypes {
  /** 체크박스 고유번호 */
  id?: number

  /** 체크박스 텍스트 */
  value?: string

  /** 체크박스 텍스트 스타일 */
  typography?: Typographies

  /** 체크박스 텍스트 색상 */
  color?: FontColors

  /** 체크박스 클릭 동작 */
  onChecked?: (event: React.ChangeEvent<HTMLInputElement>, id: number) => void

  checked?: boolean
}

/** `Checkbox` 컴포넌트의 문서입니다.  */

function Checkbox({
  id = 0,
  value,
  onChecked = () => {},
  typography = 'smallBody',
  color,
  checked,
}: CheckboxPropsTypes) {
  return (
    <Label htmlFor={`checkbox_${id}`} typography={typography} color={color}>
      <CheckboxStyles id={`checkbox_${id}`} checked={checked} onChange={(event) => onChecked(event, id)} />
      <CustomCheckboxStyles />
      <svg
        className='checkmark'
        width='10'
        height='7'
        viewBox='0 0 10 7'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.48888 0.215676C9.77645 0.503244 9.77645 0.969484 9.48888 1.25705L4.33434 6.4116C4.06226 6.68368 3.62657 6.70041 3.33443 6.45L0.757158 4.24091C0.448382 3.97624 0.412623 3.51138 0.677288 3.2026C0.941953 2.89382 1.40682 2.85806 1.7156 3.12273L3.77513 4.88805L8.44751 0.215676C8.73507 -0.071892 9.20131 -0.071892 9.48888 0.215676Z'
          fill='white'
        />
      </svg>
      {value}
    </Label>
  )
}

export default Checkbox
