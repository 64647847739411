import { DefaultTheme, css } from 'styled-components'

const screens = {
  basicWidth: '320px',
} as const

const typographies = {
  h1: css`
    font-size: 55px;
    line-height: 80px;
    font-weight: 700;
    letter-spacing: -2%;
  `,

  h2: css`
    font-size: 44px;
    line-height: 64px;
    font-weight: 400;
    letter-spacing: -1.5%;
  `,

  h3: css`
    font-size: 40px;
    line-height: 60px;
    font-weight: 700;
    letter-spacing: -2%;
  `,

  h4: css`
    font-size: 36px;
    line-height: 52px;
    font-weight: 300;
    letter-spacing: -1%;
  `,

  subtitle: css`
    font-size: 30px;
    line-height: 42px;
    font-weight: 500;
    letter-spacing: -1.5%;
  `,

  subtitle1_1: css`
    font-size: 28px;
    line-height: 41px;
    font-weight: 300;
    letter-spacing: -1.5%;
  `,

  subtitle2: css`
    font-size: 24px;
    line-height: 34px;
    font-weight: 500;
    letter-spacing: -1.25%;
  `,

  subtitle2_1: css`
    font-size: 24px;
    line-height: 34px;
    font-weight: 300;
    letter-spacing: -1.25%;
  `,

  subtitle3: css`
    font-size: 20px;
    line-height: 29px;
    font-weight: 400;
    letter-spacing: -1%;
  `,

  subtitle4: css`
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    letter-spacing: -1.5%;
  `,

  subtitleMobile: css`
    font-size: 12.5px;
    line-height: initial;
    font-weight: 400;
    letter-spacing: -4%;
  `,

  largeSubtitleMobile: css`
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    letter-spacing: -4%;
  `,

  largeBody: css`
    font-size: 18px;
    font-weight: 500;
    line-height: 29.5px;
    letter-spacing: -1.25%;
  `,

  largeRegularBody: css`
    font-size: 18px;
    font-weight: 400;
    line-height: 29.5px;
    letter-spacing: -1.25%;
  `,

  boldBody: css`
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -1.25%;
  `,

  body: css`
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    letter-spacing: -1.25%;
  `,

  smallBody: css`
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: -1%;
  `,

  smallBody2: css`
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  `, // 확정

  overline1: css`
    font-size: 10px;
    font-weight: 300;
    line-height: 14.5px;
  `,

  overline2: css`
    font-size: 10px;
    font-weight: 500;
    line-height: 14.5px;
  `,

  button: css`
    font-size: 24px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: -1.25%;
  `,

  smallButton: css`
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: -1.25%;
  `,

  smallButton_1: css`
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: -1.25%;
  `,
} as const

const colors = {
  primary_color_light: '#9FAFFF',
  primary_color_regular: '#6780FF',
  primary_color_dark: '#2254CB',

  secondary_color_light: '#FFDDFF',
  secondary_color_regular: '#FFAAF8',
  secondary_color_dark: '#CB79C5',

  action_color_light: '#FFebe5',
  action_color_regular: '#FF7042',
  action_color_dark: '#FF4D14',

  success_color_light: '#F0FAEE',
  success_color_regular: '#6AD255',
  success_color_dark: '#20C000',

  warning_color_light: '#FFF7E4',
  warning_color_regular: '#FFC848',
  warning_color_dark: '#FFB200',

  error_color_light: '#FFC1C1',
  error_color_regular: '#D7260D',
  error_color_dark: '#AD1500',

  red: '#EB5757',
  white: '#FFFFFF',
  gray05: '#F5F5F5',
  gray10: '#EBEAEA',
  gray20: '#CBCBCB',
  gray30: '#C1C1C1',
  gray50: '#999797',
  gray70: '#707070',
  gray80: '#555555',
  black: '#2F3233',
} as const

const media = {
  mobile: `(max-width: 750px)`,
} as const

const theme: DefaultTheme = {
  screens,
  typographies,
  colors,
  media,
} as const

export { screens, typographies, colors, media, theme }
