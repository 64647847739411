import styled from 'styled-components'
import { lineClamp } from 'assets/styles/lineClamp'

export const MemberCardWrapper = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 100 / 114.7;
  background-color: ${({ theme }) => theme.colors.gray50};
  mix-blend-mode: luminosity;
  border-radius: 16px;
  padding: 24px;
  padding: clamp(16px, 7.5%, 24px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &:hover {
    mix-blend-mode: normal;
    background: ${({ theme: { colors } }) => `
      linear-gradient(to top, ${colors.black}, 2%, ${colors.primary_color_regular});
      `};
  }

  ${({ theme: { media, colors } }) => `
    @media ${media.mobile} {
      mix-blend-mode: normal;
      background: ${colors.primary_color_regular};
      justify-content: center;

      &:hover {
        background: ${colors.primary_color_regular};
      }
    }
  `}
`

export const ImgWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;

  ${({ theme }) => `
    @media ${theme.media.mobile} {
      margin-top: 7.35%;

      ${MemberCardWrapper}:hover & {
        display: none;
      }
    }
  `}
`

export const TextContents = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  z-index: 1;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      opacity: 0;

      ${MemberCardWrapper}:hover & {
        opacity: 1;
      }
    }
  `}
`

export const TextWrapper = styled.div`
  z-index: 1;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      flex-wrap: wrap;
    }
  `}
`

export const MemberName = styled.p`
  ${({ theme }) => theme.typographies.subtitle2}
  color: ${({ theme }) => theme.colors.black};
  flex-shrink: 0;
  margin-right: 8px;

  ${MemberCardWrapper}:hover & {
    color: ${({ theme }) => theme.colors.white};
  }

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      width: 100%;
      margin-right: 0;
      margin-bottom: 4px;
    }
  `}
`

export const MemberRole = styled.span`
  ${({ theme }) => theme.typographies.smallBody}
  color: ${({ theme }) => theme.colors.black};
  ${{ ...lineClamp(1) }}
  flex-shrink: 1;

  &:not(:first-of-type)::before {
    content: ' · ';
  }

  ${MemberCardWrapper}:hover & {
    color: ${({ theme }) => theme.colors.white};
  }

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.smallBody2}
    }
  `}
`

export const Description = styled.p`
  ${({ theme }) => theme.typographies.smallBody}
  color: ${({ theme }) => theme.colors.black};
  display: none;

  ${MemberCardWrapper}:hover & {
    color: ${({ theme }) => theme.colors.white};
    display: block;
  }

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.overline2}
    }
  `}
`

export const IconWrapper = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
  display: none;

  ${MemberCardWrapper}:hover & {
    display: flex;
  }

  ${({ theme }) => `
    @media ${theme.media.mobile} {
      padding-top: 4px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  `}
`
