import { ChangeEventHandler } from 'react'
import { ToggleButtonStyles, Ball, Input, InputWrapper, Slider } from './ToggleButton.styles'

export interface ToggleButtonProps {
  text: string
  toggled: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
}

function ToggleButton({ text, toggled, onChange }: ToggleButtonProps) {
  return (
    <ToggleButtonStyles>
      <InputWrapper>
        <Input type='checkbox' onChange={onChange} />
        <Slider toggled={toggled}>
          <Ball toggled={toggled}>{text}</Ball>
        </Slider>
      </InputWrapper>
    </ToggleButtonStyles>
  )
}

export default ToggleButton
