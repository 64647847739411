import styled from 'styled-components'

export const CardBannerStyles = styled.section`
  width: 100%;
  margin: 0 auto;
`

export const CardBannerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`

export const CardBannerContents = styled.ul`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 0 20px;
  overflow: hidden;

  & > * {
    flex: 0 0 calc((100% - 40px) / 3);
  }

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      gap: 0 16px;
      & > * {
        flex: 0 0 calc((100% - 16px) / 2);
      }
    }
  `}
`
