import styled, { css } from 'styled-components'

export type BorderTypes = 'outline' | 'underline'

const borderStyles = {
  outline: css`
    border: 1px solid ${({ theme }) => theme.colors.gray50};
    border-radius: 4px;
  `,

  underline: css`
    background: transparent;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray70};
  `,
} as const

export const InputStyles = styled.input<{ border: BorderTypes; error?: boolean; success?: boolean }>`
  width: 100%;
  height: 48px;
  padding: ${({ border }) => (border === 'underline' ? '10px 0' : '10px 24px')};

  ${({ border }) => border && borderStyles[border]};
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary_color_regular};
  }

  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) => theme.colors.error_color_regular};
      border-color: ${({ theme }) => theme.colors.error_color_regular};
      &:focus {
        border-color: ${({ theme }) => theme.colors.error_color_regular};
      }
    `}

  ${({ success }) =>
    success &&
    css`
      border-color: ${({ theme }) => theme.colors.success_color_regular};
    `}

    :disabled {
    background: ${({ theme }) => theme.colors.gray10};
    border: none;
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${({ theme }) => theme.colors.gray20};
  }
`
