interface Storage {
  get: (key: string) => string | null
  set: (key: string, val: string) => void
  remove: (key: string) => void
}

export const storage: { local: Storage; session: Storage } = {
  local: {
    get(key) {
      return localStorage.getItem(key)
    },
    set(key, val) {
      return localStorage.setItem(key, val)
    },
    remove(key) {
      return localStorage.removeItem(key)
    },
  },
  session: {
    get(key) {
      const [, value] = document.cookie
        .split(';')
        .map((s) => s.trim())
        .map((s) => s.split('='))
        .find(([k]) => k === key) ?? [null, null]

      return value || null
    },
    set(key, val) {
      document.cookie = `${key}=${val}`
    },
    remove(key) {
      document.cookie = `${key}=; Max-Age=0`
    },
  },
} as const
