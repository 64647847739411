import styled from 'styled-components'

export const CardListStyles = styled.section`
  width: 100%;
  margin-bottom: 172px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      margin-bottom: 52px;
    }
  `}
`

export const IconBtnGroup = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 42px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      margin-bottom: 24px;
    }
  `}
`

export const IconBtn = styled.button<{ active?: boolean }>`
  cursor: pointer;
  width: 103px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid
    ${({ theme: { colors }, active }) => (active ? colors.primary_color_regular : colors.gray50)};
  color: ${({ theme: { colors }, active }) => (active ? colors.primary_color_regular : colors.gray50)};
  ${({ theme }) => theme.typographies.subtitle4}
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: ${({ theme: { colors }, active }) => (active ? colors.primary_color_regular : colors.gray50)};
    }
  }

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      width: 70px;
      height: 24px;
      ${typographies.smallBody2}
      column-gap: 4px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  `}
`

export const ChipsGroupBar = styled.nav`
  display: flex;
  align-items: center;
  column-gap: 32px;
  margin-bottom: 50px;
`

export const ChipsGroup = styled.div`
  display: flex;
  column-gap: 8px;
`

export const CardListContents = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 24px 20px;

  & > * {
    flex: 0 1 calc((100% - 40px) / 3);
  }

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      & > * {
        flex: 0 1 calc((100% - 20px) / 2);
      }
    }
  `}
`

export const MoreButtonWrapper = styled.div`
  text-align: center;
  padding-top: 60px;
`
