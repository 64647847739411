import { FontColors, Typographies } from 'assets/styles/types'
import styled from 'styled-components'

export const CheckboxStyles = styled.input.attrs({
  type: 'checkbox',
})`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`

export const CustomCheckboxStyles = styled.span`
  position: absolute;
  top: 4px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: transparent;
  border-radius: 30px;
  border: 1px solid #ddd;
`

export const Label = styled.label<{ typography: Typographies; color?: FontColors }>`
  ${({ typography, theme }) => typography && theme.typographies[typography]}
  color: ${({ color, theme }) => color && theme.colors[color]};
  cursor: pointer;
  padding-left: 27px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & .checkmark {
    position: absolute;
    top: 9px;
    left: 3px;
    width: 9.2px;
    height: 6.63px;
  }

  &:hover > input {
    & ~ span {
      background-color: #eee;
    }

    & ~ .checkmark {
      display: none;
    }
  }

  & > input:checked {
    & ~ span {
      background-color: ${({ theme }) => theme.colors.primary_color_regular};
      border: 0;
    }

    & ~ .checkmark {
      display: block;
    }
  }
`
