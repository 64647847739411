import { getBannerList } from 'core/apis/api.banner'
import {
  getPostByCategory,
  getTop3PopularPosts,
  getTop3RecentPosts,
  getRecommendedPosts,
} from 'core/apis/api.post'
import { useQuery } from 'react-query'
import { SexpediaCard, PosepediaCard, ReviewCard } from 'types/ContentResponse'
import { PostCategory } from 'types/PostCategory'

export const useMainContents = () => {
  const { data: recentCards, isLoading: recentLoading } = useQuery(['post', 'recent'], () =>
    getTop3RecentPosts(),
  )
  const { data: popularCards, isLoading: popularLoading } = useQuery(['post', 'popular'], () =>
    getTop3PopularPosts(),
  )
  const { data: sexpediaResponse, isLoading: sexpediaLoading } = useQuery(
    ['post', 'main', PostCategory.Sexpedia.ID],
    () => getPostByCategory<SexpediaCard>({ categoryId: PostCategory.Sexpedia.ID, size: 3 }),
  )
  const { data: posepediaResponse, isLoading: posepediaLoading } = useQuery(
    ['post', 'main', PostCategory.Posepedia.ID],
    () => getPostByCategory<PosepediaCard>({ categoryId: PostCategory.Posepedia.ID, size: 3 }),
  )
  const { data: reviewResponse, isLoading: reviewLoading } = useQuery(
    ['post', 'main', PostCategory.Review.ID],
    () => getPostByCategory<ReviewCard>({ categoryId: PostCategory.Review.ID, size: 3 }),
  )
  const { data: recommendedCards, isLoading: recommendedLoading } = useQuery(['post', 'recommended'], () =>
    getRecommendedPosts(),
  )

  const { data: banners, isLoading: bannerLoading } = useQuery(['banner', 'main_bannerList'], getBannerList)

  const isLoading =
    sexpediaLoading ||
    posepediaLoading ||
    reviewLoading ||
    bannerLoading ||
    recentLoading ||
    popularLoading ||
    recommendedLoading

  return {
    isLoading,
    recentCards,
    popularCards,
    recommendedCards,
    sexpediaCards: sexpediaResponse?.content,
    posepediaCards: posepediaResponse?.content,
    reviewCards: reviewResponse?.content,
    banners,
  }
}
