import styled from 'styled-components'

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  header {
    margin-bottom: 28px;
  }
`

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 40px;
`

export const ListField = styled.ul`
  ${({ theme: { typographies } }) => typographies.smallBody};
  color: ${({ theme: { colors } }) => colors.gray50};
  padding-left: 20px;
  word-break: keep-all;
`

export const ListFieldItem = styled.li`
  list-style: disc;
`

export const SubmitButtonWrapper = styled.div`
  height: 50px;

  > button {
    height: 100%;
  }

  ${({ theme: { media } }) => `
    &:last-child {
      @media ${media.mobile} {
        position: fixed;
        left: 24px;
        right: 24px;
        bottom: 52px;
      }
    }
  `}
`
