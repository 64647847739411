import { useState, ChangeEventHandler } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import {
  getComments,
  getBestComments,
  createComment,
  deleteComment,
  reportComment,
  updateComment,
  likeComment,
  unlikeComment,
  createReply,
} from 'core/apis/api.comment'
import { Comment } from 'types/Comment'
import { useInput } from './useInput'

export const useComment = () => {
  const { id } = useParams<{ id?: string }>()

  if (!id) throw new Error('Post id is not found')

  const [inputValue, onChangeInputValue, setInputValue] = useInput()
  const [modalOpened, setModalOpened] = useState(false)
  const [reportTarget, setReportTarget] = useState<number | null>(null)

  const [updateValue, onChangeUpdateValue, setUpdateValue] = useInput()
  const [updateTargetComment, setUpdateTargetComment] = useState<null | number>(null)

  const [replyValue, onChangeReplyValue, setReplyValue] = useInput()
  const [replyTargetComment, setReplyTargetComment] = useState<null | number>(null)

  const commentQuery = useQuery(['comments', id], () => getComments(id))
  const bestCommentQuery = useQuery(['comments', 'best', id], () => getBestComments(id))

  const mapBestComment = (comment: Comment): Comment => ({
    ...comment,
    isBest: !!(bestCommentQuery.data ?? []).find((bestComment) => bestComment.id === comment.id),
  })

  const isCommentLoading = commentQuery.isLoading || bestCommentQuery.isLoading
  const comments = (commentQuery.data ?? []).map(mapBestComment).sort((a, b) => {
    if (a.isBest && !b.isBest) return -1
    if (!a.isBest && b.isBest) return 1
    return new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()
  })

  const refetch = () => {
    bestCommentQuery.refetch()
    commentQuery.refetch()
  }

  const openModal = (targetCommentId: number) => {
    setReportTarget(targetCommentId)
    setModalOpened(true)
  }

  const closeModal = () => {
    setReportTarget(null)
    setModalOpened(false)
  }

  const addComment = () => {
    if (!inputValue.length) return
    createComment(id, inputValue).finally(() => {
      refetch()
    })
    setInputValue('')
  }

  const onReportComment = (reason: string) => {
    setModalOpened(false)
    if (!reportTarget) return
    reportComment(reportTarget, reason).then(() => {
      window.alert('신고되었습니다')
    })
  }

  const onDeleteComment = (commentId: number) => {
    if (!window.confirm('삭제하시겠습니까?')) return
    deleteComment(commentId).finally(() => {
      refetch()
    })
  }

  const startUpdateComment = (commentId: number, content: string) => {
    setUpdateTargetComment(commentId)
    setUpdateValue(content)
  }

  const onUpdateComment = () => {
    if (updateValue && updateTargetComment) {
      updateComment(updateTargetComment, updateValue).finally(() => {
        refetch()
      })
    }
    setUpdateTargetComment(null)
  }

  const toggleLike = (commentId: number) => {
    const targetComment = comments
      .flatMap((comment) => [comment, ...comment.children])
      .find((currentComment) => currentComment.id === commentId)

    if (!targetComment) return

    const promise = (targetComment.liked ? unlikeComment : likeComment)(commentId)

    promise.finally(() => {
      refetch()
    })
  }

  const openReplyInput = (targetCommentId: number) => {
    setReplyTargetComment(targetCommentId)
    setReplyValue('')
  }

  const addReply = () => {
    if (!replyTargetComment) return
    createReply(replyTargetComment, replyValue).finally(() => {
      refetch()
      setReplyTargetComment(null)
    })
  }

  return {
    // 댓글 CRUD API
    isCommentLoading,
    comments,
    refetch,
    addComment,
    deleteComment: onDeleteComment,
    updateTargetComment,
    updateValue,
    onChangeUpdateValue: onChangeUpdateValue as unknown as ChangeEventHandler<HTMLTextAreaElement>,
    startUpdateComment,
    updateComment: onUpdateComment,

    // 댓글 신고, 좋아요 API
    reportComment: onReportComment,
    toggleLike,

    // 댓글 추가 관련 API
    inputValue,
    onChangeInputValue: onChangeInputValue as unknown as ChangeEventHandler<HTMLTextAreaElement>,
    setInputValue,

    // 댓글 신고 모달 관련 API
    modalOpened,
    openModal,
    closeModal,

    // 답글 관련 API
    replyTargetComment,
    replyValue,
    onChangeReplyValue: onChangeReplyValue as unknown as ChangeEventHandler<HTMLTextAreaElement>,
    openReplyInput,
    addReply,
  }
}
