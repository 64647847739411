import styled from 'styled-components'

export const BrandStory = styled.section`
  width: 100%;
`

export const BrandStoryBanner = styled.div`
  background-color: ${({ theme }) => theme.colors.primary_color_regular};
  height: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme }) => `
    @media ${theme.media.mobile} {
      height: 369px;
    }
  `}
`

export const BrandStoryTitle = styled.q`
  ${({ theme }) => theme.typographies.h3};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  margin-bottom: 60px;
  display: block;
  white-space: pre-wrap;

  &::before,
  &::after {
    display: block;
  }

  ${({ theme }) => `
    @media ${theme.media.mobile} {
      ${theme.typographies.subtitle}
      margin-bottom: 34px;
    }
  `}
`

export const BrandStoryDescription = styled.p`
  ${({ theme }) => theme.typographies.largeRegularBody};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  padding: 0 32px;
  word-break: keep-all;
`

export const BrandContents = styled.div`
  max-width: 533px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 30px;
  margin: 56px auto;
`

export const BrandStoryText = styled.p`
  ${({ theme: { typographies, media } }) => `
    word-break: keep-all;
    ${typographies.largeRegularBody}

    @media ${media.mobile} {
      ${typographies.body}
      padding: 0 24px;
    }
  `}
`
