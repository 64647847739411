import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const SignUpToastStyles = styled.div`
  position: fixed;
  width: 100%;
  left: 50%;
  top: 600px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      top: auto;
      bottom: 180px;
    }
  `}
`

export const ToastInner = styled.div<{ isToastForFadeOut: boolean }>`
  position: absolute;
  transform: translateX(-50%);
  padding: 13px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.gray80};
  border-radius: 8px;
  animation: ${fadeIn} 0.3s ease-out;
  ${({ theme }) => theme.typographies.smallBody};
  opacity: ${({ isToastForFadeOut }) => !isToastForFadeOut && '0'};
  transition: opacity 0.3s;
`
