import axios from 'axios'
import { PaginationResponse, PostCard } from 'types/ContentResponse'
import { Post } from 'types/Post'
import { AuthAPI } from './api-factory'

export interface GetPostByCategoryProps {
  categoryId: number
  page?: number
  size?: number
  sort?: 'recent' | 'popularity'
  tagId?: number
}

export const getPostByCategory = async <CardType extends PostCard>(params: GetPostByCategoryProps) => {
  const { data } = await axios.get<PaginationResponse<CardType>>('api/v1/posts', { params })
  return data
}

export const getTop3RecentPosts = async () => {
  const { data } = await axios.get<PostCard[]>('api/v1/posts/top3-recent')
  return data
}

export const getTop3PopularPosts = async () => {
  const { data } = await axios.get<PostCard[]>('api/v1/posts/top3-popular')
  return data
}

export const getPostById = async (postId: string | number) => {
  const { data } = await axios.get<Post>(`api/v1/posts/${postId}`)
  return data
}

export const getRecommendedPosts = async () => {
  const { data } = await axios.get<PostCard[]>(`api/v1/posts/recommended`)
  return data
}

export const getLikesToPosts = async (postId: string) => {
  const { data } = await AuthAPI().get<{ likeYn: boolean }>(`api/v1/posts/${postId}/likes`)
  return data
}

export const addLikesToPosts = (postId: number) => {
  return AuthAPI().post(`api/v1/posts/${postId}/likes`)
}

export const deleteLikesToPosts = (postId: number) => {
  return AuthAPI().delete(`api/v1/posts/${postId}/likes`)
}

export const getScrapsToPosts = async (postId: string) => {
  const { data } = await AuthAPI().get<{ scrapYn: boolean }>(`api/v1/posts/${postId}/scraps`)
  return data
}

export const addScrapToPosts = (postId: number) => {
  return AuthAPI().post(`api/v1/posts/${postId}/scraps`)
}

export const deleteScrapToPosts = (postId: number) => {
  return AuthAPI().delete(`api/v1/posts/${postId}/scraps`)
}

export const searchPost = async (keyword: string) => {
  const { data, status } = await axios.get<PaginationResponse<PostCard>>('api/v1/posts/search', {
    params: { keyword },
  })

  if (status === 204) return { content: [] }

  return data
}
