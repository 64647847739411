import styled from 'styled-components'

export const UnregisterTemplateStyles = styled.form`
  max-width: 820px;
  margin: 56px auto 166px;
`

export const Title = styled.h1`
  margin: 0 0 15px;
  ${({ theme: { typographies } }) => typographies.h3};
`

export const SubTitle = styled.h2`
  margin-bottom: 46px;
  ${({ theme: { typographies } }) => typographies.subtitle2};
`

export const NotificationText = styled.div`
  margin-bottom: 14px;
  ${({ theme: { typographies } }) => typographies.subtitle4};
`

export const ProcessingDetailsContainer = styled.div`
  padding: 32px;
  margin-bottom: 9px;
  border: 0.8px solid ${({ theme: { colors } }) => colors.gray50};
`

export const ProcessingDetailsTitle = styled.div`
  margin-bottom: 16px;
  ${({ theme: { typographies } }) => typographies.subtitle4};
`

export const ProcessingDetailsListContainer = styled.ul`
  > li {
    margin-bottom: 15px;
    ${({ theme: { typographies } }) => typographies.largeRegularBody};

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const UnregisterQuestion = styled.div`
  margin: 55px 0 22px;
`

export const UnregisterQuestionListContainer = styled.ul`
  margin-bottom: 70px;

  > li {
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const UnregisterBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0 20px;
`

export const DETAIL_LIST = [
  ' ・탈퇴 후, 회원 및 구독 정보 등의 데이터가 삭제되며, 복구할 수 없습니다.',
  ' ・작성한 모든 댓글은 탈퇴 이후 수정하거나 삭제할 수 없습니다.',
  ' ・같은 이메일로 재가입 시 이전 계정과 연동되지 않습니다.',
  ' ・자세한 내용은 개인정보처리방침을 확인해주세요.',
]

export const QUESTION_LIST = [
  '서비스 이용 빈도가 낮아요.',
  '다른 이메일로 재가입 예정이에요.',
  '개인정보 노출이 걱정되어요.',
  '기타',
]
