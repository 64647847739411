import { useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { myPageStepState, profileState } from 'recoil/atoms'
import ImageUploading, { ImageListType } from 'react-images-uploading'
import { Button, Icon, Img, Text } from 'components/atoms'
import DefaultImg from 'assets/images/default_profile.png'
import { profileImgUpload } from 'core/apis/api.auth'
import {
  EditBtnWrapper,
  ProfileImg,
  PhotoEditorStyles,
  ProfileImgWrapper,
  SelectedImgItem,
  SelectedImgList,
  AddImageBtn,
  Title,
} from './PhotoEditor.styles'

export interface PhotoEditorPropTypes {
  thumbnailImgList: string[]
}

function PhotoEditor({ thumbnailImgList }: PhotoEditorPropTypes) {
  const setStep = useSetRecoilState(myPageStepState)
  const [profileInfo, setProfileInfo] = useRecoilState(profileState)
  const [files, setFiles] = useState<ImageListType>([{ dataURL: profileInfo?.imageUrl }])

  const handleChangeImg = (url: string) => {
    if (!profileInfo) return
    setFiles([{ dataURL: url }])
    setProfileInfo({ ...profileInfo, imageUrl: url })
  }

  const handleChangeFile = (imageList: ImageListType) => {
    if (!profileInfo || !imageList.length) return

    const { file, dataURL } = imageList[0]

    if (!file || !dataURL) return

    profileImgUpload(file).then(handleChangeImg)
    setProfileInfo({ ...profileInfo, imageUrl: dataURL })
  }

  const handleEditProfileImg = () => {
    // onAddProfileImgEvent();
    setStep('2')
  }

  return (
    <PhotoEditorStyles>
      <Title onClick={() => setStep('2')}>
        <Icon icon='moving_left_unfilled' color='primary_color_regular' size='middle' />
        <Text as='h5' typography='subtitle2' color='primary_color_regular'>
          프로필 사진 설정
        </Text>
      </Title>

      <ProfileImgWrapper>
        <Text as='label' typography='subtitle4' color='gray80' className='label'>
          프로필 사진
        </Text>
        <ImageUploading value={files} onChange={handleChangeFile} multiple={false}>
          {({ onImageUpload, isDragging, dragProps }) => (
            <ProfileImg onClick={onImageUpload} dragging={isDragging} {...dragProps}>
              <Img
                src={`${files[0]?.dataURL || DefaultImg}`}
                alt='프로필 이미지'
                border='none'
                borderRadius='big'
              />
              <AddImageBtn>
                <Icon icon='plus_circle_filled' color='white' size='larger' />
              </AddImageBtn>
            </ProfileImg>
          )}
        </ImageUploading>
      </ProfileImgWrapper>
      <Text as='label' typography='subtitle4' color='gray80'>
        추천 이미지
      </Text>
      <SelectedImgList>
        <SelectedImgItem
          onClick={() => handleChangeImg(DefaultImg)}
          style={{ border: `3px solid ${profileInfo?.imageUrl === DefaultImg ? '#6780ff' : '#c1c1c1'}` }}
        >
          <div className='close' />
        </SelectedImgItem>
        {thumbnailImgList?.map((url) => {
          return (
            <SelectedImgItem
              key={url}
              onClick={() => handleChangeImg(url)}
              active={profileInfo?.imageUrl === url}
            >
              <Img src={url} alt='' border='none' borderRadius='none' />
            </SelectedImgItem>
          )
        })}
      </SelectedImgList>
      <EditBtnWrapper>
        <Button variant='fill' size='middle' onClick={handleEditProfileImg} typography='smallButton'>
          사진 수정하기
        </Button>
      </EditBtnWrapper>
    </PhotoEditorStyles>
  )
}

export default PhotoEditor
