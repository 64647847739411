import styled from 'styled-components'

export const Header = styled.h1`
  display: block;
  width: 100%;
  padding: 18px 24px;
  ${({ theme: { typographies } }) => typographies.subtitle}
  margin: 0;

  ${({ theme: { typographies, media, colors } }) => `
    @media ${media.mobile} {
      ${typographies.largeBody}
      padding: 6px 0;
      text-align: center;
      border-top: .3px solid ${colors.gray70};
      border-bottom: .3px solid ${colors.gray70};
    }
  `}
`

export const HeaderInner = styled.span`
  display: block;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
`
