import axios, { AxiosError, AxiosInstance } from 'axios'
import { BASE_URL } from 'core/apis'
import { StatusCode } from 'core/apis/status-code'
import { tokenManager } from 'hooks'

axios.defaults.baseURL = BASE_URL
axios.defaults.withCredentials = true

const createInstance = () => axios.create({ baseURL: BASE_URL })

const useRequestInterceptor = (instance: AxiosInstance) => {
  // api 요청 전에 유저 인증 헤더 추가해주는 인터셉터
  instance.interceptors.request.use((request) => {
    const token = tokenManager.getToken()

    if (token) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      request.headers.common.Authorization = `${token.tokenType} ${token.accessToken}`
    }

    return request
  })

  return instance
}

const useResponseInterceptor = (instance: AxiosInstance) => {
  // 요청 응답으로 401상태코드가 오는 경우 로그인페이지로 보내는 인터셉터
  instance.interceptors.response.use(
    (response) => response,
    (err) => {
      if ((err as AxiosError)?.response?.status === StatusCode.UNAUTHORIZED) {
        tokenManager.clearToken()
        window.location.href = '/login'
        return null
      }
      return Promise.reject(err)
    },
  )

  return instance
}

export const API = () => {
  return useResponseInterceptor(useRequestInterceptor(createInstance()))
}

export const AuthAPI = () => {
  if (!tokenManager.getToken() || tokenManager.isExpired()) {
    window.location.href = '/login'
  }

  return useResponseInterceptor(useRequestInterceptor(createInstance()))
}
