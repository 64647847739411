import { AxiosResponse } from 'axios'
import { Provider, User, Gender } from 'types/User'
import { SignUpInfo } from 'types/auth.type'
import { PaginationResponse, PostCard } from 'types/ContentResponse'
import { tokenManager } from 'hooks'
import { API, AuthAPI } from './api-factory'

export interface LoginResponse {
  accessToken: string
  tokenType: string
}

export const loginWithEmail = async (email: string, password: string) => {
  const { data } = await API().post<LoginResponse>(`api/v1/auth/login`, { email, password })

  return data
}

export const getKakaoAccessToken = async (authToken: string, redirectUri: string) => {
  const { data } = await API().post<{ kakaoAccessToken: string }>(`api/v1/auth/kakao/token`, {
    code: authToken,
    redirectUri,
  })

  return data
}

interface SocialLoginResponse {
  accessToken: string
  tokenType: string
}

export const loginWithKakao = async (accessToken: string) => {
  const { data } = await API().post<SocialLoginResponse>(`api/v1/auth/social/login`, {
    provider: 'KAKAO',
    accessToken,
  })
  return data
}

export const loginWithFacebookRequest = async (accessToken: string) => {
  const { data } = await API().post<SocialLoginResponse>(`api/v1/auth/social/login`, {
    provider: 'FACEBOOK',
    accessToken,
  })
  return data
}

export const getSendEmailRequest = (mailTo: string) => {
  return API().get(`api/v1/mails/signup`, {
    params: { mailTo },
  })
}

export const getCertificationNumberCheck = async (code: string) => {
  const { data } = await API().get<{ success: boolean }>(`api/v1/mails/check`, {
    params: { code },
  })

  return data
}

export const postSignUpRequest = async (signUpInfo: SignUpInfo) => {
  const { data } = await API().post<SignUpInfo, AxiosResponse<{ success: boolean }>>(
    `api/v1/auth/signup`,
    signUpInfo,
  )

  return data
}

/**
 * 소셜로그인 과정에서 회원가입 유무 체크
 */
export const checkSignup = async ({ provider, accessToken }: { provider: Provider; accessToken: string }) => {
  const { data } = await API().post<boolean>(`api/v1/auth/social/signup-check`, { provider, accessToken })
  return data
}

export const putFindPasswordSendEmail = (mailTo: string) => {
  return API().put(`api/v1/mails/password`, null, { params: { mailTo } })
}

export const getUserInfo = async () => {
  if (tokenManager.isExpired()) return null

  const { data } = await AuthAPI().get<User>(`api/v1/users/me`)
  return data
}

export interface GetSocialUserInfoProps {
  accessToken: string
  provider: User['provider']
}

export const getSocialUserInfo = async ({ accessToken, provider }: GetSocialUserInfoProps) => {
  const { data } = await API().post<GetSocialUserInfoProps, AxiosResponse<User>>(`api/v1/auth/social/user`, {
    accessToken,
    provider,
  })
  return data
}

export const userPasswordEdit = (password: string) => {
  return AuthAPI().put(`api/v1/users/password`, { password })
}

export const getUserScrapList = async (categoryId?: string) => {
  const { data } = await AuthAPI().get<PaginationResponse<PostCard>>(`api/v1/users/scraps`, {
    params: { categoryId },
  })
  return data
}

export const profileImgUpload = async (file: File) => {
  const formData = new FormData()
  formData.append('multipartFile', file)

  const { data } = await AuthAPI().post<any, AxiosResponse<string>>(`api/v1/files`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })

  return data
}

export const profileEditRequest = async (
  birthYear: number,
  imageUrl: string,
  introduction: string,
  nickname: string,
  sex: typeof Gender[keyof typeof Gender],
) => {
  const { data } = await AuthAPI().put<User>(`api/v1/users`, {
    birthYear,
    imageUrl,
    introduction,
    nickname,
    sex,
  })

  return data
}

export const getProfileSampleImgList = async () => {
  const { data } = await AuthAPI().get<string[]>(`api/v1/users/profiles/recommendedimages`)
  return data
}

export const unregisterUser = (reason: string) => {
  return AuthAPI().post('api/v1/users/unregister', { isPolicyAgreed: true, reason })
}
