import { useState } from 'react'
import { Text, CheckBox, Link } from 'components/atoms'
import { useRegistrationForm, useAuthentication, useToggle } from 'hooks'
import { getUserInfo, loginWithEmail } from 'core/apis/api.auth'
import { LoginFormSchema } from 'core/utils/validation'
import { useHistory } from 'react-router-dom'
import { TextField } from 'components/molecules'
import { RouteWrapper, SignUpRoute, ErrorMessage } from './LoginForm.styles'
import AuthForm from '../AuthForm'

export interface UserAuthInfo {
  email: string
  password: string
}

function LoginForm() {
  const history = useHistory()
  const { setToken, setUser, clearAuth } = useAuthentication()
  const [keepLogged, toggleKeepLogged] = useToggle(false)
  const {
    register,
    watch,
    formState: { errors, isValid, isSubmitted },
    onSubmit,
    setValue,
  } = useRegistrationForm<UserAuthInfo>(LoginFormSchema, 'all')

  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState<boolean>(true)

  const handleLoginWithEmail = () => {
    loginWithEmail(watch('email'), watch('password'))
      .then((token) => setToken({ ...token, session: !keepLogged }))
      .then(getUserInfo)
      .then(setUser)
      .then(() => {
        setIsSubmitSuccessful(true)
        history.go(-1)
      })
      .catch(() => {
        setIsSubmitSuccessful(false)
        setValue('email', '')
        setValue('password', '')
        clearAuth()
      })
  }

  return (
    <AuthForm
      onSubmit={(...args) => {
        onSubmit(handleLoginWithEmail)(...args)
      }}
      title='로그인'
      subTitle='이메일로 로그인'
      submitButtonText='로그인'
      submitButtonDisabled={!isValid}
      tail={
        <RouteWrapper>
          <Link to='find-password' typography='smallBody' underline color='black'>
            비밀번호 찾기
          </Link>
          <SignUpRoute>
            <Text as='span' typography='smallBody' color='gray70'>
              비뉴가 처음이신가요?
            </Text>
            <Link to='signUp' typography='smallBody' color='primary_color_regular' underline>
              회원가입하기
            </Link>
          </SignUpRoute>
        </RouteWrapper>
      }
    >
      <TextField
        type='email'
        placeholder='이메일'
        autoComplete='email'
        register={{ ...register('email') }}
        error={errors.email?.message}
        onInput={(e) => {
          setValue('email', (e.nativeEvent.target as HTMLInputElement)?.value)
          setIsSubmitSuccessful(true)
        }}
      />
      <TextField
        type='password'
        placeholder='비밀번호'
        autoComplete='current-password'
        register={{ ...register('password') }}
        error={errors.password?.message}
      />
      {!watch('email')?.length && !watch('password')?.length && isSubmitted && !isSubmitSuccessful && (
        <ErrorMessage>
          <Text as='span' typography='overline2' color='error_color_regular'>
            가입하지 않은 아이디이거나, 잘못된 비밀번호입니다.
          </Text>
        </ErrorMessage>
      )}
      <CheckBox id={1} value='로그인 상태 유지' onChecked={toggleKeepLogged} checked={keepLogged} />
    </AuthForm>
  )
}

export default LoginForm
