import React from 'react'

/**
 * 텍스트 줄 수 제한. 줄 수를 초과하면 말줄임표를 넣는다
 *
 * @param line 제한 줄 수
 * @returns css스타일
 */
export const lineClamp = (line = 1) =>
  ({
    lineClamp: line,
    WebkitLineClamp: line,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  } as React.CSSProperties)
