import React from 'react'
import { Select } from 'components/atoms'
import { SelectFiledStyles, Label } from './SelectField.styles'

export interface SelectFiledPropTypes {
  /** 셀렉트 필드 속성 */
  name: string

  /** 셀렉트 필드 labe에 들어갈 텍스트 */
  label?: string

  /** placeholder 문구 */
  placeholder: string

  /** 선택 항목 */
  options: string[]

  /** 유효성 검사 */
  register?: Record<string, unknown>
}

/** `SelectFiled` 컴포넌트의 문서입니다.  */

function SelectField({ name, label, placeholder, options, register }: SelectFiledPropTypes) {
  return (
    <SelectFiledStyles>
      {label && <Label>{label}</Label>}
      <Select name={name} placeholder={placeholder} options={options} register={register} />
    </SelectFiledStyles>
  )
}

export default SelectField
