import { useInfiniteQuery, useQuery } from 'react-query'
import { CategoryTemplate } from 'components/templates'
import { getPostByCategory } from 'core/apis/api.post'
import { getTagByCategory } from 'core/apis/api.tag'
import { usePagination } from 'hooks/usePagination'
import { SexpediaCard } from 'types/ContentResponse'
import sexpediaBannerImg from 'assets/images/sexpedia_banner.png'

function SexpediaPage() {
  const { size, sort, tagId } = usePagination()

  const { data, isLoading, fetchNextPage } = useInfiniteQuery(
    ['post', 1, size, sort, tagId],
    ({ pageParam = 1 }) =>
      getPostByCategory<SexpediaCard>({ categoryId: 1, page: pageParam as number, size, sort, tagId }),
    {
      getNextPageParam(lastPage, allPages) {
        return lastPage.last ? undefined : allPages.length + 1
      },
    },
  )

  const { data: tags, isLoading: isTagLoading } = useQuery(['tag', 'sexpedia'], () => getTagByCategory(1))

  const cards = data?.pages?.reduce((acc, currentPage) => {
    return [...acc, ...currentPage.content]
  }, [] as SexpediaCard[])

  const showMoreButton = data?.pages?.slice(-1)?.[0]?.last === false

  return (
    <CategoryTemplate
      loading={isLoading || isTagLoading}
      bannerImg={sexpediaBannerImg}
      title='성과사전'
      subTitle='이것만 알고 가자 , 기초 성지식'
      cards={cards}
      showMoreButton={showMoreButton}
      tags={tags ?? []}
      onClickMoreButton={fetchNextPage}
    />
  )
}

export default SexpediaPage
