import styled from 'styled-components'

export const RouteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 12px;
  margin: 36px auto;
`

export const SignUpRoute = styled.div`
  display: flex;
  column-gap: 8px;
`

export const ErrorMessage = styled.div`
  margin-top: -14px;
  padding-left: 8px;
`
