import React from 'react'
import { SelectStyles } from './Select.styles'

export interface SelectPropTypes {
  /** 고유 이름 */
  name: string

  /** placeholder 문구 */
  placeholder?: string

  /** 선택 항목 리스트 */
  options?: string[]

  /** 유효성 검사 */
  register?: Record<string, unknown>
}

function Select({
  name,
  options = ['항목1', '항목2', '항목3', '항목4', '항목5'],
  register,
  placeholder,
}: SelectPropTypes) {
  return (
    <SelectStyles name={name} {...register} aria-label={placeholder} defaultValue=''>
      <option disabled hidden value=''>
        {placeholder}
      </option>
      {options.map((value) => {
        return (
          <option value={value} key={value}>
            {value}
          </option>
        )
      })}
    </SelectStyles>
  )
}

export default Select
