import { useEffect, useCallback } from 'react'

export const useDebounce = (callback: (...args: any) => void, callbackDeps: any[], ms: number) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedCallback = useCallback(callback, callbackDeps)

  useEffect(() => {
    const handler = setTimeout(memoizedCallback, ms)

    return () => {
      clearTimeout(handler)
    }
  }, [memoizedCallback, ms])
}
