import React from 'react'
import { useHistory } from 'react-router-dom'
import LogoBlack from 'assets/images/benew_logo.png'
import LogoWhite from 'assets/images/benew_logo_white.png'
import { LogoImg, LogoStyles } from './Logo.styles'

export interface LogoPropTypes {
  /** 로고 크기 */
  size?: number
  mobileSize?: number
  color?: 'white' | undefined
}

/** `Logo` 컴포넌트의 문서입니다.  */

function Logo({ size = 80, mobileSize, color }: LogoPropTypes) {
  const history = useHistory()
  return (
    <LogoStyles size={size} mobileSize={mobileSize ?? size}>
      <LogoImg
        src={color === 'white' ? LogoWhite : LogoBlack}
        alt='로고 이미지'
        onClick={() => history.push('/')}
      />
    </LogoStyles>
  )
}

export default Logo
