import styled from 'styled-components'

export const RouteTitleStyles = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`

export const RouteBtn = styled.div`
  display: flex;
  align-items: center;

  ${({ theme: { media, typographies, colors } }) => `
    @media ${media.mobile} {
      & * {
        ${typographies.overline2}
        color: ${colors.gray70};
      }
      & svg {
        width: 10px;
        height: 10px;
          
        path {
          fill: ${colors.gray70};
        }
      }
    }
  `}
`

export const Title = styled.h4`
  ${({ theme }) => theme.typographies.subtitle}

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.subtitle4}
    }
  `}
`
