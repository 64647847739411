import styled from 'styled-components'

export const CommentSection = styled.section`
  max-width: 715px;
  margin: 0 auto;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      display: none;
    }
  `}
`

export const MobilePadding = styled.div`
  display: none;
  height: 0;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      display: block;
      height: 57px;
    }
  `}
`
