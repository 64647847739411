import { Icon, Logo } from 'components/atoms'
import { LoginFooter } from 'components/organisms'
import { Wrapper, LogoWrapper, Main, MainInner, BackBtnWrapper, BackBtn } from './AuthTemplate.styles'

interface AuthTemplateProps {
  onClickBack?: () => any
  hasFooter?: boolean
}

/**
 * 로그인/회원가입/비밀번호찾기 등 사용자인증 관련 페이지들의 기본 레이아웃을 그리는 컴포넌트
 */
const AuthTemplate: React.FC<AuthTemplateProps> = ({ onClickBack, hasFooter = false, children }) => {
  return (
    <Wrapper>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <Main>
        <MainInner>
          {onClickBack && (
            <BackBtnWrapper>
              <BackBtn onClick={onClickBack}>
                <Icon icon='back_unfilled' size='middle' color='black' />
              </BackBtn>
            </BackBtnWrapper>
          )}
          {children}
        </MainInner>
      </Main>
      {hasFooter && <LoginFooter />}
    </Wrapper>
  )
}

export default AuthTemplate
