import { ButtonWrapper } from './MoreButton.styles'

interface MoreButtonProps {
  text?: string
  onClickButton: () => void
}

const MoreButton: React.FC<MoreButtonProps> = ({ text = '더보기', onClickButton }) => (
  <ButtonWrapper type='button' onClick={onClickButton}>
    {text}
  </ButtonWrapper>
)

export default MoreButton
