import styled, { css } from 'styled-components'
import { FontColors } from 'assets/styles/types'
import * as icons from 'assets/icons/svg'

export type IconType = keyof typeof icons
export type IconSize = 'small' | 'middle' | 'large' | 'larger'
export interface StyledIconProps {
  color: FontColors
  size: IconSize
  cursor?: boolean
}

const iconOnlySizes = {
  small: css`
    width: 16px;
    height: 16px;
  `,
  middle: css`
    width: 24px;
    height: 24px;
  `,
  large: css`
    width: 32px;
    height: 32px;
  `,

  larger: css`
    width: 40px;
    height: 40px;
  `,
} as const

export const IconStyles = styled.div<StyledIconProps>`
  color: ${({ color, theme }) => theme.colors[color]};
  cursor: ${({ cursor }) => cursor && 'pointer'};

  svg {
    display: flex;
    align-items: center;
    ${({ size }) => iconOnlySizes[size]};

    > path {
      fill: ${({ color, theme }) => theme.colors[color]};
    }
  }
`
