import { Link } from 'components/atoms'
import { NavbarStyles, NavbarItem } from './Navbar.styles'

/** `Navbar` 컴포넌트의 문서입니다.  */

function Navbar() {
  return (
    <NavbarStyles>
      <Link to='/sexpedia' typography='boldBody'>
        <NavbarItem>성과사전</NavbarItem>
      </Link>
      <Link to='/posepedia' typography='boldBody'>
        <NavbarItem>체위사전</NavbarItem>
      </Link>
      <Link to='/review' typography='boldBody'>
        <NavbarItem>성층탐구</NavbarItem>
      </Link>
      <Link to='/interview' typography='boldBody'>
        <NavbarItem>인터뷰</NavbarItem>
      </Link>
    </NavbarStyles>
  )
}

export default Navbar
