import styled from 'styled-components'

export const TermsUseFormCheckBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 14px;
`

export const TermsUseFormInfo = styled.div`
  display: grid;
  border-top: 1px solid ${({ theme }) => theme.colors.black};
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  padding: 20px 0;
  row-gap: 10px;
`

export const TermsUseFormInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
