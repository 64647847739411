import { useCallback, useMemo } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

const queryParser = (p: URLSearchParams) =>
  [
    +(p.get('size') ?? 12), // size
    p.get('sort') === 'popularity' ? 'popularity' : 'recent', // sort
    p.get('tagId') ? +p.get('tagId')! : undefined, // tagId
  ] as const

export const usePagination = () => {
  const location = useLocation()
  const history = useHistory()

  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const [size, sort, tagId] = queryParser(urlSearchParams)

  // 정렬 변경 핸들러
  const onChangeSorting = useCallback(
    (nextSort: 'popularity' | 'recent') => {
      urlSearchParams.set('sort', `${nextSort}`)
      history.push(`${location.pathname}?${urlSearchParams.toString()}`)
    },
    [history, location.pathname, urlSearchParams],
  )

  // 태그 필터 변경 핸들러
  const onChangeTagId = useCallback(
    (id: number | null) => {
      if (typeof id === 'number') {
        urlSearchParams.set('tagId', `${id}`)
      } else {
        urlSearchParams.delete('tagId')
      }
      history.push(`${location.pathname}?${urlSearchParams.toString()}`)
    },
    [history, location.pathname, urlSearchParams],
  )

  return { size, sort, tagId, onChangeSorting, onChangeTagId }
}
