import { Typographies } from 'assets/styles/types'
import styled, { css } from 'styled-components'
import * as icons from 'assets/icons/svg'

export type IconType = keyof typeof icons
export type ButtonVariant = 'fill' | 'outline' | 'text'
export type ButtonSize = 'smaller' | 'small' | 'middle' | 'large'
export type IconSize = 'small' | 'middle' | 'large' | 'larger'

const buttonStyles = {
  fill: css`
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primary_color_regular};
  `,

  outline: css`
    color: ${({ theme }) => theme.colors.gray50};
    border: 1px solid ${({ theme }) => theme.colors.gray50};

    &:hover {
      color: ${({ theme }) => theme.colors.primary_color_regular};
      border: 1px solid ${({ theme }) => theme.colors.primary_color_regular};
    }
    &:focus {
      color: ${({ theme }) => theme.colors.primary_color_regular};
      border: 1px solid ${({ theme }) => theme.colors.primary_color_regular};
    }
  `,

  text: css`
    /* padding: 4px 12px; */
    background-color: inherit;
    border: 1px solid inherit;
  `,
} as const

export const buttonSizes = {
  smaller: css`
    width: 72px;
    height: 44px;
  `,

  small: css`
    width: 103px;
    height: 36px;
  `,

  middle: css`
    font-size: 14px;
    width: 156px;
    height: 44px;
  `,

  large: css`
    width: 100%;
    height: 44px;
  `,
} as const

const iconOnlySizes = {
  small: css`
    width: 16px;
    height: 16px;
  `,
  middle: css`
    width: 24px;
    height: 24px;
  `,
  large: css`
    width: 32px;
    height: 32px;
  `,

  larger: css`
    width: 40px;
    height: 40px;
  `,
} as const

export const ButtonStyles = styled.button<{
  variant: ButtonVariant
  iconOnly: boolean
  iconOnlySize: IconSize
  size: ButtonSize
  typography: Typographies
  withIcon?: IconType
}>`
  ${({ theme }) => theme.typographies.button}
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  ${({ variant }) => buttonStyles[variant]}
  ${({ iconOnly, iconOnlySize, size }) =>
    iconOnly
      ? iconOnlySize &&
        css` 
        border-radius: 50px;
        iconOnlySizes[iconOnlySize]
        `
      : buttonSizes[size]}
  cursor: pointer;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray20};
    color: ${({ theme }) => theme.colors.white};
    cursor: not-allowed;
  }

  svg {
    display: ${({ withIcon }) => (withIcon !== undefined ? 'block' : 'none')};
  }

  ${({ typography, theme }) => typography && theme.typographies[typography]};
`

export const Icon = styled.svg<{
  variant: ButtonVariant
  iconOnly: boolean
  iconOnlySize: IconSize
  withIcon: IconType
}>`
  ${({ withIcon, iconOnlySize }) =>
    withIcon
      ? iconOnlySize && iconOnlySizes[iconOnlySize]
      : css`
          width: 0px;
          height: 0px;
        `};

  > path {
    fill: currentColor;

    ${({ withIcon, variant }) =>
      // iconOnly &&
      withIcon?.split('_')[1] === 'filled' &&
      variant === 'text' &&
      css`
        fill: ${({ theme }) => theme.colors.secondary_color_regular};
        stroke: ${({ theme }) => theme.colors.secondary_color_regular};
      `}
  }

  cursor: pointer;
`
