import * as icons from 'assets/icons/svg'
import { FontColors } from 'assets/styles/types'
import { MouseEventHandler } from 'react'
import { IconStyles, IconSize, IconType } from './Icon.styles'

export const iconTypes = Object.keys(icons) as IconType[]

export interface IconProps {
  /** 사용 할 아이콘 타입 */
  icon: IconType
  color: FontColors
  size: IconSize
  cursor?: boolean
  onClick?: MouseEventHandler<HTMLDivElement>
}

function Icon({ icon, ...props }: IconProps) {
  const SVGIcon = icons[icon]

  return (
    <IconStyles {...props}>
      <SVGIcon />
    </IconStyles>
  )
}

export default Icon
