import {
  BrandStory,
  BrandStoryBanner,
  BrandStoryTitle,
  BrandStoryDescription,
  BrandContents,
  BrandStoryText,
} from './BrandStorySection.style'

interface BrandStorySectionProps {
  title: string
  description: string
  contents: string[]
}

const BrandStorySection: React.FC<BrandStorySectionProps> = ({
  title,
  description,
  contents,
}: BrandStorySectionProps) => {
  return (
    <BrandStory>
      <BrandStoryBanner>
        <BrandStoryTitle>{title}</BrandStoryTitle>
        <BrandStoryDescription>{description}</BrandStoryDescription>
      </BrandStoryBanner>
      <BrandContents>
        {contents.map((content: string) => (
          <BrandStoryText key={content}>{content}</BrandStoryText>
        ))}
      </BrandContents>
    </BrandStory>
  )
}

export default BrandStorySection
