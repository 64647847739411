import { useEffect, useState } from 'react'
import { Link, RouteProps, useHistory, withRouter, useLocation } from 'react-router-dom'
import { Logo, Icon } from 'components/atoms'
import { Navbar, Dropdown } from 'components/molecules'
import { useAuthentication, useClickOutside } from 'hooks'
import {
  HeaderStyles,
  Desktop,
  IconGroup,
  GNB,
  Mobile,
  Menu,
  Icons,
  User,
  LoginButton,
  MenuList,
  MenuListItem,
  CategoryList,
  CategoryListItem,
  LogoutBotton,
} from './Header.styles'

export interface HeaderPropTypes {
  /** 페이지 위치 */
  match: RouteProps
}

const SearchButton: React.FC = () => (
  <Link to='/search'>
    <Icon icon='search_unfilled' size='middle' color='black' cursor />
  </Link>
)

/**
 * 모바일 헤더에서 햄버거 메뉴 버튼 클릭 시 열리는 메뉴 컴포넌트
 */
const MobileMenu: React.FC<{ closeMenu: () => void }> = ({ closeMenu }) => {
  const history = useHistory()
  const { pathname } = useLocation()
  const { clearAuth, user } = useAuthentication()
  const { ref, isOpen, handleIsOpenToggle } = useClickOutside()

  const logout = () => {
    clearAuth()
    closeMenu()
    history.replace('/')
  }

  const isCategoryPage = ['/sexpedia', '/posepedia', '/review', '/interview'].includes(pathname)
  const isAboutPage = pathname === '/about'
  const isMyPage = pathname === '/mypage'

  return (
    <Menu>
      <Icons>
        <Icon icon='cancel_unfilled' size='middle' color='black' onClick={closeMenu} />
        <div style={{ display: 'flex' }}>
          <Link to='/' style={{ marginRight: '12px' }}>
            <Icon icon='home_unfilled' size='middle' color='black' onClick={closeMenu} />
          </Link>
          <Link to='/search'>
            <Icon icon='search_unfilled' size='middle' color='black' onClick={closeMenu} />
          </Link>
        </div>
      </Icons>
      <User>
        {user ? (
          <>
            <span className='acc'>{user.nickname}</span>님, 반가워요!
          </>
        ) : (
          <LoginButton>
            <Link to='/login'>
              로그인/회원가입하기
              <Icon icon='moving_right_unfilled' color='black' size='middle' />
            </Link>
          </LoginButton>
        )}
      </User>
      <MenuList>
        <MenuListItem onClick={handleIsOpenToggle} className={isCategoryPage ? 'acc' : ''}>
          카테고리
          <CategoryList ref={ref} opened={isOpen}>
            <CategoryListItem>
              <Link to='/sexpedia'>성과사전</Link>
            </CategoryListItem>
            <CategoryListItem>
              <Link to='/posepedia'>체위사전</Link>
            </CategoryListItem>
            <CategoryListItem>
              <Link to='/review'>성층탐구</Link>
            </CategoryListItem>
            <CategoryListItem>
              <Link to='/interview'>인터뷰</Link>
            </CategoryListItem>
          </CategoryList>
        </MenuListItem>
        {user && (
          <MenuListItem className={isMyPage ? 'acc' : ''}>
            <Link to='/mypage'>마이페이지</Link>
          </MenuListItem>
        )}
        <MenuListItem className={isAboutPage ? 'acc' : ''}>
          <Link to='/about'>About</Link>
        </MenuListItem>
        <MenuListItem
          onClick={() =>
            window.open('https://be-new.notion.site/ef68e525dc8c476285cbb56ffeccd9c4?v=1', '_blank')
          }
        >
          공지사항
        </MenuListItem>
      </MenuList>
      {user && <LogoutBotton onClick={logout}>로그아웃</LogoutBotton>}
    </Menu>
  )
}

/**
 * Header 컴포넌트
 */
function Header({ match: { path } }: HeaderPropTypes) {
  const location = useLocation()
  const body = document.querySelector('body')!
  const category = String(path)
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false)

  const openMenu = () => {
    body.style.touchAction = 'none'
    body.style.overflow = 'hidden'
    setMobileMenuOpened(true)
  }

  const closeMenu = () => {
    setMobileMenuOpened(false)
  }

  useEffect(() => {
    body.style.removeProperty('touch-action')
    body.style.removeProperty('overflow')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <HeaderStyles category={category}>
      {/* 데스크톱 헤더 */}
      <Desktop>
        <GNB>
          <Logo />
          <Navbar />
        </GNB>
        <IconGroup>
          <SearchButton />
          <Dropdown />
        </IconGroup>
      </Desktop>

      {/* 모바일 헤더 */}
      <Mobile>
        <Icon icon='hamburger_unfilled' size='middle' color='black' onClick={openMenu} />
        <Logo size={46} />
        <SearchButton />
        {mobileMenuOpened && <MobileMenu closeMenu={closeMenu} />}
      </Mobile>
    </HeaderStyles>
  )
}

export default withRouter(Header)
