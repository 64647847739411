import styled from 'styled-components'

export const Wrapper = styled.section`
  margin-bottom: 120px;
`

export const CommentItem = styled.div`
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.gray20};
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`

export const Child = styled.div`
  padding-left: 40px;
  position: relative;
`

export const ReplyArrowWrapper = styled.span`
  position: absolute;
  left: 12px;
  top: 0px;

  svg {
    width: 20px;
    height: 20px;
  }
`
