import * as yup from 'yup'

export const LoginFormSchema = yup.object().shape({
  email: yup.string().email('올바른 이메일 형식이 아닙니다.').required('이메일을 입력해주세요.'),
  password: yup.string().required('비밀번호를 입력해주세요.'),
})

export const emailAuthFormSchema = yup.object().shape({
  email: yup.string().email('올바른 이메일 형식이 아닙니다.').required('이메일을 입력해주세요.'),
  athNmb: yup.number().required('인증번호를 입력해주세요.'),
})

export const privacyFormSchema = yup.object().shape({
  nickname: yup
    .string()
    .required('닉네임을 입력해주세요.')
    .matches(/^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|]{2,10}$/, '닉네임은 2~10자의 한글,영어,숫자로 입력해주세요'),
  birth: yup.string().required('출생년도를 선택해주세요').nullable(),
  gender: yup.string().required('성별을 선택해주세요').nullable(),
})

export const passwordFormSchema = yup.object().shape({
  password: yup
    .string()
    .required('비밀번호를 입력해주세요.')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d!@#$%&*?]{8,}$/,
      '영문, 숫자, 특수문자(!@#$%&*?) 포함 8자리 이상으로 설정해주세요.',
    ),

  passwordConfirm: yup.string().oneOf([yup.ref('password'), null], '비밀번호가 일치하지 않습니다.'),
})

export const profileEditSchema = yup.object().shape({
  nickname: yup.string().required('닉네임을 입력해주세요.'),
  birth: yup.string().required('출생년도를 선택해주세요').nullable(),
  gender: yup.string().required('성별을 선택해주세요').nullable(),
  Introduction: yup.string().required('소개글을 입력해주세요.'),
})

export const findPasswordSchema = yup.object().shape({
  email: yup.string().email('올바른 이메일 형식이 아닙니다.').required('이메일을 입력해주세요.'),
})
