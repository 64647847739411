import styled from 'styled-components'

export const LoginFooterStyles = styled.footer`
  width: 100%;
  height: 229px;
  background-color: ${({ theme }) => theme.colors.primary_color_regular};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      height: 204px;
    }
  `}
`

export const FooterContents = styled.div`
  max-width: 360px;
`

export const Title = styled.p`
  ${({ theme }) => theme.typographies.subtitle}
  color: ${({ theme }) => theme.colors.white};
  word-break: keep-all;
  margin-bottom: 16px;

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.subtitle3}
      margin-bottom: 12px;
    }
  `}
`

export const SubText = styled.p`
  ${({ theme }) => theme.typographies.smallBody}
  color: ${({ theme }) => theme.colors.white};
  word-break: keep-all;

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.smallBody2}
    }
  `}
`
