import { useMemo } from 'react'
import { DeepPartial, Mode, UnpackNestedValue, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AnyObjectSchema } from 'yup'

export function useRegistrationForm<T>(
  validationSchema: unknown,
  mode: Mode,
  defaultValues: Record<string, unknown> = {},
) {
  const schema = useMemo(() => validationSchema, [validationSchema])

  const {
    register,
    watch,
    formState,
    handleSubmit: onSubmit,
    reset,
    setError,
    setValue,
  } = useForm<T>({
    resolver: yupResolver(schema as AnyObjectSchema),
    mode,
    defaultValues: defaultValues as UnpackNestedValue<DeepPartial<T>>,
  })

  return {
    register,
    watch,
    formState,
    onSubmit,
    reset,
    setError,
    setValue,
  }
}
