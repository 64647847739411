import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useRecoilState } from 'recoil'
import { signUpInfoState } from 'recoil/atoms'
import { getPolicy } from 'core/apis/api.policy'
import { CheckBox, Link } from 'components/atoms'
import { useLocation } from 'react-router-dom'
import { TermsUseFormCheckBox, TermsUseFormInfo, TermsUseFormInfoItem } from './TermsUseForm.styles'
import AuthForm from '../../AuthForm'

export interface TermsUseFormPropTypes {
  /** 다음 단계로 이동 동작 */
  onProceed: () => void
}

/** `TermsUseForm` 컴포넌트의 문서입니다.  */

function TermsUseForm({ onProceed }: TermsUseFormPropTypes) {
  const { state } = useLocation()
  const provider = (state as { provider?: 'KAKAO' | 'FACEBOOK' })?.provider
  const [, setSignUpInfo] = useRecoilState(signUpInfoState)
  const { data: privacy } = useQuery('PRIVACY_POLICY', () => getPolicy('PRIVACY_POLICY'))
  const { data: serviceUsage } = useQuery('SERVICE_USAGE_POLICY', () => getPolicy('SERVICE_USAGE_POLICY'))
  const { data: marketing } = useQuery('MARKETING_AGREEMENT', () => getPolicy('MARKETING_AGREEMENT'))

  const [checkedList, setCheckedList] = useState<number[]>([])
  const allChecked: boolean = [2, 3, 5].every((id) => checkedList.includes(id))

  const handleCheckboxChange = (
    { currentTarget: { checked } }: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    if (checked) {
      setCheckedList([...checkedList, id])
    } else {
      setCheckedList(checkedList.filter((el) => el !== id))
    }
  }

  const handleAllChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget

    if (checked) {
      const allCheckedArr = Array(4)
        .fill(0)
        .map((_, idx) => idx + 2)

      setCheckedList(allCheckedArr)
    } else {
      setCheckedList([])
    }
  }

  const handleNextStep = () => {
    if (!serviceUsage || !privacy || !marketing) return

    setSignUpInfo((signUpInfo) => ({
      ...signUpInfo,
      agreements: [
        { policyId: serviceUsage.id, agreed: checkedList.includes(2) },
        { policyId: privacy.id, agreed: checkedList.includes(3) },
        { policyId: marketing.id, agreed: checkedList.includes(4) },
      ],
    }))

    onProceed()
  }

  return (
    <AuthForm
      title={`${provider ? '소셜 계정으' : '이메일'}로 회원가입하기`}
      subTitle='약관동의하기'
      submitButtonDisabled={!allChecked}
      onSubmitButtonClick={handleNextStep}
      submitButtonText='다음'
    >
      <TermsUseFormCheckBox>
        <CheckBox
          id={1}
          checked={checkedList.length === 4}
          onChecked={handleAllChecked}
          value='모두 동의합니다.'
          color='black'
        />
        <TermsUseFormInfo>
          <TermsUseFormInfoItem>
            <CheckBox
              id={2}
              checked={checkedList.includes(2)}
              onChecked={handleCheckboxChange}
              value='비뉴 이용약관 (필수)'
              color='gray50'
            />
            <Link
              underline
              typography='smallBody'
              onClick={() => window.open(serviceUsage?.content, '_blank')}
              to='#'
            >
              약관보기
            </Link>
          </TermsUseFormInfoItem>
          <TermsUseFormInfoItem>
            <CheckBox
              id={3}
              checked={checkedList.includes(3)}
              onChecked={handleCheckboxChange}
              value='개인정보 수집 및 이용 약관 (필수)'
              color='gray50'
            />
            <Link
              underline
              typography='smallBody'
              target='_blank'
              onClick={() => window.open(privacy?.content, '_blank')}
              to='#'
            >
              약관보기
            </Link>
          </TermsUseFormInfoItem>
          <TermsUseFormInfoItem>
            <CheckBox
              id={4}
              checked={checkedList.includes(4)}
              onChecked={handleCheckboxChange}
              value='이벤트 및 마케팅 정보 이메일 알림 (선택)'
              color='gray50'
            />
          </TermsUseFormInfoItem>
        </TermsUseFormInfo>
        <CheckBox
          id={5}
          checked={checkedList.includes(5)}
          onChecked={handleCheckboxChange}
          value='만 14세 이상입니다. (필수)'
          color='black'
        />
      </TermsUseFormCheckBox>
    </AuthForm>
  )
}

export default TermsUseForm
