import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  /* 스크롤바 숨김 */
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      overflow: auto;
      white-space: nowrap;
      flex-wrap: nowrap;
      padding-right: 24px;
    }
  `}

  button {
    color: ${({ theme: { colors } }) => colors.gray50};
    border: 0.8px solid ${({ theme: { colors } }) => colors.gray50};
    display: inline-block;
    height: 35px;
    ${({ theme: { typographies } }) => typographies.smallBody}
    padding: 0 13px;
    flex-shrink: 0;
  }
`
