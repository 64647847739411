import { LoginForm } from 'components/organisms'
import { AuthTemplate } from 'components/templates'
import { useHistory } from 'react-router-dom'

function LoginPage() {
  const history = useHistory()

  return (
    <AuthTemplate onClickBack={() => history.go(-1)} hasFooter>
      <LoginForm />
    </AuthTemplate>
  )
}

export default LoginPage
