import { useState } from 'react'
import { useQuery } from 'react-query'
import { MyTemplate } from 'components/templates'
import { getUserScrapList, getProfileSampleImgList } from 'core/apis/api.auth'

function MyPage() {
  const [selectedCategory, setSelectedCategory] = useState<number | undefined>(undefined)
  const { data, isLoading } = useQuery(['user_scrap', selectedCategory], () =>
    getUserScrapList(selectedCategory?.toString()),
  )
  const { data: imgList, isLoading: imgListLoading } = useQuery(['user_sample_img'], getProfileSampleImgList)

  const onChangeCategory = (id: number) => {
    if (id === selectedCategory) {
      setSelectedCategory(undefined)
    } else {
      setSelectedCategory(id)
    }
  }

  return (
    <MyTemplate
      loading={isLoading || imgListLoading}
      cards={data?.content ?? []}
      thumbnailImgList={imgList ?? []}
      selectedCategory={selectedCategory}
      onChangeCategory={onChangeCategory}
    />
  )
}

export default MyPage
