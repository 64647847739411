import { useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'
import MainPage from 'pages/MainPage'
import AboutPage from 'pages/AboutPage'
import SexpediaPage from 'pages/SexpediaPage'
import PosepediaPage from 'pages/PosepediaPage'
import InterviewPage from 'pages/InterviewPage'
import ReviewPage from 'pages/ReviewPage'
import PostPage from 'pages/PostPage'
import SignUpPage from 'pages/SignUpPage'
import LoginPage from 'pages/LoginPage'
import KakaoLoginRedirectPage from 'pages/KakaoLoginRedirectPage'
import MyPage from 'pages/MyPage'
import FindPasswordPage from 'pages/FindPasswordPage'
import SearchPage from 'pages/SearchPage'
import AuthorPage from 'pages/AuthorPage'
import MobileCommentPage from 'pages/MobileCommentPage'
import { useAuthentication, useScrollToTop } from 'hooks'
import { getUserInfo } from 'core/apis/api.auth'

const Routes = () => {
  const { accessToken, tokenType, user, setUser } = useAuthentication()
  const location = useLocation()

  useEffect(() => {
    ReactGA.initialize('G-K9GY05D00P')
    ReactGA.set({ page: location.pathname })
    ReactGA.send({ hitType: location.pathname, page: `/${location.pathname}` })
  }, [location])

  /**
   * 페이지 로드 시 유저 정보 fetch
   * 유저 상태가 null일 때만 수행한다
   */
  useEffect(() => {
    if (!accessToken || !tokenType || !!user) return
    getUserInfo().then(setUser)
  }, [accessToken, tokenType, user, setUser])

  useScrollToTop()

  return (
    <Switch>
      <Route exact path='/' component={MainPage} />
      <Route exact path='/about' component={AboutPage} />
      <Route exact path='/sexpedia' component={SexpediaPage} />
      <Route exact path='/posepedia' component={PosepediaPage} />
      <Route exact path='/interview' component={InterviewPage} />
      <Route exact path='/review' component={ReviewPage} />
      <Route exact path='/post/:id' component={PostPage} />
      <Route exact path='/post/:id/comments' component={MobileCommentPage} />
      <Route exact path='/author/:id' component={AuthorPage} />
      <Route exact path='/signUp' component={SignUpPage} />
      <Route exact path='/login' component={LoginPage} />
      <Route exact path='/login/kakao' component={KakaoLoginRedirectPage} />
      <Route exact path='/mypage' component={MyPage} />
      <Route exact path='/search' component={SearchPage} />
      <Route exact path='/find-password' component={FindPasswordPage} />
    </Switch>
  )
}

export default Routes
