import React from 'react'
import { Chips } from 'components/atoms'
import { Wrapper } from './RadioChips.styles'

export interface RadioChipsProps {
  /**
   * id: id로 사용될 값
   * label: 화면에 표시될 내용
   */
  options: { id: number; label: string }[]

  // 선택된 칩스의 id
  selectedId?: number

  /**
   * id: 클릭한 chips의 id
   * selected: 선택한건지, 해제한건지에 대한 boolean값
   */
  onClick?: (id: number, selected: boolean) => void
}

/**
 * RadioChips
 * 라디오인풋처럼 여러개의 칩스 중에서 하나를 선택할 수 있는 컴포넌트
 */
const RadioChips: React.FC<RadioChipsProps> = ({ options, selectedId, onClick }) => {
  const onClickChips = (id: number) => {
    if (selectedId === id) {
      onClick?.(id, false)
    } else {
      onClick?.(id, true)
    }
  }

  return (
    <Wrapper>
      {options.map(({ id, label }) => (
        <Chips key={id} onClick={() => onClickChips(id)} active={id === selectedId}>
          {label}
        </Chips>
      ))}
    </Wrapper>
  )
}

export default RadioChips
