import { Header, MainFooter } from 'components/organisms'
import LoadingOverlayWrapper from 'react-loading-overlay-ts'
import { Img } from 'components/atoms'
import Loading from 'assets/images/loading.png'
import { Wrapper, Main } from './BaseTemplate.styles'

export interface BaseTemplateProps {
  loading?: boolean
  hasFooter?: boolean
  style?: React.CSSProperties
}

/**
 * BaseTemplate 컴포넌트
 *
 * 헤더/메인/푸터로 구성되는 기본 레이아웃 템플릿
 */
const BaseTemplate: React.FC<BaseTemplateProps> = ({
  loading = false,
  hasFooter = true,
  children,
  style,
}) => {
  return (
    <LoadingOverlayWrapper
      active={loading}
      spinner={<Img src={Loading} alt='로딩 이미지' border='none' borderRadius='none' />}
    >
      <div style={{ minHeight: '100vh', ...style }}>
        {loading || (
          <Wrapper>
            <Header />
            <Main>{children}</Main>
            {hasFooter && <MainFooter />}
          </Wrapper>
        )}
      </div>
    </LoadingOverlayWrapper>
  )
}

export default BaseTemplate
