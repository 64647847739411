import React from 'react'
import { Button, Img } from 'components/atoms'
import { RouteTitle } from 'components/molecules'
import { PostCard } from 'types/ContentResponse'
import { ArrowProps, ResponsiveType } from 'react-multi-carousel'
import {
  CardSliderWrapper,
  CardSliderStyles,
  NextSlideArrow,
  PrevSlideArrow,
  Slide,
  SlideContents,
} from './CardSlider.styles'

const CustomRight: React.FC<ArrowProps> = ({ onClick }) => (
  <NextSlideArrow>
    <Button
      variant='text'
      size='small'
      iconOnly
      withIcon='moving_right_unfilled'
      iconOnlySize='large'
      onClick={onClick}
    >
      moving_right_icon
    </Button>
  </NextSlideArrow>
)
const CustomLeft: React.FC<ArrowProps> = ({ onClick }) => (
  <PrevSlideArrow>
    <Button
      variant='text'
      size='small'
      iconOnly
      withIcon='moving_left_unfilled'
      iconOnlySize='large'
      onClick={onClick}
    >
      moving_left_icon
    </Button>
  </PrevSlideArrow>
)

export interface CardSliderPropTypes {
  /** 카드 데이터 */
  slides: PostCard[]

  /** 카드 슬라이더 제목 */
  title: string
}

/**
 * `CardSlider` 컴포넌트
 *
 * 카드 데이터를 캐로셀 형식으로 보여준다
 */
function CardSlider({ slides, title: routeTitle }: CardSliderPropTypes) {
  // 반응형 설정 값..
  const responsive: ResponsiveType = {
    desktop: {
      breakpoint: { max: 3000, min: 750 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 750, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  }

  return (
    <CardSliderWrapper>
      <RouteTitle title={routeTitle} to='' />
      <CardSliderStyles
        responsive={responsive}
        draggable
        swipeable
        infinite
        arrows
        customRightArrow={<CustomRight />}
        customLeftArrow={<CustomLeft />}
        showDots={false}
        renderDotsOutside={false}
      >
        {slides.map(({ id, title, thumbnail, createdDate, description, author }) => (
          <Slide key={id} href={`/post/${id}`}>
            <Img src={thumbnail} alt={title} border='none' borderRadius='none' />
            <SlideContents>
              <SlideContents.Date>
                {new Date(createdDate).toLocaleDateString().replaceAll(' ', '')}
              </SlideContents.Date>
              <SlideContents.Title>{title}</SlideContents.Title>
              <SlideContents.Description>{description}</SlideContents.Description>
              <SlideContents.Author>Edit. {author.nickname}</SlideContents.Author>
            </SlideContents>
          </Slide>
        ))}
      </CardSliderStyles>
    </CardSliderWrapper>
  )
}

export default CardSlider
