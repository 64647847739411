import styled from 'styled-components'

interface StyledHeaderPropTypes {
  category: string
}

export const HeaderStyles = styled.header<StyledHeaderPropTypes>`
  width: 100%;
  height: 132px;
  border-bottom: ${({ category, theme }) =>
    category === '/post/:id' && `0.75px solid ${theme.colors.gray10}`};
  flex-shrink: 0;
  padding: 0 24px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      height: 60px;
    }
  `}
`

export const Desktop = styled.div`
  max-width: 1100px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      display: none;
    }
  `}
`

export const GNB = styled.div`
  display: flex;
  align-items: center;
`

export const IconGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 24px;
`

export const Mobile = styled(Desktop)`
  display: none;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      display: flex;
    }
  `}
`

// 아래부터 모바일 메뉴

export const Menu = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 999;
  overflow: scroll;
`

export const Icons = styled.div`
  height: 60px;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const User = styled.div`
  ${({ theme }) => theme.typographies.subtitle2}
  height: 84px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  margin-bottom: 44px;
  border-bottom: 6px solid ${({ theme }) => theme.colors.gray05};

  .acc {
    color: ${({ theme }) => theme.colors.primary_color_regular};
  }
`

export const LoginButton = styled.button`
  a {
    display: flex;
    align-items: center;
  }

  div {
    margin-left: 8px;
    padding-top: 4px;
  }
`

export const MenuList = styled.ul`
  padding-bottom: 80px;
  border-bottom: 6px solid ${({ theme }) => theme.colors.gray05};
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  margin-bottom: 32px;

  .acc {
    color: ${({ theme }) => theme.colors.primary_color_regular};
  }
`

export const MenuListItem = styled.li`
  padding: 0 24px;

  &,
  & > button {
    ${({ theme }) => theme.typographies.subtitle}
    color: ${({ theme }) => theme.colors.gray70};
  }
`

export const CategoryList = styled.ul<{ opened: boolean }>`
  background-color: ${({ theme }) => theme.colors.gray05};
  margin-left: -24px;
  margin-right: -24px;
  margin-top: ${({ opened }) => (opened ? '12px' : 0)};
  height: ${({ opened }) => (opened ? '232px' : '0')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 16px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
`

export const CategoryListItem = styled.div`
  ${({ theme }) => theme.typographies.subtitle2}
  color: ${({ theme }) => theme.colors.black};
  padding-left: 112px;
  position: relative;

  ::before {
    content: '';
    width: 24px;
    border-top: 1px solid black;
    height: 1px;
    position: absolute;
    left: 76px;
    top: 17px;
  }
`

export const LogoutBotton = styled.button`
  padding: 0 24px;
  ${({ theme }) => theme.typographies.boldBody}
  color: ${({ theme }) => theme.colors.gray30};
  margin-bottom: 60px;
`
