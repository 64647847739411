import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

const GlobalStyles = createGlobalStyle`
  ${normalize}

  body{
    background-color: #ffffff;
    font-family: 'Noto Sans KR', sans-serif;
  }

  * {
    box-sizing: border-box; 
    margin: 0;
    padding: 0;
  }

  a {
    color: inherit;
    line-height: normal;
    text-decoration: none;
  }

  input, button {
    background-color: transparent;
    border: none;
    outline: none;
  }

  p {
    margin: 0;
    padding: 0;
    white-space: pre-wrap;
  }

  ol, ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  img {
    display: block;
    /* width: 100%; */
    height: 100%;
  }

`

export default GlobalStyles
