import { Button, Text } from 'components/atoms'
import { DimmedBackground, ModalWrapper, ModalContent, ModalButtonWrapper } from './Modal.styles'

export interface ModalProps {
  title?: string
  visible: boolean
  onOk: () => void
  onCancel?: () => void
  okText?: string
  cancelText?: string
}

const Modal: React.FC<ModalProps> = ({
  children,
  title,
  visible,
  onOk,
  onCancel,
  okText = '확인',
  cancelText = '취소',
}) => {
  if (!visible) return null

  return (
    <DimmedBackground>
      <ModalWrapper>
        {title && (
          <Text
            style={{ lineHeight: '44px' }}
            as='h3'
            typography='subtitle'
            align='center'
            color='primary_color_regular'
          >
            {title}
          </Text>
        )}
        <ModalContent>{children}</ModalContent>
        <ModalButtonWrapper>
          {onCancel && (
            <Button
              variant='fill'
              size='middle'
              typography='smallButton'
              onClick={onCancel}
              style={{ backgroundColor: '#C2C1C1' }} // gray30
            >
              {cancelText}
            </Button>
          )}
          <Button variant='fill' size='middle' typography='smallButton' onClick={onOk}>
            {okText}
          </Button>
        </ModalButtonWrapper>
      </ModalWrapper>
    </DimmedBackground>
  )
}

export default Modal
