import { Text, Button } from 'components/atoms'
import { PaginationStyles, PageNumberList, PageNumber, IconWrapper } from './Pagination.styles'

export interface PaginationPropTypes {
  /** 현재 페이지 위치 */
  currentPage: number

  /** 보여줄 항목 개수 */
  postsPerPage: number

  /** 모든 항목 개수  */
  totalPosts: number

  /** 페이지네이션 동작 함수 */
  paginate: (num: number) => void
}

/** `Pagination` 컴포넌트의 문서입니다.  */

function Pagination({ currentPage = 1, postsPerPage = 5, totalPosts = 20, paginate }: PaginationPropTypes) {
  const pageNumbers: number[] = []

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i += 1) {
    pageNumbers.push(i)
  }

  return (
    <PaginationStyles>
      <IconWrapper>
        <Button
          variant='text'
          size='smaller'
          iconOnly
          withIcon='moving_left_unfilled'
          iconOnlySize='middle'
          onClick={() => currentPage > 1 && paginate(currentPage - 1)}
        >
          moving_left_icon
        </Button>
      </IconWrapper>

      <PageNumberList>
        {pageNumbers.map((num) => (
          <PageNumber key={num} onClick={() => paginate(num)} currentPage={currentPage} pageNumber={num}>
            <Text as='span' typography='subtitle3'>
              {`${num}`}
            </Text>
          </PageNumber>
        ))}
      </PageNumberList>

      <IconWrapper>
        <Button
          variant='text'
          size='smaller'
          iconOnly
          withIcon='moving_right_unfilled'
          iconOnlySize='middle'
          onClick={() => pageNumbers.length > currentPage && paginate(currentPage + 1)}
        >
          moving_right_icon
        </Button>
      </IconWrapper>
    </PaginationStyles>
  )
}

export default Pagination
