import styled from 'styled-components'
import Carousel from 'react-multi-carousel'
import { lineClamp } from 'assets/styles/lineClamp'

export const CardSliderWrapper = styled.section`
  width: 100%;
  margin: 0 auto;
  position: relative;
`

export const CardSliderStyles = styled(Carousel)`
  position: relative;
  aspect-ratio: 1084 /410;
  border-radius: 16px;
  border: 1px solid ${({ theme: { colors } }) => colors.gray30};

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      border-radius: 8px;
      min-height: 329px;
      aspect-ratio: initial;
      border: 0;
      box-shadow: 3px 3px 5px 0px #0002;

      .react-multi-carousel-track {
        min-height: 329px;
      }
    }
  `}

  .react-multi-carousel-track {
    height: 100%;
  }
`

export const NextSlideArrow = styled.div`
  position: absolute;
  top: 45%;
  right: 32px;
  z-index: 10;
  cursor: pointer;
  user-select: none;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      display: none;
    }
  `}
`

export const PrevSlideArrow = styled.div`
  position: absolute;
  z-index: 10;
  left: 32px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      display: none;
    }
  `}
`

export const Slide = styled.a`
  display: flex;
  width: 100%;
  height: 100%;

  > * {
    width: 50%;
  }

  img {
    object-fit: cover;
  }

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      flex-direction: column;

      > * {
        width: 100%;
      }

      > img {
        flex: 1;
      }

      > div {
        flex: 0 0 104px;
      }
    }
  `}
`

const Date = styled.span`
  ${({ theme: { typographies } }) => typographies.smallBody}
  color: ${({ theme: { colors } }) => colors.black};

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      display: none;
    }
  `}
`

const Title = styled.h5`
  ${({ theme: { typographies } }) => typographies.subtitle2}
  color: ${({ theme: { colors } }) => colors.black};
  ${{ ...lineClamp(2) }}
  word-break: keep-all;

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.largeSubtitleMobile}
    }
  `}
`

const Description = styled.p`
  ${({ theme: { typographies } }) => typographies.body}
  color: ${({ theme: { colors } }) => colors.black};
  ${{ ...lineClamp(4) }}

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      display: none;
    }
  `}
`

const Author = styled.span`
  ${({ theme: { typographies } }) => typographies.subtitle4}
  color: ${({ theme: { colors } }) => colors.black};

  ${({ theme: { media, typographies, colors } }) => `
    @media ${media.mobile} {
      ${typographies.subtitleMobile}
      color: ${colors.gray70};
    }
  `}
`

const SlideContentsStyle = styled.div`
  padding: 55px 112px 55px 68px;
  padding: 16px 72px 16px 28px;
  padding-right: clamp(72px, 10.2%, 112px);
  padding-left: clamp(28px, 6.2%, 68px);
  padding-top: clamp(16px, 5%, 55px);
  padding-bottom: clamp(16px, 5%, 55px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      padding: 12px 24px;
    }
  `}
`

const SlideContents = SlideContentsStyle as typeof SlideContentsStyle & {
  Date: typeof Date
  Title: typeof Title
  Description: typeof Description
  Author: typeof Author
}

SlideContents.Date = Date
SlideContents.Title = Title
SlideContents.Description = Description
SlideContents.Author = Author

export { SlideContents }
