import styled from 'styled-components'

export const TabsContainer = styled.nav`
  width: 100%;
  height: 100%;
  max-width: 462px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  margin: auto;
`

export const TabItem = styled.div<{ active?: boolean; underline: boolean; wide: boolean }>`
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: ${({ wide }) => (wide ? 1 : 'initial')};
  color: ${({ active, theme: { colors } }) => (active ? colors.primary_color_regular : colors.black)};
  ${({ active, theme: { typographies } }) => (active ? typographies.subtitle2 : typographies.subtitle2_1)}
  border-bottom: ${({ active, underline, theme }) =>
    active && underline ? `2px solid ${theme.colors.primary_color_regular}` : `2px solid transparent`};
  padding: 0 12px;

  ${({ active, theme: { typographies, media, colors } }) => `
    @media ${media.mobile} {
      ${active ? typographies.subtitle4 : typographies.body}
      color: ${active ? colors.primary_color_regular : colors.gray30};
    }
  `}
`
