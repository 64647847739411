import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { useDebounce, useInput } from 'hooks'
import { SearchTemplate } from 'components/templates'
import { getKeywords } from 'core/apis/api.keyword'
import { searchPost } from 'core/apis/api.post'

const SearchPage: React.FC = () => {
  const location = useLocation<{ query: string } | undefined>()
  const history = useHistory()
  const [input, onChangeInput, setInput] = useInput()
  const [debouncedInput, setDebouncedInput] = useState('')

  useEffect(() => {
    setInput(location.state?.query ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useDebounce(
    () => {
      setDebouncedInput(input)
      history.replace('/search', { query: input })
    },
    [input],
    150,
  )

  const { data: keywords } = useQuery('keywords', getKeywords)
  const { data: searchResult, isLoading } = useQuery(
    ['search-result', debouncedInput],
    () => searchPost(debouncedInput),
    { enabled: !!debouncedInput },
  )

  return (
    <SearchTemplate
      searchInput={input}
      searchResult={searchResult?.content}
      onChangeInput={onChangeInput}
      keywordTitle='추천 키워드'
      keywords={keywords ?? []}
      onClickKeyword={({ name }) => setInput(name)}
      isLoading={isLoading}
    />
  )
}

export default SearchPage
