import { useEffect } from 'react'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import { myPageStepState, userState, profileState, unregisterState } from 'recoil/atoms'
import { Profile, AccountSetting, PhotoEditor, CardList, TitleWithFilter } from 'components/organisms'
import { BaseTemplate, UnregisterTemplate } from 'components/templates'
import { PageHeader } from 'components/molecules'
import { PostCategory } from 'types/PostCategory'
import { PostCard } from 'types/ContentResponse'
import { BaseTemplateProps } from '../BaseTemplate/BaseTemplate'
import { NotScraped, Section, SectionInner } from './MyTemplate.styles'

export interface MyTemplatePropTypes extends BaseTemplateProps {
  cards: PostCard[]
  thumbnailImgList: string[]
  selectedCategory?: number
  onChangeCategory: (id: number) => void
}

function MyTemplate({
  loading,
  cards,
  thumbnailImgList,
  selectedCategory,
  onChangeCategory,
}: MyTemplatePropTypes) {
  const [step, setStep] = useRecoilState(myPageStepState)
  const user = useRecoilValue(userState)
  const setProfileInfo = useSetRecoilState(profileState)
  const options = Object.values(PostCategory).map(({ KO: label, ID: id }) => ({ id, label }))
  const isUnregister = useRecoilValue(unregisterState)

  useEffect(() => {
    setStep('1')
    setProfileInfo(user)
  }, [setProfileInfo, setStep, user])

  if (isUnregister) {
    return (
      <BaseTemplate loading={loading}>
        <UnregisterTemplate />
      </BaseTemplate>
    )
  }

  return (
    <BaseTemplate loading={loading}>
      <PageHeader>마이페이지</PageHeader>
      {step === '1' && <Profile />}
      <Section>
        <SectionInner>
          {
            {
              '1': (
                <>
                  <TitleWithFilter
                    title='스크랩한 글'
                    options={options}
                    onClick={onChangeCategory}
                    selectedId={selectedCategory}
                  />
                  {cards?.length ? (
                    <CardList cards={cards} showSortingButtons={false} showDescription />
                  ) : (
                    <NotScraped>아직 스크랩한 글이 없어요!</NotScraped>
                  )}
                </>
              ),
              '2': <AccountSetting />,
              '3': <PhotoEditor thumbnailImgList={thumbnailImgList} />,
            }[step]
          }
        </SectionInner>
      </Section>
    </BaseTemplate>
  )
}

export default MyTemplate
