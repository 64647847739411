import { useCallback, useEffect, useRef, useState } from 'react'

export function useClickOutside() {
  const [isOpen, setOpen] = useState<boolean>(false)
  const ref = useRef<HTMLUListElement>(null)

  const handleIsOpenToggle = useCallback(() => {
    setOpen((prev) => !prev)
  }, [])

  const handleClickOutside = useCallback(
    ({ target }: MouseEvent) => {
      if (isOpen && !ref.current?.contains(target as HTMLElement)) handleIsOpenToggle()
      else if (isOpen && ref.current?.contains(target as HTMLElement)) handleIsOpenToggle()
    },
    [isOpen, handleIsOpenToggle],
  )

  useEffect(() => {
    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [handleClickOutside])

  return { ref, isOpen, handleIsOpenToggle }
}
