import styled from 'styled-components'

export const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
`

export const LogoWrapper = styled.div`
  position: absolute;
  top: 45px;
  left: 35px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      display: none;
    }
  `}
`

export const Main = styled.main`
  flex: 1;
  padding: 198px 24px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      padding-top: 0;
    }
  `}
`

export const MainInner = styled.section`
  width: 100%;
  max-width: 348px;
  position: relative;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      max-width: 100%;
    }
  `}
`

export const BackBtnWrapper = styled.div`
  text-align: left;
  margin-bottom: 17px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      margin-bottom: 40px;
    }
  `}
`

export const BackBtn = styled.button`
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
  margin-top: 12px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      margin-top: 20px;
    }
  `}

  svg {
    ${({ theme: { media } }) => `
      @media ${media.mobile} {
        width: 20px;
        height: 20px;
      }
    `}
  }
`
