import { useEffect, useRef, useState, useCallback } from 'react'

export function useHover<T extends HTMLElement = HTMLElement>() {
  const [isHover, setIsHover] = useState(false)

  const ref = useRef<T>(null)

  const handler = useCallback(
    ({ target }: MouseEvent) => {
      if (!ref.current || !target) return
      const hover = ref.current.contains(target as T)
      setIsHover(hover)
    },
    [ref],
  )

  useEffect(() => {
    if (ref.current) window.addEventListener('mousemove', handler)

    return () => window.removeEventListener('mousemove', handler)
  }, [ref, handler])

  return [ref, isHover] as const
}
