import styled from 'styled-components'

export const DropdownStyles = styled.div`
  position: relative;
`

export const MenuBox = styled.ul`
  width: 170px;
  position: absolute;
  top: 50px;
  left: -120px;
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  border-radius: 8px;
  padding: 18px 26px;
  z-index: 3;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.1);
`

export const ProfileImg = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer;
`

export const UserIcon = styled(ProfileImg)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary_color_light};
  border-radius: 50%;
`
