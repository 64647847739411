import styled from 'styled-components'

export type BorderTypes = 'default' | 'gray' | 'none'
export type BorderRadiusTypes = 'small' | 'leftBig' | 'big' | 'rightBig' | 'none' | 'circle'

interface ImgStyledPropTypes {
  border: BorderTypes
  borderRadius: BorderRadiusTypes
}
const borderStyles: { [key in BorderTypes]: string } = {
  default: '1px solid black',
  gray: '1px solid gray',
  none: 'none',
}
const borderRadiusStyles: { [key in BorderRadiusTypes]: string } = {
  small: '4px',
  leftBig: '16px 0 0 16px',
  big: '16px',
  rightBig: '0 16px 16px 0',
  none: 'none',
  circle: '50%',
}

export const ImgStyles = styled.img<ImgStyledPropTypes>`
  width: 100%;
  /* object-fit: cover; */
  border: ${({ border }) => (border ? borderStyles[border] : 'none')};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadiusStyles[borderRadius] : '8px')};
`
