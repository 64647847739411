import { useHistory } from 'react-router-dom'

export function usePageRoute() {
  const history = useHistory()

  function handlePageMove(url: string) {
    history.push(url)
  }

  return { handlePageMove }
}
