import React from 'react'
import { InputStyles, BorderTypes } from './Input.styles'

export interface InputPropTypes extends React.InputHTMLAttributes<HTMLInputElement> {
  /** 속성  */
  type: string

  /** placeholder 문구 */
  placeholder: string

  /** 텍스트 */
  value?: string

  /** 테두리 모양 */
  border?: BorderTypes

  /** 유효성 검사 */
  register?: Record<string, unknown>

  /** 에러 상태 */
  error?: boolean

  /** 성공 상태 */
  success?: boolean

  disabled?: boolean

  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
}

/** `Input` 컴포넌트의 문서입니다.  */

function Input({
  type,
  placeholder = '텍스트',
  value,
  border = 'outline',
  register,
  error,
  success,
  disabled,
  onBlur,
  ...rest
}: InputPropTypes) {
  return (
    <InputStyles
      type={type}
      placeholder={placeholder}
      value={value}
      border={border}
      {...register}
      error={error}
      success={success}
      disabled={disabled}
      onBlur={onBlur}
      {...rest}
    />
  )
}

export default Input
