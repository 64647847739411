import styled, { css } from 'styled-components'

export const MenuStyles = styled.li<{ weight?: 'bold'; underline?: boolean }>`
  font-weight: ${({ weight }) => weight && `${weight}`};
  height: 45px;
  cursor: pointer;
  display: flex;
  align-items: center;
  ${({ underline }) =>
    underline &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray10};
    `}
`
