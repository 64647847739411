import { useHistory, useLocation } from 'react-router-dom'
import { BaseTemplate } from 'components/templates'
import { CardList, Banner, Header } from 'components/organisms'
import { PostCard, Tag } from 'types/ContentResponse'
import { RadioChips, Tabs } from 'components/molecules'
import { usePagination } from 'hooks/usePagination'
import { BaseTemplateProps } from '../BaseTemplate/BaseTemplate'
import {
  BannerWrapper,
  TabsWrapper,
  ListWrapper,
  ListWrapperInner,
  FixedHeaderWrapper,
  MobileHeader,
  MobileChipsWrapper,
} from './CategoryTemplate.styles'

interface CategoryTemplateProps extends BaseTemplateProps {
  cards?: PostCard[]
  tags: Tag[]
  bannerImg: string
  title: string
  subTitle: string
  onClickMoreButton?: () => void | Promise<any>
  showMoreButton?: boolean
}

const CategoryTemplate: React.FC<CategoryTemplateProps> = ({
  loading,
  cards,
  tags,
  bannerImg,
  title,
  subTitle,
  onClickMoreButton,
  showMoreButton,
}) => {
  const { pathname } = useLocation()
  const { tagId, onChangeTagId } = usePagination()
  const history = useHistory()

  const onClickTag = (id: number) => {
    onChangeTagId(id === tagId ? null : id)
  }

  return (
    <FixedHeaderWrapper>
      <BaseTemplate loading={loading}>
        <BannerWrapper>
          <Banner
            bannerImg={bannerImg}
            title={title}
            subTitle={subTitle}
            tags={tags}
            checkedTagId={tagId}
            onClickTag={onClickTag}
          />
        </BannerWrapper>

        <MobileHeader>
          <Header />
          <TabsWrapper>
            <Tabs activeKey={pathname} onChange={(path) => history.push(path)}>
              <Tabs.Tab key='/sexpedia'>성과사전</Tabs.Tab>
              <Tabs.Tab key='/posepedia'>체위사전</Tabs.Tab>
              <Tabs.Tab key='/review'>성층탐구</Tabs.Tab>
              <Tabs.Tab key='/interview'>인터뷰</Tabs.Tab>
            </Tabs>
          </TabsWrapper>
        </MobileHeader>

        <ListWrapper>
          {tags.length ? (
            <MobileChipsWrapper>
              <RadioChips
                options={tags.map(({ id, name }) => ({ id, label: name }))}
                selectedId={tagId}
                onClick={onClickTag}
              />
            </MobileChipsWrapper>
          ) : null}
          <ListWrapperInner>
            <CardList cards={cards} showMoreButton={showMoreButton} onClickMoreButton={onClickMoreButton} />
          </ListWrapperInner>
        </ListWrapper>
      </BaseTemplate>
    </FixedHeaderWrapper>
  )
}

export default CategoryTemplate
