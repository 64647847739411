import bannerImage from 'assets/images/footerBanner.png'
import { Icon, Img } from 'components/atoms'
import { useHover } from 'hooks'
import {
  Banner,
  BannerInner,
  BannerContents,
  BannerImage,
  IconWrapper,
  TextWrapper,
  BannerIcon,
  BannerTitle,
  BannerDescription,
} from './FooterBanner.styles'

const FooterBanner: React.FC = () => {
  const [hoverRef, isHovered] = useHover<HTMLAnchorElement>()

  return (
    <Banner
      ref={hoverRef}
      href='https://docs.google.com/forms/d/e/1FAIpQLSd0zPfQXmmadRR-UYXxNWU7GOO1Kjx_YzEew62WvEjjoQJBOA/viewform?usp=send_form'
      target='_blank'
      rel='noreferrer'
    >
      <BannerInner>
        <BannerContents>
          <TextWrapper>
            <BannerIcon>💌</BannerIcon>
            <BannerTitle>당신의 고민을 알려주세요!</BannerTitle>
            <BannerDescription>어떤 고민이든 좋아요. 비뉴가 도와드릴게요!</BannerDescription>
          </TextWrapper>
          <IconWrapper>
            <Icon
              icon='moving_round_unfilled'
              size='larger'
              color={isHovered ? 'success_color_light' : 'secondary_color_regular'}
            />
          </IconWrapper>
        </BannerContents>
        <BannerImage>
          <Img src={bannerImage} alt='푸터 베너 이미지' border='none' borderRadius='none' />
        </BannerImage>
      </BannerInner>
    </Banner>
  )
}

export default FooterBanner
