import { useTimer } from 'hooks'
import { CertificationTimer } from './Timer.styles'

interface TimeTypes {
  minutes: number
  seconds: number
}

function Timer({ minutes: minuteProps, seconds: secondProps }: TimeTypes) {
  const { minutes, seconds } = useTimer(minuteProps, secondProps)

  return <CertificationTimer>{`${minutes}:${seconds}`}</CertificationTimer>
}

export default Timer
