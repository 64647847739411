import { useRecoilState } from 'recoil'
import { signUpInfoState } from 'recoil/atoms'
import { TextField, SelectField } from 'components/molecules'
import { useRegistrationForm, usePageRoute, useBirthYear } from 'hooks'
import { privacyFormSchema } from 'core/utils/validation'
import { postSignUpRequest } from 'core/apis/api.auth'
import { Gender } from 'types/User'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import AuthForm from '../../AuthForm'

export interface IFormInputs {
  nickname: string
  birth: string
  gender: string
}

/** `PrivacyForm` 컴포넌트의 문서입니다.  */

function PrivacyForm() {
  const { state } = useLocation()
  const [signUpInfo, setSignUpInfo] = useRecoilState(signUpInfoState)
  const {
    register,
    watch,
    formState: { /* errors, */ isValid },
    onSubmit,
  } = useRegistrationForm<IFormInputs>(privacyFormSchema, 'all')
  const yearOfBirthList = useBirthYear()
  const genderList: string[] = ['여성', '남성', '인터섹스', '고민중']
  const { handlePageMove } = usePageRoute()

  useEffect(() => {
    const provider = (state as { provider?: 'KAKAO' | 'FACEBOOK' })?.provider ?? 'LOCAL'

    setSignUpInfo((info) => ({ ...info, provider }))
  }, [state, setSignUpInfo])

  const handleSignUp = async () => {
    await postSignUpRequest({
      ...signUpInfo,
      nickname: watch('nickname'),
      birthYear: Number(watch('birth')),
      sex: Gender[watch('gender') as keyof typeof Gender],
    })

    alert('회원가입 성공 🎊🎉')
    handlePageMove('/login')
  }

  return (
    <AuthForm
      title='이메일로 회원가입하기'
      subTitle='개인정보 입력하기'
      onSubmit={(...args) => {
        onSubmit(handleSignUp)(...args)
      }}
      submitButtonText='가입하기'
      submitButtonDisabled={!isValid}
    >
      <TextField
        type='text'
        label='닉네임'
        placeholder='닉네임 입력하기'
        register={{ ...register('nickname') }}
      />
      <SelectField
        name='birth'
        label='출생연도'
        placeholder='출생연도'
        options={yearOfBirthList}
        register={{ ...register('birth') }}
      />
      <SelectField
        name='gender'
        label='성별'
        placeholder='성별'
        options={genderList}
        register={{ ...register('gender') }}
      />
      <AuthForm.Ul>
        <AuthForm.Li>가입 완료 후 개인정보 수정이 가능합니다.</AuthForm.Li>
      </AuthForm.Ul>
    </AuthForm>
  )
}

export default PrivacyForm
