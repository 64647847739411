import { FormEventHandler, ReactNode } from 'react'
import { FormHeaderProps } from 'components/molecules/FormHeader/FormHeader'
import { FormHeader } from 'components/molecules'
import { Button } from 'components/atoms'
import { Form, FieldWrapper, SubmitButtonWrapper, ListField, ListFieldItem } from './AuthForm.styles'

interface AuthFormProps extends FormHeaderProps {
  onSubmit?: FormEventHandler<HTMLFormElement>
  submitButtonText?: string
  submitButtonDisabled?: boolean
  onSubmitButtonClick?: () => void
  tail?: ReactNode // 버튼 아래 위치하는 컴포넌트
}

/**
 * 로그인/회원가입 폼의 공통 레이아웃 컴포넌트
 * 폼 내의 각 필드/버튼 사이의 간격 조절
 */
const AuthForm: React.FC<AuthFormProps> & { Ul: typeof ListField; Li: typeof ListFieldItem } = ({
  children,
  title,
  subTitle,
  submitButtonText,
  submitButtonDisabled = false,
  onSubmitButtonClick,
  onSubmit,
  tail,
}) => {
  return (
    <Form onSubmit={onSubmit}>
      <FormHeader title={title} subTitle={subTitle} />
      <FieldWrapper>{children}</FieldWrapper>
      {submitButtonText && (
        <SubmitButtonWrapper>
          <Button
            type='submit'
            variant='fill'
            disabled={submitButtonDisabled}
            size='large'
            typography='smallButton'
            onClick={onSubmitButtonClick}
          >
            {submitButtonText}
          </Button>
        </SubmitButtonWrapper>
      )}
      {tail}
    </Form>
  )
}

AuthForm.Ul = ListField
AuthForm.Li = ListFieldItem

export default AuthForm
