import React from 'react'
import { DotsStyles } from './Dots.styles'

export interface DotsPropTypes {
  /** 활성화 / 비활성화 */
  active?: boolean

  /** 클릭 이벤트 */
  onClick?: () => void
}

/** `Dots` 컴포넌트의 문서입니다.  */

function Dots({ onClick, active }: DotsPropTypes) {
  return <DotsStyles className={active ? 'active' : 'inactive'} active={active} onClick={onClick} />
}

export default Dots
