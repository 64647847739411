import React, { ReactNode } from 'react'
import { Typographies, FontColors } from 'assets/styles/types'
import * as icons from 'assets/icons/svg'
import { ButtonStyles, Icon, ButtonSize, ButtonVariant, IconSize, IconType } from './Button.styles'

export const iconTypes = Object.keys(icons) as IconType[]

export interface ButtonPropsTypes {
  /** 버튼 속성 */
  type?: 'button' | 'submit' | 'reset'

  /** 버튼 종류 */
  variant: ButtonVariant

  /** 버튼 크기 */
  size: ButtonSize

  /** 버튼 텍스트 스타일 */
  typography?: Typographies

  /** 버튼 텍스트 색상 */
  color?: FontColors

  /** 버튼에서 아이콘만 보여줄 때 이 값을 `true`로 설정하세요. */
  iconOnly?: boolean

  /** 버튼 안에 아이콘 추가 */
  withIcon?: IconType

  /** 아이콘 크기 */
  iconOnlySize?: IconSize

  /** 버튼 활성화 */
  disabled?: boolean

  /** 버튼 내용 */
  children?: string | ReactNode

  /** 버튼 클릭 동작 */
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void

  /** css */
  style?: React.CSSProperties
}

/** `Button` 컴포넌트의 문서입니다.  */

function Button({
  type,
  variant = 'fill',
  size = 'middle',
  typography = 'smallBody2',
  color,
  withIcon,
  iconOnly,
  iconOnlySize = 'middle',
  disabled,
  children,
  onClick,
  style = {},
}: ButtonPropsTypes) {
  const SVGIcon: React.ElementType | keyof JSX.IntrinsicElements | undefined = withIcon && icons[withIcon]

  return (
    <ButtonStyles
      type={type}
      variant={variant}
      size={size}
      disabled={disabled}
      typography={typography}
      color={color}
      withIcon={withIcon}
      iconOnly={!!iconOnly}
      iconOnlySize={iconOnlySize}
      onClick={onClick}
      style={style}
    >
      <Icon
        as={SVGIcon}
        variant={variant}
        color={color}
        size={size}
        withIcon={withIcon}
        iconOnly={iconOnly}
        iconOnlySize={iconOnlySize}
      />
      {!iconOnly && children}
    </ButtonStyles>
  )
}

export default Button
