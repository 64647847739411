import styled from 'styled-components'

export const CommentWrapper = styled.article<{ deleted?: boolean }>`
  display: flex;
  position: relative;
  color: ${({ theme: { colors }, deleted }) => deleted && colors.gray70};
`

export const CommentLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-right: 8px;
`

export const CommentRight = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
`

export const CommentNickname = styled.p`
  ${({ theme }) => theme.typographies.subtitle4}
`

export const CommentContent = styled.p`
  ${({ theme }) => theme.typographies.body}
  flex: 1;
`

export const ThumbsUpButton = styled.button<{ liked: boolean }>`
  padding: 8px 18px;
  display: inline-flex;
  align-items: center;
  column-gap: 4px;
  cursor: pointer;
  margin-bottom: 12px;
  color: ${({ theme: { colors }, liked }) => (liked ? colors.red : colors.black)};
  border: 1px solid ${({ theme: { colors }, liked }) => (liked ? colors.red : colors.black)};
  border-radius: 100px;
  ${({ theme }) => theme.typographies.subtitle4}

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: ${({ theme: { colors }, liked }) => (liked ? colors.red : colors.black)};
    }
  }
`

export const CommentButtonsLeft = styled.div`
  ${({ theme }) => theme.typographies.smallBody2}

  button {
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  }

  > * {
    font-size: inherit;
    font-weight: inherit;
  }

  > :not(:last-child) {
    margin-right: 16px;
    position: relative;

    &::after {
      content: '・';
      position: absolute;
      right: -14px;
    }
  }

  > :last-child {
    color: ${({ theme }) => theme.colors.gray80};
  }
`

export const CommentButtonsRight = styled.div`
  ${({ theme }) => theme.typographies.smallBody2}

  button {
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  }

  > * {
    font-size: inherit;
    font-weight: inherit;
  }

  > :last-child {
    color: ${({ theme }) => theme.colors.gray80};
  }
`

export const BestCommentBadge = styled.span`
  ${({ theme }) => theme.typographies.smallBody}
  background-color: ${({ theme: { colors } }) => colors.red};
  color: ${({ theme: { colors } }) => colors.white};
  padding: 2px 12px;
  margin-right: 12px;
`
