import styled from 'styled-components'

export const ButtonWrapper = styled.button`
  ${({ theme }) => theme.typographies.boldBody}
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary_color_regular};
  padding: 11px 28px;
  border-radius: 49px;
  min-width: 200px;
  cursor: pointer;

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      min-width: 140px;
      padding: 15px 28px;
      ${typographies.smallButton}
    }
  `}
`
