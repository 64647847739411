import styled from 'styled-components'

export const Section = styled.section`
  width: 100%;
  padding: 0 24px;
  margin-bottom: 172px;
  color: ${({ theme: { colors } }) => colors.black};
`

export const SectionInner = styled.div`
  width: 100%;
  max-width: 1084px;
  margin: 0 auto;
`

export const CardListWrapper = styled.div`
  padding-top: 72px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      padding-top: 24px;
    }
  `}
`

export const KeywordWrapper = styled.section`
  padding: 52px 0 0 92px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      padding: 32px 0 0 32px;
    }
  `}
`

export const KeywordTitle = styled.h4`
  ${({ theme: { typographies } }) => typographies.subtitle2}
  margin-bottom: 20px;

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.subtitle4}
    }
  `}
`

export const KeywordList = styled.ul``

export const KeywordItem = styled.li`
  ${({ theme: { typographies } }) => typographies.subtitle4}
  margin-bottom: 14px;
  cursor: pointer;

  ${({ theme: { media, typographies, colors } }) => `
    @media ${media.mobile} {
      ${typographies.subtitleMobile}
      color: ${colors.gray70};
    }
  `}
`

export const NoData = styled.div`
  text-align: center;
  margin-top: 30vh;
  ${({ theme: { typographies } }) => typographies.subtitle2}

  ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.subtitle4}
    }
  `}
`
