import { useState } from 'react'
import { useRecoilState } from 'recoil'
import { signUpInfoState } from 'recoil/atoms'
import { useRegistrationForm, useTimer } from 'hooks'
import { TextField } from 'components/molecules'
import { emailAuthFormSchema } from 'core/utils/validation'
import { getCertificationNumberCheck, getSendEmailRequest } from 'core/apis/api.auth'
import { useLocation } from 'react-router-dom'
import AuthForm from '../../AuthForm'

export interface EmailAuthFormPropTypes {
  /** 다음 단계로 이동 동작  */
  onProceed: () => void
}

export interface IFormInputs {
  email: string
  athNmb: string
}

export interface CheckTypes {
  check: boolean
  errors?: string
  success: string
}

/** `EmailAuthForm` 컴포넌트의 문서입니다.  */

function EmailAuthForm({ onProceed }: EmailAuthFormPropTypes) {
  const { state } = useLocation()
  const provider = (state as { provider?: 'KAKAO' | 'FACEBOOK' })?.provider
  const [signUpInfo, setSignUpInfo] = useRecoilState(signUpInfoState)
  const { minutes, seconds, initialState, activateTimer, resetTimer } = useTimer(0, 0)
  const {
    register,
    watch,
    formState: { errors },
  } = useRegistrationForm<IFormInputs>(emailAuthFormSchema, 'onChange')
  const [sendCheck, setSendCheck] = useState<CheckTypes>({
    check: false,
    success: '',
  })
  const [athNmbCheck, setAthNmbCheck] = useState<CheckTypes>({
    check: false,
    errors: '',
    success: '',
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // 이메일 발송
  const handleEmailSend = () => {
    setIsLoading(true)
    const { check } = sendCheck

    getSendEmailRequest(watch('email'))
      .then((data) => {
        if (data) {
          activateTimer(1, 30)
          setSendCheck({
            ...sendCheck,
            check: true,
            success: '인증메일이 전송되었습니다.',
          })
        }

        if (data && check) {
          activateTimer(1, 30)
          setSendCheck({
            ...sendCheck,
            success: '인증메일이 재전송되었습니다.',
          })
          setAthNmbCheck({
            ...athNmbCheck,
            check: false,
            errors: '',
            success: '',
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  // 인증번호 전송
  const handleAthNmbSend = async () => {
    try {
      const data = await getCertificationNumberCheck(watch('athNmb'))

      if (data.success) {
        setAthNmbCheck({
          ...athNmbCheck,
          check: true,
          errors: '',
          success: '인증번호가 일치합니다.',
        })
        resetTimer()

        return
      }
      if (!data.success) {
        setAthNmbCheck({
          ...athNmbCheck,
          check: false,
          errors: '인증번호가 일치하지 않습니다.',
          success: '',
        })
        return
      }
    } catch (error) {
      setAthNmbCheck({
        ...athNmbCheck,
        check: false,
        errors: '인증번호가 일치하지 않습니다.',
        success: '',
      })
    }
  }

  const handleNextStep = () => {
    setSignUpInfo({
      ...signUpInfo,
      email: watch('email'),
      emailVerified: true,
    })
    onProceed()
  }

  const getAthNubCheckErrorMessage = () => {
    if (athNmbCheck.errors) {
      return athNmbCheck.errors
    }

    if (!initialState && Number(minutes) === 0 && Number(seconds) === 0 && !athNmbCheck.check) {
      return '인증시간을 초과하였습니다.'
    }

    return undefined
  }

  let buttonText: string

  if (isLoading) buttonText = '전송 중..'
  else if (sendCheck.success) buttonText = '재전송'
  else buttonText = '전송'

  return (
    <AuthForm
      title={`${provider ? '소셜 계정으' : '이메일'}로 회원가입하기`}
      subTitle='이메일로 인증메일 받기'
    >
      <TextField
        type='email'
        label='이메일'
        placeholder='이메일 입력하기'
        buttonText={buttonText}
        register={{ ...register('email') }}
        disabled={!(!errors.email?.message && watch('email'))}
        onClick={handleEmailSend}
        error={errors.email?.message}
        success={isLoading ? '' : sendCheck.success}
      />
      <TextField
        type='text'
        label='인증번호'
        placeholder='인증번호 입력하기'
        buttonText='다음'
        register={{ ...register('athNmb') }}
        disabled={!watch('athNmb')}
        onClick={athNmbCheck.success ? handleNextStep : handleAthNmbSend}
        error={getAthNubCheckErrorMessage()}
        success={athNmbCheck.success}
        timer={sendCheck.success && !athNmbCheck.check ? `${minutes}:${seconds}` : undefined}
      />
    </AuthForm>
  )
}

export default EmailAuthForm
