import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FontColors, Typographies } from 'assets/styles/types'

export const LinkStyles = styled(Link)<{
  underline?: boolean
  typography?: Typographies
  color?: FontColors
}>`
  display: inline-block;
  min-width: 24px;
  text-decoration: ${({ underline }) => underline && 'underline'};
  ${({ typography, theme }) => typography && theme.typographies[typography]}
  color: ${({ color, theme }) => color && theme.colors[color]};
`
