import { Img, Icon } from 'components/atoms'
import { Member } from 'types/Member'
import {
  MemberCardWrapper,
  ImgWrapper,
  TextWrapper,
  TextContents,
  IconWrapper,
  MemberName,
  MemberRole,
  Description,
} from './MemberCard.styles'

export interface MemberCardProps {
  member: Member
}

const iconMap = {
  instagram: 'instagram_unfilled',
  email: 'email_unfilled',
  notion: 'notion_unfilled',
  github: 'github_filled',
  web: 'link_unfilled',
} as const

const MemberCard: React.FC<MemberCardProps> = ({ member: { image, name, roles, contacts, description } }) => {
  return (
    <MemberCardWrapper>
      <ImgWrapper>
        <Img src={image} alt='멤버 이미지' border='none' borderRadius='none' />
      </ImgWrapper>
      <TextContents>
        <TextWrapper>
          <MemberName>{name}</MemberName>
          {roles.map((role) => (
            <MemberRole key={role}>{role}</MemberRole>
          ))}
        </TextWrapper>
        <Description>{description}</Description>
        <IconWrapper>
          {Object.entries(contacts)
            .map(([type, url]: [string, string]): [string, string] => [
              type,
              type === 'email' ? `mailto:${url}` : url,
            ])
            .map(([type, url]: [string, string]) => (
              <Icon
                key={url}
                icon={iconMap[type as keyof typeof iconMap]}
                size='middle'
                color='white'
                onClick={() => window.open(url, '_blank')}
              />
            ))}
        </IconWrapper>
      </TextContents>
    </MemberCardWrapper>
  )
}

export default MemberCard
