import styled from 'styled-components'

export const TextareaStyles = styled.textarea`
  width: 100%;
  height: 80px;
  padding: 10px;

  :focus {
    outline: none;
  }
`
