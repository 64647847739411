import React, { useState } from 'react'
import { Menu, Img, Icon, Text } from 'components/atoms'
import { usePageRoute, useClickOutside, useAuthentication } from 'hooks'
import defaultProfileImage from 'assets/images/default_profile.png'
import { Modal } from 'components/molecules'
import { DropdownStyles, MenuBox, ProfileImg, UserIcon } from './Dropdown.styles'

interface LoginUserIconProps {
  userImageUrl: string
  handleIsOpenToggle: () => void
}

const LoginUserIcon: React.FC<LoginUserIconProps> = ({ userImageUrl, handleIsOpenToggle }) => (
  <ProfileImg onClick={handleIsOpenToggle}>
    <Img src={userImageUrl} alt='프로필 이미지' border='none' borderRadius='circle' />
  </ProfileImg>
)

interface LogoutUserIconProps {
  handleIsOpenToggle: () => void
}

const LogoutUserIcon: React.FC<LogoutUserIconProps> = ({ handleIsOpenToggle }) => (
  <UserIcon>
    <Icon icon='profile_unfilled' color='white' size='middle' onClick={handleIsOpenToggle} />
  </UserIcon>
)

const CommonMenuItems: React.FC<{
  underline?: boolean
  handlePageMove: (url: string) => void
}> = ({ underline = false, handlePageMove }) => (
  <>
    <Menu
      underline
      onClick={() =>
        window.open('https://benew-project.notion.site/ef68e525dc8c476285cbb56ffeccd9c4', '_blank')
      }
    >
      공지사항
    </Menu>
    <Menu underline={underline} onClick={() => handlePageMove(`/about`)}>
      About
    </Menu>
  </>
)

const LoginMenuItems: React.FC<{
  nickname: string
  handlePageMove: (url: string) => void
  openLogoutModal: () => void
}> = ({ nickname = '', handlePageMove, openLogoutModal }) => (
  <>
    <Menu weight='bold'>{nickname}</Menu>
    <Menu underline onClick={() => handlePageMove(`/mypage`)}>
      마이페이지
    </Menu>
    <CommonMenuItems underline handlePageMove={handlePageMove} />
    <Menu onClick={openLogoutModal}>로그아웃</Menu>
  </>
)

const LogoutMenuItems: React.FC<{
  handlePageMove: (url: string) => void
}> = ({ handlePageMove }) => (
  <>
    <Menu underline onClick={() => handlePageMove('/login')}>
      로그인
    </Menu>
    <CommonMenuItems handlePageMove={handlePageMove} />
  </>
)

function Dropdown() {
  const { user, clearAuth } = useAuthentication()
  const { ref, isOpen, handleIsOpenToggle } = useClickOutside()
  const { handlePageMove } = usePageRoute()
  const [logoutModalVisible, setLogoutModalVisible] = useState<boolean>(false)

  const openLogoutModal = () => {
    setLogoutModalVisible(true)
  }
  const closeLogoutModal = () => {
    setLogoutModalVisible(false)
  }

  const handleLogout = () => {
    clearAuth()
    closeLogoutModal()
    handlePageMove(`/`)
  }

  return (
    <DropdownStyles>
      {/* 유저 아이콘 */}
      {user ? (
        <LoginUserIcon
          userImageUrl={user.imageUrl || defaultProfileImage}
          handleIsOpenToggle={handleIsOpenToggle}
        />
      ) : (
        <LogoutUserIcon handleIsOpenToggle={handleIsOpenToggle} />
      )}
      {/* 드롭다운 메뉴 */}
      {isOpen && (
        <MenuBox ref={ref}>
          {user ? (
            <LoginMenuItems
              nickname={user.nickname}
              handlePageMove={handlePageMove}
              openLogoutModal={openLogoutModal}
            />
          ) : (
            <LogoutMenuItems handlePageMove={handlePageMove} />
          )}
        </MenuBox>
      )}
      {/* 로그아웃 모달 */}
      <Modal title='로그아웃' visible={logoutModalVisible} onOk={handleLogout} onCancel={closeLogoutModal}>
        <Text as='p' align='center' typography='smallBody'>
          {'로그아웃 하실건가요..?? 😢\n비뉴는 금방 돌아오실 거라고 믿고 있어요!'}
        </Text>
      </Modal>
    </DropdownStyles>
  )
}

export default Dropdown
