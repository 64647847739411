import { SearchBar } from 'components/molecules'
import { CardList } from 'components/organisms'
import { PostCard } from 'types/ContentResponse'
import { Keyword } from 'types/Keyword'
import { BaseTemplate } from 'components/templates'
import { PropsWithChildren } from 'react'
import {
  Section,
  SectionInner,
  KeywordWrapper,
  KeywordTitle,
  KeywordList,
  KeywordItem,
  NoData,
  CardListWrapper,
} from './SearchTemplate.styles'

interface SearchTemplateProps {
  searchInput: string
  onChangeInput: React.ChangeEventHandler<HTMLInputElement>
  keywordTitle: string
  keywords: Keyword[]
  onClickKeyword: (keyword: Keyword) => void
  searchResult?: PostCard[]
  isLoading: boolean
}

const SearchTemplate: React.FC<SearchTemplateProps> = ({
  searchInput,
  onChangeInput,
  keywordTitle,
  keywords,
  onClickKeyword,
  searchResult,
  isLoading,
}: PropsWithChildren<SearchTemplateProps>) => {
  let result: JSX.Element | null = null

  if (isLoading) {
    result = <NoData>검색 중..</NoData>
  } else if (!searchResult || !searchInput.length) {
    result = (
      <KeywordWrapper>
        <KeywordTitle>{keywordTitle}</KeywordTitle>
        <KeywordList>
          {keywords.map((keyword) => (
            <KeywordItem key={keyword.id} onClick={() => onClickKeyword(keyword)}>
              {keyword.name}
            </KeywordItem>
          ))}
        </KeywordList>
      </KeywordWrapper>
    )
  } else if (searchResult.length > 0) {
    result = (
      <CardListWrapper>
        <CardList cards={searchResult} showSortingButtons={false} />
      </CardListWrapper>
    )
  } else if (searchResult.length === 0) {
    result = <NoData>검색 결과가 없어요!</NoData>
  }

  return (
    <BaseTemplate hasFooter={!!searchResult?.length}>
      <Section>
        <SectionInner>
          <SearchBar value={searchInput} onChange={onChangeInput} placeholder='콘텐츠를 검색해보세요.' />
          {result}
        </SectionInner>
      </Section>
    </BaseTemplate>
  )
}

export default SearchTemplate
