import { useState, useEffect } from 'react'
import { useRegistrationForm, usePageRoute, useToastPop } from 'hooks'
import { SignUpToast, TextField } from 'components/molecules'
import { findPasswordSchema } from 'core/utils/validation'
import { putFindPasswordSendEmail } from 'core/apis/api.auth'
import AuthForm from '../AuthForm'

export interface IFormInputs {
  email: string
}

function FindPasswordForm() {
  const {
    register,
    watch,
    formState: { errors, isValid },
    onSubmit,
    setError,
  } = useRegistrationForm<IFormInputs>(findPasswordSchema, 'onChange')
  const [success, setSuccess] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isNotUser, setIsNotUser] = useState<boolean>(false)
  const { handlePageMove } = usePageRoute()
  const { isToastOn, handleToastPop } = useToastPop()

  const handleFindPasswordSend = async () => {
    setIsLoading(true)

    try {
      await putFindPasswordSendEmail(watch('email'))
      setSuccess(true)
      setIsNotUser(false)
      handleToastPop(4000)
    } catch (error) {
      setError('email', {
        type: 'error',
        message: '가입하지 않은 이메일입니다.',
      })
      setIsNotUser(true)
      handleToastPop(4000)
    }
    setIsLoading(false)
  }

  const handleFindPasswordButtonFunction = () => {
    if (success) {
      handlePageMove('/login')
    }
  }

  const handleFindPasswordButton = () => {
    if (isValid) setIsNotUser(false)
  }

  const handleValidMessage = () => {
    if (!isValid) setSuccess(false)
  }

  useEffect(() => {
    handleFindPasswordButton()
    handleValidMessage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  let buttonText: string

  if (isNotUser) buttonText = '회원가입 하기'
  else if (success) buttonText = '로그인하기'
  else if (isLoading) buttonText = '전송 중..'
  else buttonText = '임시 비밀번호 전송'

  const disabled = isNotUser ? false : !isValid
  const handleClickButton = isNotUser
    ? () => handlePageMove('/signUp')
    : () => handleFindPasswordButtonFunction()

  return (
    <AuthForm
      onSubmit={(...args) => {
        onSubmit(handleFindPasswordSend)(...args)
      }}
      title='비밀번호 찾기'
      subTitle='이메일 입력하기'
      submitButtonText={buttonText}
      submitButtonDisabled={disabled}
      onSubmitButtonClick={handleClickButton}
    >
      <TextField
        type='email'
        label='이메일'
        placeholder='이메일 입력하기'
        register={{ ...register('email') }}
        error={errors.email?.message}
        success={success ? '임시 비밀번호가 전송되었습니다.' : undefined}
      />
      <AuthForm.Ul>
        <AuthForm.Li>
          가입한 이메일 주소로 임시 비밀번호를 보내드립니다. 로그인 후 비밀번호를 반드시 변경해주세요.
        </AuthForm.Li>
      </AuthForm.Ul>
      {isToastOn && (
        <SignUpToast>
          {success
            ? '😘 임시 비밀번호를 보내드렸어요! 로그인해볼까요?'
            : '😎 아직 가입하지 않으셨네요? 회원가입을 하러 가볼까요?'}
        </SignUpToast>
      )}
    </AuthForm>
  )
}

export default FindPasswordForm
