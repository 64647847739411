import styled from 'styled-components'

export const AccountSettingStyles = styled.section`
  max-width: 532px;
  margin: 0 auto;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      max-width: 100%;
    }
  `}
`
export const TabsWrapper = styled.div`
  height: 59px;

  ${({ theme: { media, colors } }) => `
    @media ${media.mobile} {
      border-bottom: 1px solid ${colors.gray10};
      height: 50px;
      margin: 0 -24px;
    }
  `}

  nav {
    max-width: 100%;
    paddin: 0;
  }
`
