import styled from 'styled-components'
import { lineClamp } from 'assets/styles/lineClamp'

export const PostStyles = styled.article`
  max-width: 715px;
  width: 100%;
  margin: 56px auto 65px;
  position: relative;

  ${({ theme: { media } }) => `
  @media ${media.mobile} {
    margin: 16px auto 0;
    }
  `}
`

export const PostInfoWrapper = styled.div`
  ${({ theme: { media } }) => `
  @media ${media.mobile} {
    margin: 0 24px;
    }
  `}
`

export const PostTitle = styled.h3`
  ${({ theme: { media, typographies } }) => `${typographies.h4}
    @media ${media.mobile} {
      ${typographies.subtitle1_1}
      }
  `}
`

export const TagsGroup = styled.div`
  display: flex;
  column-gap: 16px;
  margin-bottom: 24px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      margin-bottom: 16px;
    }
  `}
`

export const Tag = styled.span`
  ${({ theme: { media, typographies } }) => `${typographies.smallBody}
    @media ${media.mobile} {
      ${typographies.smallBody2};
    }
  `}
`

export const AuthorInfoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  column-gap: 36px;
  margin: 24px auto 59px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      margin: 20px auto 22px;
    }
  `}
`

export const AuthorNickname = styled.span`
  ${({ theme: { media, typographies } }) => `${typographies.largeBody}
    @media ${media.mobile} {
      ${typographies.smallBody};
    }
  `}
`

export const CreatedDate = styled.span`
  ${({ theme: { media, typographies } }) => `${typographies.body}
    @media ${media.mobile} {
      ${typographies.smallBody};
    }
  `}
`

export const PostContentWrapper = styled.section`
  /* 게시글 본문 내용은 여기서 스타일 지정 */
  width: 100%;
  ${({ theme }) => theme.typographies.largeRegularBody}

  h1,
  h2,
  h3 {
    ${({ theme: { media, typographies } }) => `${typographies.subtitle2}
      @media ${media.mobile} {
        ${typographies.subtitle4}
        padding: 0 24px;
      }
    `}
  }

  ul,
  ol {
    padding-left: 1.5em;

    ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.subtitle4}
      padding: 0 48px;
    }

  `}
  }

  ol li {
    list-style-type: decimal;

    p {
      padding: 0;
    }
  }

  ul li {
    list-style-type: disc;

    p {
      padding: 0;
    }
  }

  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.primary_color_regular};
  }

  img {
    width: 100%;
    padding: 0;
  }

  p {
    ${({ theme: { media, typographies } }) => `
    @media ${media.mobile} {
      ${typographies.smallBody}
      padding: 0 24px
    }
  `}
  }
`

export const IconBtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 44px;
  margin: 100px 0 90px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      display: none;
    }
  `}
`

export const LikeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 18px;
`

export const LikeIconWrapper = styled.div<{ tigerToggle: boolean }>`
  border-radius: 50%;
  padding: 22px;
  background-color: ${({ theme, tigerToggle }) => tigerToggle && theme.colors.secondary_color_light};
  border: 1px solid
    ${({ theme, tigerToggle }) => (tigerToggle ? theme.colors.secondary_color_regular : theme.colors.gray20)};
`

export const LikeCount = styled.p`
  ${({ theme: { typographies } }) => typographies.subtitle3};
  text-align: center;
`

export const ScrapedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 18px;
`

export const ScrapedIconWrapper = styled.div<{ bookmarkToggle: boolean }>`
  border-radius: 50%;
  padding: 22px;
  background-color: ${({ theme, bookmarkToggle }) => bookmarkToggle && theme.colors.primary_color_light};
  border: 1px solid
    ${({ theme, bookmarkToggle }) =>
      bookmarkToggle ? theme.colors.primary_color_regular : theme.colors.gray20};
`

export const ScrapCount = styled.p`
  ${({ theme: { typographies } }) => typographies.subtitle3};
  text-align: center;
`

export const AuthorCardWrapper = styled.div`
  display: flex;
  margin: 123px 0 0px;

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      margin: 64px 24px 0;
    }
  `}
`

export const PostMoveWrapper = styled.div`
  display: flex;
  height: 175px;
  margin-bottom: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray50};

  > * {
    width: 50%;
  }

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      margin: 0 24px;
      margin-bottom: 24px;
      border-bottom: 0;
    }
  `}
`

export const PrevMove = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  padding: 20px;
  cursor: pointer;

  &:hover {
    background-color: rgba(159, 175, 255, 0.3);
  }

  ${({ theme: { media } }) => `
    @media ${media.mobile} {
      padding: 20px 24px;
    }
  `}
`

export const PrevMoveText = styled.span`
  ${({ theme: { typographies } }) => typographies.subtitle4};
`

export const PrevPostTitle = styled.p`
  ${{ ...lineClamp(2) }}

  ${({ theme: { media, typographies } }) => `
    ${typographies.subtitle2_1}

    @media ${media.mobile} {
      ${typographies.smallBody}
    }
  `};
`

export const NextMove = styled(PrevMove)`
  text-align: right;
`

export const NextMoveText = styled(PrevMoveText)``

export const NextPostTitle = styled(PrevPostTitle)``
