import React from 'react'
import { TextareaStyles } from './Textarea.styles'

export interface TextareaPropTypes {
  register?: Record<string, unknown>
  value?: string
  maxLength?: number
  placeholder: string
}

function Textarea({ maxLength, register, ...rest }: TextareaPropTypes) {
  return <TextareaStyles maxLength={maxLength} {...register} {...rest} />
}

export default Textarea
