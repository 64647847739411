import { useMutation } from 'react-query'
import { useSetRecoilState, useRecoilState } from 'recoil'
import { userState, myPageStepState, profileState } from 'recoil/atoms'
import { Text, Input, Select, Textarea, Img, Button, Icon } from 'components/atoms'
import { useBirthYear, useRegistrationForm } from 'hooks'
import { profileEditRequest } from 'core/apis/api.auth'
import { profileEditSchema } from 'core/utils/validation'
import DefaultImg from 'assets/images/default_profile.png'
import { Gender, User } from 'types/User'
import { AxiosError } from 'axios'
import {
  ProfileFormStyles,
  ProfileFormItem,
  ProfileImg,
  ProfileImgWrapper,
  AddBtnWrapper,
  EditBtnWrapper,
} from './ProfileEditForm.styles'

export type UserEditInfo = Pick<User, 'birthYear' | 'imageUrl' | 'introduction' | 'nickname' | 'sex'>

export interface IFormInputs {
  nickname: string
  birth: string
  gender: string
  introduction: string
}

function ProfileEditForm() {
  const setUser = useSetRecoilState(userState)
  const [profileInfo, setProfileInfo] = useRecoilState(profileState)
  const yearOfBirthList = useBirthYear()
  const setStep = useSetRecoilState(myPageStepState)

  const { register, watch, onSubmit } = useRegistrationForm<IFormInputs>(profileEditSchema, 'all', {
    nickname: profileInfo?.nickname,
    introduction: profileInfo?.introduction,
  })

  const { mutateAsync: onChangeProfile } = useMutation(
    ({ birthYear, imageUrl, introduction, nickname, sex }: UserEditInfo) =>
      profileEditRequest(birthYear, imageUrl, introduction, nickname, sex),
    {
      onSuccess: (data) => {
        setProfileInfo(data)
        setUser(data)
        alert('프로필 변경 성공! 🎉')
        setStep('1')
      },

      onError: (error) => {
        if ((error as AxiosError)?.response?.status === 409) {
          alert('닉네임이 중복되었습니다! 다시 확인해주세요.😱')
        } else {
          alert('프로필 변경 실패! 다시 확인해주세요.😱')
        }
      },
    },
  )

  if (!profileInfo) return <div />

  const handleChangeProfile = () => {
    onChangeProfile({
      birthYear: Number(watch('birth')) ? Number(watch('birth')) : profileInfo.birthYear,
      imageUrl: profileInfo?.imageUrl,
      introduction: watch('introduction') ? watch('introduction') : profileInfo.introduction,
      nickname: watch('nickname') ? watch('nickname') : profileInfo.nickname,
      sex: watch('gender') ? Gender[watch('gender') as keyof typeof Gender] : profileInfo.sex,
    })
  }

  return (
    <ProfileFormStyles
      onSubmit={(...args) => {
        onSubmit(handleChangeProfile)(...args)
      }}
    >
      <ProfileImgWrapper>
        <Text as='label' typography='subtitle4' color='gray80' className='label'>
          프로필 사진
        </Text>
        <ProfileImg>
          <Img
            src={profileInfo?.imageUrl || DefaultImg}
            alt='프로필 이미지'
            border='none'
            borderRadius='big'
          />
          <AddBtnWrapper>
            <Icon icon='pencil_circle_filled' color='white' size='larger' onClick={() => setStep('3')} />
          </AddBtnWrapper>
        </ProfileImg>
      </ProfileImgWrapper>
      <ProfileFormItem>
        <Text as='label' typography='subtitle4' color='gray80' className='label'>
          닉네임
        </Text>
        <Input type='text' placeholder={profileInfo?.nickname} register={{ ...register('nickname') }} />
      </ProfileFormItem>
      <ProfileFormItem>
        <Text as='label' typography='subtitle4' color='gray80' className='label'>
          출생년도
        </Text>
        <Select
          name='birth'
          placeholder={String(profileInfo.birthYear)}
          options={yearOfBirthList}
          register={{ ...register('birth') }}
        />
      </ProfileFormItem>
      <ProfileFormItem>
        <Text as='label' typography='subtitle4' color='gray80' className='label'>
          성별
        </Text>
        <Select
          name='gender'
          placeholder={(Object.keys(Gender) as Array<keyof typeof Gender>).find(
            (key) => Gender[key] === profileInfo.sex,
          )}
          options={Object.keys(Gender)}
          register={{ ...register('gender') }}
        />
      </ProfileFormItem>
      <ProfileFormItem>
        <Text as='label' typography='subtitle4' color='gray80' className='label'>
          소개글
        </Text>
        <Textarea
          maxLength={60}
          register={{ ...register('introduction') }}
          placeholder={profileInfo?.introduction}
        />
      </ProfileFormItem>
      <EditBtnWrapper>
        <Button variant='fill' size='middle' onClick={handleChangeProfile} typography='smallButton'>
          프로필 저장하기
        </Button>
      </EditBtnWrapper>
    </ProfileFormStyles>
  )
}

export default ProfileEditForm
