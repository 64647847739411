import { useEffect, useState } from 'react'
import { Button } from 'components/atoms'
import Checkbox from 'components/atoms/Checkbox/Checkbox'
import { useHistory } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { unregisterState } from 'recoil/atoms'
import { unregisterUser } from 'core/apis/api.auth'
import {
  UnregisterTemplateStyles,
  Title,
  SubTitle,
  NotificationText,
  ProcessingDetailsContainer,
  ProcessingDetailsTitle,
  ProcessingDetailsListContainer,
  UnregisterQuestion,
  UnregisterQuestionListContainer,
  UnregisterBtnContainer,
  DETAIL_LIST,
  QUESTION_LIST,
} from './UnregisterTemplate.style'

function UnregisterTemplate() {
  const history = useHistory()
  const [checkboxSet] = useState(new Set())
  const [isCheckedRequired, setIsCheckedRequired] = useState(false)
  const setUnregister = useSetRecoilState(unregisterState)

  const checkRequired = () => setIsCheckedRequired((v) => !v)

  const unregister = () => {
    if (!isCheckedRequired) return

    const reasons = [...checkboxSet].join('/')

    unregisterUser(reasons)
      .then(() => {
        alert('서비스 탈퇴가 완료되었습니다')
        history.replace('/')
      })
      .catch(() => {
        alert('처리 중 에러가 발생했습니다. 다시 시도해주세요')
      })
  }

  const cancelUnregister = () => {
    history.push('/')
  }

  useEffect(() => {
    return () => setUnregister(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <UnregisterTemplateStyles>
      <Title>서비스 탈퇴하기</Title>
      <SubTitle>뉴비님, 그동안 비뉴와 함께 해주셔서 진심으로 감사드려요.</SubTitle>
      <NotificationText>서비스 탈퇴 신청에 앞서 아래의 내용을 반드시 확인해주세요.</NotificationText>
      <ProcessingDetailsContainer>
        <ProcessingDetailsTitle>회원 탈퇴 시 처리내용</ProcessingDetailsTitle>
        <ProcessingDetailsListContainer>
          {DETAIL_LIST.map((list) => (
            <li key={list}>{list}</li>
          ))}
        </ProcessingDetailsListContainer>
      </ProcessingDetailsContainer>
      <Checkbox
        value='위 내용을 모두 확인하였습니다. (필수)'
        typography='smallBody'
        color='gray70'
        onChecked={checkRequired}
      />
      <UnregisterQuestion>비뉴 서비스를 탈퇴하려는 이유가 무엇인가요? (복수 선택 가능)</UnregisterQuestion>
      <UnregisterQuestionListContainer>
        {QUESTION_LIST.map((question, index) => (
          <li key={question}>
            <Checkbox
              id={index + 1}
              value={question}
              color='gray70'
              typography='body'
              onChecked={(e) => {
                if (e.target.checked) checkboxSet.add(QUESTION_LIST[index])
                else checkboxSet.delete(QUESTION_LIST[index])
              }}
            />
          </li>
        ))}
      </UnregisterQuestionListContainer>
      <UnregisterBtnContainer>
        <Button
          type='button'
          variant='fill'
          size='middle'
          typography='smallButton'
          style={{ padding: '15px 65px', width: 'auto', height: 'auto' }}
          disabled={!isCheckedRequired}
          onClick={unregister}
        >
          서비스 탈퇴하기
        </Button>
        <Button
          type='button'
          variant='fill'
          size='middle'
          typography='smallButton'
          style={{ padding: '15px 65px', width: 'auto', height: 'auto' }}
          onClick={cancelUnregister}
        >
          조금 더 이용해 볼래요!
        </Button>
      </UnregisterBtnContainer>
    </UnregisterTemplateStyles>
  )
}

export default UnregisterTemplate
