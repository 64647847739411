import styled from 'styled-components'

export const AccountFormStyles = styled.div`
  max-width: 532px;
  margin: 71px auto 210px;
`

export const PasswordEditFormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

export const FormItem = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 12px;
  /* align-items: center; */
  padding: 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray10};

  .label {
    width: 40%;
  }
`

export const DeleteAccount = styled.button`
  ${({ theme: { typographies } }) => typographies.largeBody};
  color: ${({ theme: { colors } }) => colors.gray20};

  &:hover {
    color: ${({ theme: { colors } }) => colors.primary_color_regular};
    cursor: pointer;
    text-decoration: underline;
  }
`
