import { MainTemplate } from 'components/templates'
import { useMainContents } from 'hooks'

const MainPage = () => {
  const {
    isLoading,
    recentCards,
    popularCards,
    sexpediaCards,
    posepediaCards,
    reviewCards,
    banners,
    recommendedCards,
  } = useMainContents()

  return (
    <MainTemplate
      loading={isLoading}
      recentCards={recentCards ?? []}
      popularCards={popularCards ?? []}
      sexpediaCards={sexpediaCards ?? []}
      posepediaCards={posepediaCards ?? []}
      reviewCards={reviewCards ?? []}
      editorPickCards={recommendedCards ?? []}
      banners={banners ?? []}
    />
  )
}

export default MainPage
