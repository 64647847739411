import styled from 'styled-components'

export const Wrapper = styled.header``

export const Title = styled.h3`
  ${({ theme: { typographies } }) => typographies.subtitle}
  margin-bottom: 14px;

  ${({ theme: { typographies, media } }) => `
    @media ${media.mobile} {
      ${typographies.subtitle2}
      margin-bottom: 2px;
    }
  `}
`

export const SubTitle = styled.div`
  ${({ theme: { typographies } }) => typographies.body}
  color: ${({ theme: { colors } }) => colors.primary_color_regular};
`
